import { BaseUriHolder } from "../utils/BaseUriHolder";
import { PrivateService } from "../auth/PrivateService";

export class GlobalInfoService {
    constructor () {
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    getGlobalInfosByUseForAndIn(useFor, usableAt) {
        let uri = this.baseUriHolder.getPublic() +'/globals/q?userfor='+ useFor +'&usable='+usableAt;
        return this.privateService.getFetch(uri);
    }
}
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import PageNotFound from '../../PageNotFound';

const RedirectedJobView = () => {
    const [checkParams, setCheckParams] = useState(false);
    let history = useHistory();
    const { search } = useLocation();

    console.log(search);

    useEffect(() => {
        if (!search.includes('?jobId') || !search.includes('jobCode') || !search.includes('companyId')) {
            setCheckParams(false);
        }
        else {
            setCheckParams(true);
        }
    
      }, [search]);

    
    if (checkParams) {
        const searchParams = new URLSearchParams(search);
    
        console.log(searchParams.get('jobId'));

        let selectedJob = {
            jobId: searchParams.get('jobId'),
            jobCode: searchParams.get('jobCode'),
            companyId: searchParams.get('companyId'),
          };

        let jobDetailState = {
            selectedJob: selectedJob,
        };

        history.push("/job/details", jobDetailState);
    }


    return (
        <div>
        {checkParams ? <ProgressSpinnerLoader showBar={true} /> : <PageNotFound/>}
        </div>
    );
}

export default RedirectedJobView;
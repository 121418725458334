import { PrivateService } from "../auth/PrivateService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class MessageSenderService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    sendMessage(message) {
        let uri = this.baseUriHolder.getUser() + '/message/send';
        return this.privateService.postFetch(uri, message);
    }

    countUnseenMessage(){
        let uri = this.baseUriHolder.getPublic() + '/message/count';
        return this.privateService.getFetch(uri);
    }

    findUserMessages(filter) {
        let uri = this.baseUriHolder.getUser() + '/messages';
        return this.privateService.postFetch(uri, filter);
    }
    findUnseenMessages() {
        let uri = this.baseUriHolder.getUser() + '/unseen/messages';
        return this.privateService.getFetch(uri);
    }

    findMessagesByJob(jobId, userId) {
        let uri = this.baseUriHolder.getUser() + '/messages/by-job?jobId='+jobId+'&userId='+userId;
        return this.privateService.getFetch(uri);
    }
    readMessage(recipentId) {
        let uri = this.baseUriHolder.getUser() + '/message/read?recipentId='+recipentId
        return this.privateService.getFetch(uri);
    }

    showMessageDetails(recipientId, title, status) {
        let uri = this.baseUriHolder.getPublic() + `/messages/${recipientId}/${title}/${status}`;
        return this.privateService.getFetch(uri);
    }
}
import { BaseUriHolder } from "../utils/BaseUriHolder";
import { PrivateService } from "../auth/PrivateService";

export class UserService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    loadLoggedUserProfile() {
        let uri = this.baseUriHolder.getPublic() + '/user/profile';
        return this.privateService.getFetch(uri);
    }

    updateUserProfile(profile) {
        let uri = this.baseUriHolder.getPublic() + '/user/profile/update';
        return this.privateService.putFetch(uri, profile);
    }

    checkCurrentPass=(info)=> {
        let uri = this.baseUriHolder.getUser() + '/change/password/check';
        return this.privateService.postFetch(uri, info);
    }
    
    checkCodeAndChangePass=(info)=> {
        let uri = this.baseUriHolder.getUser() + '/change/password/ok';
        return this.privateService.postFetch(uri, info);
    }
}
export const JOB_STATUS = {
    PENDING: 0,
    ACTIVE: 1,
    DEACTIVE:2
}
export const RESUME_STATUS = {
    DEACTIVE: 0,
    ACTIVE: 1
}
export const APPLICANTION_STATUS = {
    PENDING: 0,
    APPLIED: 1
}
import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { JobInfoService } from "../../service/JobInfoService";
import { DateHandler } from "../../utils/DateHandler";
import { JobApplicationService } from "../../service/JobApplicationService";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { SplitButton } from "primereact/splitbutton";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MessageSenderService } from "../../service/MessageSenderService";
import { APPLICANTION_STATUS } from "../../utils/StatusConstants";

class Applicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobList: [],
      selectedJob: null,
      applicantList: [],
      selectedApplicants: null,
      messageDialog: false,
      messageSender:{
        messageTitle:'',
        messageDetail:''
      },
    };

    this.jobInfoService = new JobInfoService();
    this.jobApplicationService = new JobApplicationService();
    this.messageSenderService = new MessageSenderService();
  }

  componentDidMount() {
    this.onLoadActiveJobs();
  }

  onLoadActiveJobs = () => {
    let { jobList } = this.state;
    const { company } = this.props;
    if (company) {
      this.jobInfoService
        .findEmployerActiveJobs(company.companyId)
        .then((res) => {
          if (res.status === 200) {
            return res.json().then((body) => {
              jobList = body;
              this.setState({ jobList });
            });
          }
        });
    }
  };
  onSelectJob = (e) => {
    let { selectedJob, jobList } = this.state;
    jobList.forEach((j) => {
      if (j.jobId === e.value) {
        selectedJob = j;
      }
    });
    this.findApplicants(e.value);
    this.setState({ selectedJob });
  };

  findApplicants = (jobId) => {
    let { applicantList, showProgress } = this.state;
    this.setState({ showProgress: true });
    this.jobApplicationService.findApplicantsForEmployer(jobId).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          for (let a = 0; a < body.length; a++) {
            if (body[a].applicationStatus === APPLICANTION_STATUS.PENDING) {
              body[a].applicationStatus = "Pending";
            } else if (body[a].applicationStatus === APPLICANTION_STATUS.APPLIED) {
              body[a].applicationStatus = "Applied";
            }
          }

          applicantList = body;
          showProgress = false;
          this.setState({ applicantList, showProgress });
        });
      } else {
        showProgress = false;
        this.setState({ showProgress });
      }
    });
  };

  appliedDateTemp = (rowData) => {
    return (
      <span>
        {DateHandler.getDateFromStringAnyFormat(
          rowData.applyDate,
          "DD MMM, YYYY"
        )}
      </span>
    );
  };

  splitButtonAction = (type) => {
    let { messageDialog, selectedApplicants } = this.state;
    if (selectedApplicants === null || selectedApplicants.length < 0) {
      this.props.toast(3, 'Please, Select one more applicants');
      return;
    }
    console.log(selectedApplicants);
    if (type === "messageDialog") {
      messageDialog = true;
    }
    this.setState({ messageDialog });
  };

  sendMessage=()=> {
    let {messageDialog, selectedApplicants, messageSender, selectedJob, showProgress} = this.state;

    let applicants = [];

    selectedApplicants.map((a, i) => {
      applicants.push(a.applicantInfo);
    })
messageSender['receivers'] = applicants;
messageSender['jobInfoDto'] = selectedJob;

this.setState({showProgress:true});
this.messageSenderService.sendMessage(messageSender)
.then(res => {
  if(res.status === 201) {
    showProgress = false;
    messageDialog = false;
    this.setState({showProgress, messageDialog});
    this.props.toast(1, 'Message Details has successfully saved');
  }else {
    return res.json().then(err => {
      showProgress = false;
      this.setState({showProgress});
      this.props.toast(4, err.message);
    })
  }
})
  }

  onChangeMessageSender =(e)=> {
    let {messageSender} = this.state;
    messageSender[e.target.name] = e.target.value;
    this.setState({messageSender});
  }
  render() {
    const {
      selectedJob,
      jobList,
      applicantList,
      selectedApplicants,
      messageDialog,
      showProgress,
      messageSender
    } = this.state;

    let jobOpt = [];
    jobList.map((val, i) => {
      jobOpt.push({ label: val.jobTitle, value: val.jobId });
    });
    let expireDate = new Date();
    if (selectedJob) {
      expireDate = selectedJob.jobExpireDate;
    }

    const btnList = [
      {
        label: "Select as Employees",
        icon: "pi pi-check-square",
        command: (e) => {
          this.splitButtonAction("messageDialo");
        },
      },
      {
        label: "Select for Interview",
        icon: "pi pi-check",
        command: (e) => {
          this.splitButtonAction("messageDialo");
        },
      },
    ];
    return (
      <div className="row">
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="col-12">
          <div className="card">
            <div className="card-body" style={{ padding: "0.50rem" }}>
              <div className="row">
                <div className="col-sm-6 col-lg-6">
                  <strong>Select a Job to see Applicants</strong>
                  <Dropdown
                    value={selectedJob && selectedJob.jobId}
                    options={jobOpt}
                    onChange={this.onSelectJob}
                    placeholder="Select a Job"
                  />
                </div>
                <div className="col-sm-6 col-lg-6">
                  <strong>Job Details</strong>
                  <div className="col-12 p-0">
                    <h5>{selectedJob && selectedJob.jobTitle}</h5>
                    <span className="mr--10">
                      Code: {selectedJob && selectedJob.jobCode}
                    </span>
                    <span className="mr--10">
                      Salary: {selectedJob && selectedJob.jobOfferingAmount}
                    </span>
                    <span>
                      Expire:{" "}
                      {DateHandler.getDateFromStringAnyFormat(
                        expireDate,
                        "DD MMMM, YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt--10">
          <div className="tab-header card p-1 mb--5">
            <span className="tab-header-text">Applicant List</span>
            <span className="text-right">found [{applicantList.length}]</span>
          </div>
          <div className="col-12 pd0">
            <DataTable
              value={applicantList}
              selection={selectedApplicants}
              onSelectionChange={(e) =>
                this.setState({ selectedApplicants: e.value })
              }
              dataKey="id"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "2em" }}
              ></Column>
              <Column
                field="applicantInfo.fullName"
                header="Applicant Name"
              ></Column>
              <Column field="expectedSalary" header="Expected Salary"></Column>
              <Column
                field="applyDate"
                header="Applied"
                body={this.appliedDateTemp}
              ></Column>
              <Column field="applicationStatus" header="Status"></Column>
            </DataTable>

          </div>

          <div className="col-12 pd0 text-right">
            <SplitButton
              label="Message"
              icon="pi pi-envelope"
              onClick={() => this.splitButtonAction("messageDialog")}
              model={btnList}
              className="btn-primary"
            ></SplitButton>
          </div>

          <Dialog
            header="Message Details"
            visible={messageDialog}
            modal
            style={{ width: "50vw", maxHeight: "75vh" }}
            footer={
              <div>
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  onClick={() => this.setState({ messageDialog: false })}
                />
                <Button
                  label="Send"
                  icon="pi pi-envelope"
                  onClick={this.sendMessage}
                  autoFocus
                />
              </div>
            }
            onHide={() => this.setState({ messageDialog: false })}
          >
            <div className="col-12">
              <h4 className="text-center">
                You have selected <b>{selectedApplicants && selectedApplicants.length}</b> Applicants to send Message
              </h4>
            </div>
            <div className="col-12 mb--10 mb-lg-10">
              <div className="form-group">
                <label>Title *</label>
                <input
                  id="messageTitle"
                  name="messageTitle"
                  type="text"
                  className="form-control"
                  value={messageSender.messageTitle}
                  onChange={this.onChangeMessageSender}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>Message *</label>
                <textarea
                  type="text"
                  rows="5"
                  id="message"
                  name="messageDetail"
                  className="form-control"
                  value={messageSender.messageDetail}
                  onChange={this.onChangeMessageSender}
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default Applicants;

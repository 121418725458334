import React, { Component } from "react";
import {
  FaSearch,
  FaMapMarkerAlt,
  FaTimesCircle,
  FaRegCircle,
  FaEnvelope,
} from "react-icons/fa";
import { AutoComplete } from "primereact/autocomplete";
import JobList from "./JobList";

import Select from "react-select";
import { AddressInfoService } from "../../service/AddressInfoService";
import { Helmet } from "react-helmet";
import { KeywordInfoService } from "../../service/KeywordInfoService";
import { JobInfoService } from "../../service/JobInfoService";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { Toast } from "primereact/toast";
import { ToastMessage } from "../../utils/ToastMessage";
import { JobFilterUtils } from "../../utils/JobFilterUtils";
import { Paginator } from "primereact/paginator";
import { DateHandler } from "../../utils/DateHandler";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class FindJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
      modifiedFilter: [],
      isMoreFilter: false,
      moreFilter: [],
      addresses: [],
      filteredAddesses: [],
      selectedAddress: null,
      keywords: [],
      filteredKeywords: [],
      selectedKeyword: null,
      jobList: [],
      suggestedJobs:[],
      jobFilter: [],
      showProgress: false,
      paginator: {
        page: 0,
        limit: 20,
        totalPage: 0,
        totalFound: 0,
        option:{}
      },
      pageCallRef: {
        no: false,
        all: false,
        title: false,
        address: false
      },
      errors: {},
    };
    this.addressInfoService = new AddressInfoService();
    this.keywordInfoService = new KeywordInfoService();
    this.jobInfoService = new JobInfoService();
  }

  componentDidMount() {
    fetch("metaData.json").then((res) =>
      res.json().then((data) => {
        this.setState({ metadata: data.jobFind });
      })
    );
    this.initFilter();
    this.onLoadAddress();
    this.onLoadKeywords();
    const jobFilters = localStorage.getItem("jobFilter");
    console.log('arr', jobFilters);
    const filters = JSON.parse(jobFilters);
    // console.log('len', jobFilters.length);
    if (filters !== null && filters.length > 0) {
      // const jobFilter = JSON.parse(jobFilters);
      
     
      let jobFilter = filters.find(f => f.selected === true);
      if (jobFilter !== undefined && jobFilter !== null) { 

        if (jobFilter.jobTitle === null && jobFilter.address !== null) {
          console.log("Address")
          this.onLoadJobListByLocation(jobFilter.address, 0);
        } else if(jobFilter.jobTitle !== null && jobFilter.address === null){
          console.log("Job Title")
          this.onLoadJobListByJobTitle(jobFilter.jobTitle, 0);
        } else if(jobFilter.jobTitle !== null && jobFilter.address !== null){
          console.log("Address & Job Title")
          this.onLoadJobList(jobFilter.jobTitle, jobFilter.address, 0);
        } 
        this.setState({ jobFilter: jobFilter });
      }   
      else {
        this.onLoadAllJobs(0);
      }
    }
    else {
      this.onLoadAllJobs(0);
    }
  }

  onLoadAddress = () => {
    let { addresses } = this.state;
    this.addressInfoService.getAllAddress().then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          addresses = body;
          this.setState({ addresses });
        });
      }
    });
  };

  onLoadKeywords = () => {
    let { keywords } = this.state;
    this.keywordInfoService.getKeywords().then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          keywords = body;
          this.setState({ keywords });
        });
      }
    });
  };

  onFilterAddressList = (e) => {
    let { addresses, filteredAddesses } = this.state;
    setTimeout(() => {
      filteredAddesses = [];
      if (!e.query.trim().length) {
        filteredAddesses = addresses;
      } else {
        filteredAddesses = addresses.filter((a) => {
          return a.addressName.toLowerCase().startsWith(e.query.toLowerCase());
        });
      }
      this.setState({ filteredAddesses });
    }, 250);
  };

  onFilterKeywordList = (e) => {
    let { keywords, filteredKeywords } = this.state;
    setTimeout(() => {
      filteredKeywords = [];
      if (!e.query.trim().length) {
        filteredKeywords = keywords;
      } else {
        filteredKeywords = keywords.filter((a) => {
          return a.name.toLowerCase().startsWith(e.query.toLowerCase());
        });
      }
      this.setState({ filteredKeywords });
    }, 250);
  };
  initFilter() {
    const filter = [
      <Select value="" onChange={this.handleChange} options={options} />,
      <Select value="" onChange={this.handleChange} options={options} />,
      <Select value="" onChange={this.handleChange} options={options} />,
      <Select value="" onChange={this.handleChange} options={options} />,
    ];
    let { modifiedFilter, moreFilter } = this.state;
    let width = document.body.clientWidth;
    if (width > 768) {
      modifiedFilter = filter;
    } else if (width < 769 && width > 425) {
      moreFilter.push(filter.pop());
      moreFilter.push(filter.pop());
      modifiedFilter = filter;
    } else if (width < 426) {
      moreFilter = filter;
    }

    this.setState({ modifiedFilter, moreFilter });
  }
  onClickMoreFilter = () => {
    this.setState({ isMoreFilter: this.state.isMoreFilter ? false : true });
  };

  onClickFindJobs = () => {
    let { selectedAddress, selectedKeyword, errors } = this.state;

    if (
      selectedKeyword === null ||
      selectedKeyword === "" ||
      selectedKeyword === undefined
    ) {
      // errors["keyword"] = "Enter job title or keyword";     
      // selectedKeyword = "Executive";
      selectedKeyword = null;
    }

    if (
      selectedAddress === null ||
      selectedAddress === "" ||
      selectedAddress === undefined
    ) {
      // selectedAddress = "Washington, D.C.";
      selectedAddress = null;
    }

    let jobTitle;
    if (
      typeof selectedKeyword === "object" || selectedKeyword instanceof Object ) {
      jobTitle = selectedKeyword && selectedKeyword.name;
      // jobTitle = selectedKeyword.name;
    } else {
      jobTitle = selectedKeyword;
    }

    let address;
    if (
      typeof selectedAddress === "object" || selectedAddress instanceof Object ) {
      address = selectedAddress && selectedAddress.addressName;
    } else {
      address = selectedAddress;
    }

    if (
      (jobTitle === " " || jobTitle === null || jobTitle === undefined) &&
      (address !== " " || address !== null)
    ) {
      this.onLoadJobListByLocation(address, 0);
    } else if (
      (address === " " || address === null) &&
      (jobTitle !== " " || jobTitle !== null)
    ) {
      this.onLoadJobListByJobTitle(jobTitle, 0);
    } else {
      this.onLoadJobList(jobTitle, address, 0);
    }

    let searchObj = {
      jobTitle: jobTitle,
      address:address,
      selected:true
    }    

    JobFilterUtils.updateLocatStore(searchObj);
    let jobFilter = localStorage.getItem('jobFilter');
    if (jobFilter) {
      jobFilter = JSON.parse(jobFilter);
      searchObj = jobFilter.find(f => f.jobTitle === jobTitle);
    }
    
    this.setState({ jobFilter: searchObj });
  };


  onLoadAllJobs =(page)=> {
    let { jobList, paginator, pageCallRef, showProgress } = this.state;
    pageCallRef.no = true;
    pageCallRef.all = false;
    pageCallRef.title = false;
    pageCallRef.address = false;

    showProgress = true;
    this.setState({ showProgress, pageCallRef });

    let paginatorObj = {
      page: page,
      limit: paginator.limit,
      totalPage:paginator.totalPage,
      filter: {
      },
    };
    
    this.jobInfoService.findActiveJobs(paginatorObj).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          
          jobList = body.list;
          paginator.totalPage = body.pages;
          paginator.totalFound = body.count;
          showProgress = false;
            this.setState({jobList, paginator, showProgress });
        });
      } else {
        return res.json().then((err) => {
          jobList = [];
          showProgress = false;
          this.setState({ jobList, showProgress });
        });
      }
    });
  }

  onLoadJobList = (title, address, page) => {
    let { jobList, pageCallRef, paginator, showProgress } = this.state;
    pageCallRef.no = false;
    pageCallRef.all = true;
    pageCallRef.title = false;
    pageCallRef.address = false;

    showProgress = true;
    this.setState({ showProgress, pageCallRef });

    let filter = {
      jobTitle: title,
      address:address
    }

    let paginatorObj = {
      page: page,
      limit: paginator.limit,
      totalPage:paginator.totalPage,
      filter: filter,
    };

    this.jobInfoService.findJobsForPublicWithPage(paginatorObj).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          jobList = body.list;
          paginator.totalPage = body.pages;
          paginator.totalFound = body.count;
          paginator.option = filter;
          showProgress = false;
            this.setState({jobList, paginator, showProgress });
        });
      } else {
        return res.json().then((err) => {
          jobList = [];
          showProgress = false;
          this.setState({ jobList, showProgress });
        });
      }
    });
  };

  onLoadJobListByJobTitle = (title, page) => {
    let { jobList, pageCallRef, paginator, showProgress } = this.state;
    pageCallRef.no = false;
    pageCallRef.all = false;
    pageCallRef.title = true;
    pageCallRef.address = false;
    
    showProgress = true;
    this.setState({ showProgress, pageCallRef });

    let filter = {
      jobTitle: title
    }

    let paginatorObj = {
      page: page,
      limit: paginator.limit,
      totalPage:paginator.totalPage,
      filter: filter,
    };
    this.jobInfoService.findJobsByJobTitleWithPage(paginatorObj).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          jobList = body.list;
          paginator.totalPage = body.pages;
          paginator.totalFound = body.count;
          paginator.option = filter;
          showProgress = false;
          this.setState({ jobList, paginator, showProgress });
        });
      } else {
        return res.json().then((err) => {
          jobList = [];
          showProgress = false;
          this.setState({ jobList, showProgress });
        });
      }
    });
  };

  onLoadJobListByLocation = (address, page) => {
    let { jobList, pageCallRef, paginator, showProgress } = this.state;
    pageCallRef.no = false;
    pageCallRef.all = false;
    pageCallRef.title = false;
    pageCallRef.address = true;
    
    showProgress = true;
    this.setState({ showProgress, pageCallRef });

    let filter = {
      address:address
    }

    let paginatorObj = {
      page: page,
      limit: paginator.limit,
      totalPage:paginator.totalPage,
      filter: filter,
    };
    this.jobInfoService.findJobsForPublicByLocationWithPage(paginatorObj).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          jobList = body.list;
          paginator.totalPage = body.pages;
          paginator.totalFound = body.count;
          paginator.option = filter;
          showProgress = false;
          this.setState({ jobList, paginator, showProgress });
        });
      } else {
        return res.json().then((err) => {
          jobList = [];
          showProgress = false;
          this.setState({ jobList, showProgress });
        });
      }
    });
  };

  clearJobFilter = () => {
    let { jobFilter } = this.state;
    
    let jobFilters = localStorage.getItem('jobFilter');
    if (jobFilters && jobFilter) {
      jobFilters = JSON.parse(jobFilters);

      let filters = jobFilters.filter(f => f.jobTitle !== jobFilter.jobTitle);
      localStorage.setItem("jobFilter", JSON.stringify(filters));
      jobFilter = null;
      this.setState({ jobFilter });
      this.onLoadAllJobs(0);
    }
  };

  showToast = (severity, details) => {
    this.toast.show(ToastMessage.getMessage(severity, details));
  };

  onChangePaginator = (e) => {
    let { paginator, pageCallRef } = this.state;
    paginator.page = e.page * paginator.limit;
    this.setState({ paginator });
    
    if (pageCallRef.no) {
      this.onLoadAllJobs(e.page);
    } 
    else if (pageCallRef.all) {
      const option = paginator.option;
      this.onLoadJobList(option['jobTitle'], option['address'], e.page);
    }
    else if (pageCallRef.title) {
      const option = paginator.option;
      this.onLoadJobListByJobTitle(option['jobTitle'], e.page);
    }
    else if (pageCallRef.address) {
      const option = paginator.option;
      this.onLoadJobListByLocation(option['address'], e.page);
    }
  };
  render() {
    const {
      metadata,
      filteredAddesses,
      selectedAddress,
      filteredKeywords,
      selectedKeyword,
      jobList,
      suggestedJobs,
      jobFilter,
      paginator,
      showProgress,
    } = this.state;

    let selectedJobFilter = jobFilter && Object.values(jobFilter).filter(v => v !== null && v !== true);
    return (
      <section
        id="jobs"
        className="inner-page-section pt--100 pt_lg--90 pb--70 pb_lg-120"
      >
        <Toast ref={(el) => (this.toast = el)} />
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container text-left">
          <div className="row">
            <div className="col-lg-12">
              <div className="jobSearch-find-form pt--30 pt_lg-30">
                <div className="row">
                  <div className="col-lg-5 mb--20 mb-lg-10">
                    <AutoComplete
                      value={selectedKeyword}
                      suggestions={filteredKeywords}
                      completeMethod={this.onFilterKeywordList}
                      field="name"
                      onChange={(e) =>
                        this.setState({ selectedKeyword: e.value })
                      }
                      placeholder="job title or keyword"
                      // placeholder="Job title or keyword"
                    />
                    <FaSearch />
                  </div>
                  <div className="col-lg-5 mb--20 mb-lg-10">
                    <AutoComplete
                      value={selectedAddress}
                      suggestions={filteredAddesses}
                      completeMethod={this.onFilterAddressList}
                      field="addressName"
                      onChange={(e) =>
                        this.setState({ selectedAddress: e.value })
                      }
                      placeholder="city state zip code or remote"
                      // placeholder="City, state, zip code or remote"
                    />
                    <FaMapMarkerAlt />
                  </div>
                  <div className="col-lg-2 mb--20 mb-lg-10">
                    <button
                      className="submit-btn"
                      type="submit"
                      onClick={this.onClickFindJobs}
                    >
                      Find Jobs
                    </button>
                    <FaSearch style={{ color: "white" }} />
                  </div>
                </div>
              </div>
            </div>
            <main className="col-lg-12 order-lg-last main-content mb--60 mb-lg-0">
              {/*
              <div className="row hover-effect-parent4 job-filter">
                {modifiedFilter.map((select, i)=>{
                  return <div className="col-lg-3 col-md-4" key={i}>
                    {select}
                    </div>
                })}
                { moreFilter.length> 0 &&
                  <div className="col-lg-3 col-md-4">
                  <div className="mor-filter"
                  onClick={this.onClickMoreFilter}>More Filter  <FaCaretDown/></div>
                  {isMoreFilter &&
                  <div className="mor-filter-plat">
                  {moreFilter.map((select, i)=>{
                    return <div>{select}</div>
                    })}
                  </div>}
                </div>
                }
              </div>
              */}

              <div className="tab-header card p-1">
                <span className="tab-header-text">
                  Jobs Found ({paginator.totalFound})
                </span>
                <div className="text-right">
                  <span className="mr-2">
                    <span className="mr-2">Filters:</span>
                    {selectedJobFilter &&
                      selectedJobFilter.map((v, i) => (
                        <span key={i} className="mr-2">
                          <FaRegCircle style={{ fontSize: "10px" }} /> {v}
                        </span>
                      ))}
                  </span>
                  <FaTimesCircle
                    title="Clear filter"
                    style={{ cursor: "pointer" }}
                    onClick={this.clearJobFilter}
                  />
                  <a href="/job-alert">
                    <FaEnvelope className="job-alert" title="Get Job Alert" />
                  </a>
                </div>
              </div>
              <JobList
                history={this.props.history}
                jobList={jobList}
                toast={this.showToast}
              />

              {suggestedJobs.length > 0 && 
              <div className="mt--10">
                <div className="tab-header card p-2">
                  <span className="tab-header-text">
                    Suggested Jobs ({suggestedJobs.length})
                  </span>
                </div>
                <JobList
                  history={this.props.history}
                  jobList={suggestedJobs}
                  toast={this.showToast}
                />
              </div>
              }
              <div className="col-12 pl-0 pr-0">
              <Paginator rows={paginator.limit} totalRecords={paginator.totalFound} totalPages={paginator.totalPage} first={paginator.page} last={paginator.limit} onPageChange={this.onChangePaginator}
                style={{ background: "initial", border: "none" }}></Paginator>
              </div>
            </main>
          </div>
        </div>
      </section>
    );
  }
}
export default FindJobs;

import React, { Component } from "react";
import { Link} from "react-router-dom";
import { FaEnvelope, FaKey, FaSignInAlt } from "react-icons/fa";
import SectionHeader from "../../common/section-header";
import TokenHandler from "../../auth/TokenHandler";
import { PrivateService } from "../../auth/PrivateService";
import { Helmet } from "react-helmet";
import decode from 'jwt-decode';
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";


const title = <h3 className="mb--15">Sign In now</h3>;
const subtitle = (
  <h6 className="sub-title" style={{ lineHeight: "normal" }}>
    Don't miss job notifications, employer messages. <br /> Keep Sign In.{" "}
  </h6>
);
const alignment = "section-header text-lg-center pb--40";

const signInTitle = <h3>Create profile now</h3>;
const signInSub = (
  <h6 className="sub-title" style={{ lineHeight: "normal" }}>
    If you don't have an account, <br />
    Let's{" "}
    <Link to="/signup" className="btn btn-outline-primary">
      Sign Up
    </Link>{" "}
    now
  </h6>
);
const signInAlignment =
  "section-header text-lg-center pt_lg--185 pt--40 text-center";



class Signin extends Component {
  constructor(props){
    super(props);
    this.state={
      email:'',
      pass:'',
      metadata:null,
      showProgress:false,
      errors: {}
    }
    this.tokenHandler=new TokenHandler();
    this.privateService = new PrivateService();
  }
  componentDidMount() {
    let {errors} = this.state;
    fetch('metaData.json').then(res=>res.json().then(data=>{this.setState({metadata:data.signin})}));
    const search = window.location.search;
    if(search){
      console.log('path', search);
      if(search.includes('?error=')){
        let error = search.split('=')[1];
        if(error && error === 'auth_param_miss'){
          errors['error'] = `Sign-in is failed for missing info`;
        }
        else if(error && error === 'auth_token_miss'){
          errors['error'] = `Sign-in is failed for authorized token`;
        }
        else if(error && error === 'auth_token_invalid'){
          errors['error'] = `Sign-in is failed for invalid authorized token`;
        }
        else if(error && error === 'auth_access_denied'){
          errors['error'] = `Access denied to employer portal`;
        }
        this.tokenHandler.clearSession();
      }
      else if(search.includes('?signout=auth_yes')){
        this.tokenHandler.clearSession();
        window.location.replace("/signin");
      }
    }
  }
  onChangeEmail=(e)=>{
    let {email, errors} = this.state;
    errors['email']='';
    errors['error']='';
    email=e.target.value;
    this.setState({email,errors});
  }
  onChangePass=(e)=>{
    let {pass, errors} = this.state;
    errors['pass']='';
    errors['error']='';
    pass=e.target.value;
    this.setState({pass, errors});
  }
  onSignin=()=>{
    let {email, pass, showProgress, errors} = this.state;
    if(email==='' || pass===''){
      if(email===''){
        errors['email']='Email is required.';
      }
      if(pass===''){
        errors['pass']='Password is required';
      }
      this.setState({errors});
      return;
    }
    
    this.setState({showProgress:true});
    this.privateService.signin(email, pass)
    .then(res => {
      if(res.status === 200) {
        return res.json().then(body => {
          this.tokenHandler.setToken(body);
          showProgress = false;
          this.setState({showProgress});
          if(this.props.location.state===undefined){
            const accsTokn = decode(body.access_token);
            const roles = accsTokn.authorities;
            if(roles[0] === 'ROLE_JOBSEEKER') {
              window.location.replace("/");
              // window.location.replace("/profile");
              // window.location.href = `${process.env.REACT_APP_REDIRECT_TO_SEEKER}/auth?token=${body.access_token}`;
            } else if(roles[0] === 'ROLE_EMPLOYER') {
              // window.location.replace("/employer");
              window.location.href = `${process.env.REACT_APP_REDIRECT_TO_EMP}/auth?token=${body.access_token}`;
            } else {
              window.location.replace("/");
            }
            
          }else{
          window.location.replace(this.props.location.state.referer.pathname);
          }
        })
      } else if(res.status === 400) {
        return res.json().then(body => {
          errors['error'] = `Username or Password isn't matched`;
          showProgress = false;
          this.setState({showProgress});
        })
      } else {
        return res.json().then(body => {
          console.log('body', body);
          showProgress = false;
          this.setState({showProgress});
        })
      }
    })
    
  }
  render() {
    const {email, pass, metadata, showProgress, errors} = this.state;
    return (
      <section id="signup" className="profile-section bg-ash-color pb--60">
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container position-relative pt--100 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-lg-7 order-lg-last">
              <div className="profile-section-content text-center text-lg-left">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  alignment={alignment}
                />

                <div className="profile-info-container-style1 hover-effect-parent1 text-left">
                  <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
                    <div className="content max-width-425">
                      <h4 className="font-weight-bold mb-3 mt-0">Sign In</h4>
                      <div className="row">
                        <div className="col-lg-12 mb--10 mb-lg-10">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaEnvelope />
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              value={email}
                              onChange={this.onChangeEmail}
                            />
                          </div>
                          <span className="error-msg">{errors["email"]}</span>
                        </div>
                        <div className="col-lg-12 mb--10 mb-lg-10">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaKey />
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              value={pass}
                              onChange={this.onChangePass}
                            />
                          </div>
                          <span className="error-msg">{errors["pass"]}</span>
                          <span className="error-msg">{errors["error"]}</span>
                        </div>
                        <div className="col-lg-12 mb--10 mb-lg-10">
                          <div
                            className="input-group"
                            style={{ display: "inline-grid" }}
                          >
                            <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={this.onSignin}
                            >
                              <span>
                                <FaSignInAlt />
                              </span>{" "}
                              Sign In
                            </button>
                            <Link to="/forgot-password">Forgot Password?</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-lg-first pr_lg--100">
              <SectionHeader
                title={signInTitle}
                subtitle={signInSub}
                alignment={signInAlignment}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Signin;

import React, { Component } from 'react';
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare, FaInstagramSquare, FaPinterestSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-top pt--100 pt_sm--20 pb--40">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-2">
                                <div className="footer-widget text-left mb--30 mb-lg-0">
                                    <Link to="/" className="footer-logo">
                                        <img 
                                            src={`${process.env.PUBLIC_URL}/assets/images/footer-logo.png`}
                                            alt="logo"
                                        />
                                    </Link>
{/*                                     
                                    <ul className="footer-contact-info pl-0 list-unstyled">
                                        <li><a href="mailto:info@example.com?Subject=Hello%20again" target="_top"><strong>Email:</strong> info@example.com</a></li>

                                        <li><a href="tel:448448737363"><strong>Phone:</strong> +4484 4873 7363</a></li>
                                    </ul>
                                    <form className="footer-email-form">
                                        <input 
                                            type="email" 
                                            name="email" 
                                            placeholder="Email"
                                        />
                                        <FaArrowRight />
                                    </form> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-1"></div>
                            <div className="col-md-6 col-lg-2">
                                <div className="footer-widget text-left mb--30 mb-lg-0">
                                    <h5 className="footer-title">Job Seeker</h5>
                                    <ul className="list-ustyled pl-0 pb-0 list-unstyled">
                                        <li><Link to="/jobs">Find Jobs</Link></li>
                                        <li><Link to="/resume">Create Resume</Link></li>
                                        <li><Link to="/profile">Profile</Link></li>
                                        <li><Link to="/signin">Sign In</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <div className="footer-widget text-left mb--30 mb-lg-0">
                                    <h5 className="footer-title">Employer</h5>
                                    <ul className="list-ustyled pl-0 pb-0 list-unstyled">
                                        <li><Link to="/post-job">Post Jobs</Link></li>
                                        <li><Link to="/employer">Profile</Link></li>
                                        {/* <li><Link to="/">Search Resumes</Link></li> */}
                                        <li><Link to="/signin">Sign In</Link></li>
                                    </ul>
                                </div>
                            </div>
                            {/** 
                            <div className="col-md-6 col-lg-2">
                                <div className="footer-widget text-left text-lg-left mb--30 mb-lg-0">
                                    <h5 className="footer-title">Social Media</h5>
                                    <ul className="list-ustyled pl-0 pb-0 list-unstyled">
                                        <li><a href="https://business.facebook.com/LiviamInc?business_id=694228997367168&ref=bookmarks"
                                        target="_blank" rel="noopener noreferrer"><FaFacebookSquare/> Facebook</a></li>
                                        <li><a href="https://www.linkedin.com/company/liviam-inc-?trk=company_logo"
                                        target="_blank" rel="noopener noreferrer"><FaLinkedin/> Linkedin</a></li>
                                        <li><a href="https://twitter.com/Liviaminc"
                                        target="_blank" rel="noopener noreferrer"><FaTwitterSquare/> Twitter</a></li>
                                        <li><a href="https://www.instagram.com/liviaminc/"
                                        target="_blank" rel="noopener noreferrer"><FaInstagramSquare/> Instagram</a></li>
                                        <li><a href="https://www.pinterest.com/liviaminc/"
                                        target="_blank" rel="noopener noreferrer"><FaPinterestSquare/> Pinterest</a></li>
                                    </ul>
                                </div>
                            </div>
                                */}
                            <div className="col-md-6 col-lg-2">
                                <div className="footer-widget text-left mb--30 mb-lg-0">
                                    <h5 className="footer-title">Company</h5>
                                    <ul className="list-ustyled pl-0 pb-0 list-unstyled">
                                        <li><Link to="/contact">Contact</Link></li>
                                        <li><Link to="/aboutus">About us</Link></li>
                                        <li><Link to="/terms-of-use">Terms & Conditions</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        {/* <li><Link to="/faq">FAQ</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="justify-content-between align-items-center">
                            <p>&copy;  Copyrights 2015 - {new Date().getFullYear()} <Link to="/">Liviam Inc</Link> All rights reserved.</p>
                            {/* <p>Made width <span className="ti-heart"></span>by <Link to="/"> axsis</Link></p> */}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
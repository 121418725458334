import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SectionHeader from '../../common/section-header';
import MyResumes from './MyResumes';
import BasicInfo from "./BasicInfo";
import AppliedJobs from "./AppliedJobs";
import SavedJobs from "./SavedJobs";
import ReceivedMessages from "./ReceivedMessages";
import ChangePassword from "./ChangePassword";
import { UserService } from "../../service/UserService";
import { Helmet } from "react-helmet";
import { Toast } from "primereact/toast";
import { ToastMessage } from "../../utils/ToastMessage";
import TokenHandler from "../../auth/TokenHandler";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";


const title = <h5 className="m-0">Welcome Back!</h5>;

const alignment = "section-header text-lg-center pb--10";

class ProfileLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata:null,
      profileInfo : null,
      showProgress:true
    }
    this.userService = new UserService();
    this.tokenHandler = new TokenHandler();
  }

  componentDidMount(){
    let { showProgress } = this.state;
    let refLink = window.location.pathname;

    let isAuthenticated = this.tokenHandler.isLoggedIn();
    const token = this.tokenHandler.getToken();
    const roles = this.tokenHandler.getUserRoles();

    if((refLink === '/profile' || refLink === '/resume') && isAuthenticated) {
      if(roles.length>0 && roles[0] === 'ROLE_JOBSEEKER'){
        showProgress = false;
        this.setState({showProgress});
        let url =  `${process.env.REACT_APP_REDIRECT_TO_SEEKER}/auth?token=${token.access_token}`;
        window.location.replace(url);
      }
    }
    
    
    // fetch('metaData.json').then(res=>res.json().then(data=>{this.setState({metadata:data.jobseeker})}))
    // this.onLoadProfileInfo();
  }

  onLoadProfileInfo=()=> {
    this.userService.loadLoggedUserProfile()
    .then(res=>{
      if(res.status === 302){
        return res.json().then(body=>{this.setState({profileInfo: body})});
      }
    })
  }
  showToast=(severity, details)=>{
    this.toast.show(ToastMessage.getMessage(severity, details));
  }
  render() {
    const {profileInfo, metadata, showProgress} = this.state;
    let name=profileInfo && profileInfo.fullName ? profileInfo.fullName : 'User';
    let subtitle = (
      <span className="sub-title" style={{ lineHeight: "normal" }}>
        <h3>{name}</h3>
        <h6>Profile Information</h6>
      </span>
    );
    return (
      <section
        id="profile-layout"
        className="profile-section bg-ash-color pb--60"
      >
        <Toast ref={(el) => (this.toast = el)}/>
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <div className="container position-relative pt--100 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            {/* <div className="col-lg-12 order-lg-last">
              <div className="profile-section-content text-center text-lg-left">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  alignment={alignment}
                />
                <div className="col-12">
                  <Tabs>
                    <TabList>
                      <Tab>Basic Info</Tab>
                      <Tab>My Resumes</Tab>
                      <Tab>Applied Jobs</Tab>
                      <Tab>Saved Jobs</Tab>
                      <Tab>Messages</Tab>
                      <Tab>Password</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="">
                        <BasicInfo toast={this.showToast}/>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <MyResumes toast={this.showToast}/>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <AppliedJobs />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <SavedJobs />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <ReceivedMessages/>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                       <ChangePassword toast={this.showToast}/>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div> */}

            <ProgressSpinnerLoader showBar={showProgress} />
          </div>
        </div>
      </section>
    );
  }
}
export default ProfileLayout;

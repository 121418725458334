import React, { Component } from "react";
import {
  FaRegClock,
  FaTextHeight,
  FaRegHeart,
  FaBriefcase,
  FaMapMarkerAlt,
  FaAlignJustify,
  FaRegCalendarAlt,
} from "react-icons/fa";
import StarRatings from "react-star-ratings";
import JobInSide from "./JobInSide";
import { DateHandler } from "../../utils/DateHandler";
import { FileHandler } from "../../utils/FileHandler";
import TokenHandler from "../../auth/TokenHandler";
import { FavoriteJobService } from "../../service/FavoriteJobService";

class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetails: false,
      listClass: "col-12",
      detailClass: "",
      selectedJob: null,
      selectedIndex: "",
      jobSaveBtn:false,
    };
    this.tokenHandler=new TokenHandler();
    this.favoriteJobService = new FavoriteJobService();
  }

  componentDidMount(){
    let isAuthenticated= this.tokenHandler.isLoggedIn();
    const roles = this.tokenHandler.getUserRoles();
    if(isAuthenticated && roles.length>0){
      if(roles[0] === 'ROLE_JOBSEEKER'){
        this.setState({jobSaveBtn:true})
      }
    }
  }
  onClickJob = (job, index) => {
    let { selectedIndex, selectedJob } = this.state;
    selectedIndex = index;
    // selectedJob = job;
    selectedJob = {
      jobId: job.jobId,
      jobCode: job.jobCode,
      companyId: job.comId,
    };
    this.setState({ selectedIndex, selectedJob });
    let width = document.body.clientWidth;
    if (width > 768) {
      // let { isDetails, detailClass, listClass } = this.state;
      // isDetails = true;
      // detailClass = "col-8 pd0";
      // listClass = "col-4 pd0";
      // this.setState({ isDetails, detailClass, listClass });

      let jobDetailState = {
        selectedJob: selectedJob,
      };
      this.props.history.push("/job/details", jobDetailState);
    } else {
      let jobDetailState = {
        selectedJob: selectedJob,
      };
      this.props.history.push("/mobile/job/details", jobDetailState);
    }
  };

  onCloseJobDetails =()=> {
    let {selectedIndex, isDetails, detailClass, listClass } = this.state;
    selectedIndex="";
      isDetails = false;
      detailClass = "";
      listClass = "col-12";
      this.setState({ selectedIndex, isDetails, detailClass, listClass });
  }

  saveJobAsFavorite=(jobId)=> {
    let isAuthenticated= this.tokenHandler.isLoggedIn();
    const roles = this.tokenHandler.getUserRoles();
    if(isAuthenticated && roles.length>0){
      this.favoriteJobService.saveFavoriteJob(jobId)
      .then((res) => {
        if(res.status === 201){
          return res.json().then((body) => {
            this.props.toast(1, body.message);
          })
        } else {
          return res.json().then((err) => {
            this.props.toast(4, err.message);
          })
        }
      })
    }
  }
  render() {
    const {
      isDetails,
      detailClass,
      listClass,
      selectedIndex,
      selectedJob,
      jobSaveBtn
    } = this.state;
    const {jobList} = this.props;
    return (
      <div className="row hover-effect-parent4 justify-content-center justify-content-lg-start mt--5 mt-lg-30">
        <div className={listClass}>
          {jobList.map((val, i) => (
            <div
              className={`col-md-12 mb--30 mb-lg-0 pd0 ${
                i === selectedIndex ? "selected-job" : ""
              }`}
              key={i}
              
            >
              <div className="job-item text-lg-left">
                <div className="job-thumb">
                <img src={FileHandler.getLogoSource({logoType: val.comLogoType, logoContent: val.comLogoContent})} alt="Company Logo"/>
                  <small style={{ marginLeft: "10px" }}>
                    <StarRatings
                      numberOfStars={5}
                      rating={val.comRating}
                      starDimension={"15px"}
                      starSpacing={"1px"}
                    />
                  </small>
                  {jobSaveBtn && <span title="Save this job" className="jobSave">
                    <FaRegHeart onClick={(e)=>this.saveJobAsFavorite(val.jobId)}/>
                  </span>}
                </div>
                <div className="job-content border-effect">
                  <a href="#" onClick={() => this.onClickJob(val, i)}>
                    <h5 className="title" style={{ margin: "0px" }}>
                      {val.jobTitle}
                    </h5>
                  </a>
                  <span className="company-location">
                    <FaBriefcase /> {val.comName}, <FaMapMarkerAlt />{" "}
                    {val.comLocation}
                  </span>
                  <div>{val.jobSalary && <p>${val.jobSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>}</div>

                  <small dangerouslySetInnerHTML={{ __html: val.jobDescription.length > 300 ? val.jobDescription.slice(0, 300) : val.jobDescription}}>
                  </small>
                  {/* <small dangerouslySetInnerHTML={{ __html: val.jobDescription}}></small> */}
                  <div className="meta-job list-unstyled pl-0 d-flex justify-content-between">
                    <div>
                      <span className="icon">
                        <FaTextHeight />
                      </span>
                      <span className="meta-content">{val.jobType}</span>
                    </div>
                    <div>
                      <span className="icon">
                        <FaRegCalendarAlt />
                      </span>
                    <span className="meta-content">{DateHandler.getDateFromStringAnyFormat(val.jobExpireDate, 'DD MMMM, YYYY')}</span>
                    </div>
                    <div>
                      <span className="icon">
                      <FaRegClock /> 
                      </span>
                      <span className="meta-content">{DateHandler.countDays(val.jobPublishDate, new Date())} days ago</span>
                    </div>
                    <div>
                      <span className="icon">
                        <FaAlignJustify />
                      </span>
                      <span className="meta-content" style={{cursor:'pointer'}}
                      onClick={() => this.onClickJob(val, i)}>
                        Details
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={detailClass}>
          {isDetails && <JobInSide selectedJob={selectedJob} history={this.props.history} 
          onCloseDetails={this.onCloseJobDetails}/>}
        </div>
      </div>
    );
  }
}

export default JobList;

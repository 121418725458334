import React, { Component } from "react";
// import { FaSearch, FaMapMarkerAlt, FaCaretDown } from "react-icons/fa";
import FilterBar from "./FilterBar";
import CompanyList from "./CompanyList";

class FindCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <section
        id="companies"
        className="inner-page-section pt--90 pt_lg--130 pb--70 pb_lg-120"
      >
        <div className="container text-left">
          <div className="row">
            <main className="col-lg-9 order-lg-last main-content mb--60 mb-lg-0">
              <CompanyList />
              <div className="col-12 pl-0 pr-0">
                {/* <Pagination /> */}
              </div>
            </main>
            <aside className="col-lg-3 sidebar order-lg-first">
              <FilterBar />
            </aside>
          </div>
        </div>
      </section>
    );
  }
}
export default FindCompanies;

import React, {Component} from 'react';
import { Link } from 'react-router-dom';

const companies = []
class FilterBar extends Component {
    constructor(props){
        super(props);
        this.state={
            location:[],
            rating:[],
            topReview:[]
        }
    }
    componentDidMount(){
        this.groupByLocation();
        this.groupByRating();
        this.groupByCompanyName();
    }
    groupByCompanyName=()=>{
        let {topReview} = this.state;
        const groupedMap = companies.reduce(
            (entryMap, e) => entryMap.set(e.company, [...entryMap.get(e.company)||[], e]),
            new Map()
        );
        const iterator1 = groupedMap[Symbol.iterator]();
        let i=0;
            for (const item of iterator1) {
                i++;
                let obj={
                    count:320+i,
                    name:item[0]
                }
                topReview.push(obj);
              }
              this.setState({topReview});
    }
    groupByLocation=()=>{
        let {location} = this.state;
        const groupedMap = companies.reduce(
            (entryMap, e) => entryMap.set(e.location, [...entryMap.get(e.location)||[], e]),
            new Map()
        );
        const iterator1 = groupedMap[Symbol.iterator]();
            for (const item of iterator1) {
                let obj={
                    count:item[1].length,
                    name:item[0]
                }
                location.push(obj);
              }
              this.setState({location});
    }
    groupByRating=()=>{
        let {rating} = this.state;
        const groupedMap = companies.reduce(
            (entryMap, e) => entryMap.set(e.rating, [...entryMap.get(e.rating)||[], e]),
            new Map()
        );
        const iterator1 = groupedMap[Symbol.iterator]();
            for (const item of iterator1) {
                let obj={
                    count:item[1].length,
                    name:item[0]
                }
                rating.push(obj);
              }
              this.setState({rating});
    }
    render(){
        const {location, rating, topReview} = this.state;
        return(
            <div className="sidebar-wrapper">
                {/* Location Widget */}
                <div className="widget">
                    <h5 className="widget-title">Location</h5>
                    <div className="widget-wrapper">
                    <ul className="data-list list-unstyled">
                {location.map((val, i)=>(
                    <li key={i}>
                        <Link className="d-flex align-items-center justify-content-between" to="/">
                        <span className="text">{val.name}</span>
                        <span className="count">{val.count}</span>
                    </Link>
                    </li>
                ))}  
            </ul>
                    </div>
                </div>

                {/* Rating widget */}
                <div className="widget">
                    <h5 className="widget-title">Rating</h5>
                    <div className="widget-wrapper">
                    <ul className="data-list list-unstyled">
                {rating.map((val, i)=>(
                    <li key={i}>
                        <Link className="d-flex align-items-center justify-content-between" to="/">
                        <span className="text">{val.name}</span>
                        <span className="count">{val.count}</span>
                    </Link>
                    </li>
                ))}  
            </ul>
                    </div>
                </div>

                {/* Top 5 widget */}
                <div className="widget">
                    <h5 className="widget-title">Top 5 Reviewed</h5>
                    <div className="widget-wrapper">
                    <ul className="data-list list-unstyled">
                {topReview.map((val, i)=>(
                    <li key={i}>
                        <Link className="d-flex align-items-center justify-content-between" to="/">
                        <span className="text">{val.name}</span>
                        <span className="count">{val.count}</span>
                    </Link>
                    </li>
                ))}  
            </ul>
                    </div>
                </div>
                              
            </div>
        )
    }
}
export default FilterBar;
import { BaseUriHolder } from "../utils/BaseUriHolder";
import { PublicService } from "../auth/PublicService";
import { PrivateService } from "../auth/PrivateService";


export class CompanyInfoService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
        this.privateService = new PrivateService();
    }

    loadAllCompaniesForPublic() {
        let uri = this.baseUriHolder.getPublic() + '/companies';
        return this.publicService.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    loadCompannyByIdForPublic(companyId) {
        let uri = this.baseUriHolder.getPublic() + '/companies/q?companyId=' + companyId;
        return this.publicService.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    loggedEmployerCompany () {
        let uri = this.baseUriHolder.getEmployer() + '/company';
        return this.privateService.getFetch(uri);
    }

    createNewCompany(companyInfo) {
        let uri = this.baseUriHolder.getEmployer() + '/company';
        return this.privateService.postFetch(uri, companyInfo);
    }

    updateCompany(companyInfo) {
        let uri = this.baseUriHolder.getEmployer() + '/company';
        return this.privateService.putFetch(uri, companyInfo);
    }
}
import { BaseUriHolder } from "../utils/BaseUriHolder";
import { PublicService } from "../auth/PublicService";


export class UserAccountRecoveryService {

    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
    }

    sendCodeIfExistByEmail(info) {
        let uri = this.baseUriHolder.getPublic()+ '/recovery/password/verify-code';
        return this.publicService.postFetch(uri, info);
    }

    checkVerificationCode(info) {
        let uri = this.baseUriHolder.getPublic()+ '/recovery/password/check-code';
        return this.publicService.postFetch(uri, info);
    }

    resetPassword(info) {
        let uri = this.baseUriHolder.getPublic()+ '/recovery/password/reset';
        return this.publicService.postFetch(uri, info);
    }
}
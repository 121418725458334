import React from 'react'
export const TextEditorHeader= [
    <span className="ql-formats" key="0">
    <button className="ql-bold" aria-label="Bold"></button>
    <button className="ql-italic" aria-label="Italic"></button>
    <button className="ql-underline" aria-label="Underline"></button>
    </span>,
    <span className="ql-formats"  key="1">
    <button className="ql-list" aria-label="Ordered List" value="ordered"></button>
    <button className="ql-list" aria-label="Unordered List" value="bullet"></button>
    <button className="ql-link" aria-label="Insert Link" ></button>
    </span>
    ]

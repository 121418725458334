import React, { Component } from "react";
import { Helmet } from "react-helmet";
import SectionHeader from "../../common/section-header";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { DataView } from "primereact/dataview";
import { DateHandler } from "../../utils/DateHandler";
import { FaCircle, FaRegCircle, FaExternalLinkAlt } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { Toast } from "primereact/toast";
import { ToastMessage } from "../../utils/ToastMessage";
import { MessageSenderService } from "../../service/MessageSenderService";

const title = <h5 className="mb--15">Message Details</h5>;
const alignment = "section-header text-lg-center";
const senderStyle = {
  justifyContent: "flex-end",
  display: "flex",
  textAlign: "right",
};
class MessageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
      showProgress: false,
      messageList: [],
      jobInfo: {},
      messageSender: {
        messageTitle: "",
        messageDetail: "",
        jobInfo: false,
        receivers: [],
      },
      errors: {},
    };
    this.messageSenderService = new MessageSenderService();
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    if (!params.jobId || !params.senderId) {
      return;
    }
    fetch("metaData.json").then((res) =>
      res.json().then((data) => {
        this.setState({ metadata: data.notifications });
      })
    );
    this.onLoadMesssageDetails(params.jobId, params.senderId);
  }
  onLoadMesssageDetails = (jobId, userId) => {
    let { messageList, jobInfo } = this.state;
    this.messageSenderService.findMessagesByJob(jobId, userId).then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          messageList = body;
          if (body.length > 0) {
            let obj0 = body[0];
            jobInfo["title"] = obj0.sendFor;
            jobInfo["id"] = jobId;
            jobInfo["senderName"] = obj0.sendBy;
            jobInfo["senderId"] = userId;
          }
          this.setState({ messageList, jobInfo });
        });
      }
    });
  };
  dataviewListItem = (data) => {
    
    const own = data.own;
    let details = "";
    let unreadStatus = !own && data.status === 0;
    
    return (
      <div className={`col-12 pt-1 pb-1 mb-1`} style={own ? {}: senderStyle}>
        <div className="bg-light rounded pl-2 pr-2">
          {data.title && <div style={{ fontWeight: "500" }}>{data.title}</div>}
          <div className="text-break">
            {data.details}
            {unreadStatus && (
              <button
                title="Read Details"
                className="btn btn-secondary btn-sm pt-0 pb-0"
                onClick={() => this.updateReadStatus(data.id)}
              >
                Read
              </button>
            )}
          </div>
          <span style={{ fontSize: "small" }}>
            {DateHandler.getDateFromStringAnyFormat(
              data.date,
              "DD MMM, YYYY hh:mm a"
            )}
          </span>
        </div>
      </div>
    );
  };

  updateReadStatus = (dataId) => {
    const {
      match: { params },
    } = this.props;
    this.messageSenderService.readMessage(dataId).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          this.onLoadMesssageDetails(params.jobId, params.senderId);
        });
      }
    });
  };
  onChangeMessageSender = (e) => {
    let { messageSender ,errors} = this.state;
    messageSender[e.target.name] = e.target.value;
    if (e.target.name === "messageDetail") {
      errors["messageDetail"] = " ";
    }
    this.setState({ messageSender });
  };

  sendMessage = () => {
    let { messageSender, jobInfo, showProgress ,errors} = this.state;
    const {
      match: { params },
    } = this.props;
    // if (messageSender.messageDetail === "") {
    //   return;
    // }
    if(messageSender.messageDetail === ""){
      errors["messageDetail"] = "Enter message details";
      this.setState({ errors });
      return;
    }
    if (messageSender.messageTitle === "") {
      messageSender.messageTitle = jobInfo.title;
    }
    let receivers = [{ profileId: jobInfo.senderId }];
    messageSender["receivers"] = receivers;
    messageSender["jobInfoDto"] = { jobId: jobInfo.id };

    this.setState({ showProgress: true });
    this.messageSenderService.sendMessage(messageSender).then((res) => {
      if (res.status === 201) {
        this.clearSubmitForm();
        this.toast.show(
          ToastMessage.getMessage(1, "Message successfully send")
        );
        showProgress = false;
        this.setState({ showProgress });
        
        this.onLoadMesssageDetails(params.jobId, params.senderId);
        
      } else {
        return res.json().then((err) => {
          this.toast.show(ToastMessage.getMessage(4, err.message));
          showProgress = false;
          this.setState({ showProgress });
        });
      }
    });
  };

  clearSubmitForm=()=> {
    let {messageSender} = this.state;
    messageSender = {
      messageTitle: "",
      messageDetail: "",
      jobInfo : false,
      receivers: [],
    };

    this.setState({messageSender})
    
  }

  render() {
    const { messageList, jobInfo, metadata, messageSender, showProgress,errors } =
      this.state;
    return (
      <section id="msgDetails" className="profile-section bg-ash-color pb--60">
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <Toast ref={(el) => (this.toast = el)} />
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container position-relative pt--100 pb--30 pt_lg--100 pb_lg--50">
          <div className="row">
            <div className="col-lg-1 col-xl-2"></div>
            <div className="col-lg-12 col-12 col-xl-12">
              <SectionHeader title={title} alignment={alignment} />
              {jobInfo && (
                <div
                  className="col-12 rounded-top"
                  style={{ background: "#d3d3d3f5" }}
                >
                  <div>
                    Job : <b>{jobInfo.title}</b>
                  </div>
                  <div>Employer : {jobInfo.senderName}</div>
                </div>
              )}
              <div className="col-12 p-0">
                <DataView
                  value={messageList}
                  layout="list"
                  itemTemplate={this.dataviewListItem}
                ></DataView>
              </div>
              <div className="row mt-3 p-0">
                <div className="col-12 col-md-10 col-lg-9">
                  <textarea
                    type="text"
                    placeholder="Enter message"
                    rows="3"
                    className="form-control"
                    id="message"
                    name="messageDetail"
                    value={messageSender.messageDetail}
                    onChange={this.onChangeMessageSender}
                  />
                  <div className="error-msg" >
                    <span>{errors["messageDetail"]}</span>
                  </div>
                </div>
                <div className="col-12 col-md-2 col-lg-3">
                  <input
                    id="title"
                    name="messageTitle"
                    className="form-control"
                    placeholder="Enter title"
                    type="text"
                    // value={messageSender.messageTitle}
                    value={
                      messageSender.messageTitle !== ""
                        ? messageSender.messageTitle
                        : jobInfo.title
                    }
                    onChange={this.onChangeMessageSender}
                  />
                  <Button
                    label="Reply"
                    className="w-100 mt-3"
                    onClick={this.sendMessage}
                  ></Button>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-xl-2"></div>
          </div>
        </div>
      </section>
    );
  }
}
export default MessageDetails;

import React, { Component } from 'react';
import SectionHeader from '../../common/section-header';
import { FaRegListAlt, FaChartLine } from "react-icons/fa";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { FiCheckCircle } from "react-icons/fi";
// import './../css/screenshot.css';

// section header content const
const title = <h3 className="mb--15">Let's create your profile</h3>;
const subtitle = <h6 className="sub-title">Employers are looking for your profile. Open your account and choose your job. </h6>;
const alignment = 'section-header text-lg-left pb--40';

// section header 2 content const
const title2 = <h3 className="mb--15">Sign up for job alerts </h3>
const subtitle2 = <h6 className="sub-title">Get job alerts straight to your inbox. </h6>
const alignment2 = 'section-header text-lg-left pb--20';


const ssInfoList1 = [
    {
        icon: <FaRegListAlt />,
        title: 'Easy to use',
        description: 'Just create account and stay to use'
    },
    {
        icon: <FaChartLine />,
        title: 'Monitor & manage',
        description: 'Easily manage your account '
    },
    {
        icon: <AiOutlineThunderbolt />,
        title: 'Stay up to date',
        description: 'Get latest update '
    },
]

const ssInfoList2 = [
    {
        icon: <FiCheckCircle />,
        description: 'Enter your email to get started.'
    },
    {
        icon: <FiCheckCircle />,
        description: 'You will be able to unsubscribe at any moment.'
    },
]

class ProfileSection extends Component {
    render() {
        return (
            <section id="profile-section" className="profile-section bg-ash-color pb--60">
                <div className="container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50">
                    <div className="row">
                        <div className="col-lg-5 order-lg-last">
                            <div className="profile-section-content text-center text-lg-left">
                                <SectionHeader 
                                    title={ title } 
                                    subtitle={ subtitle }
                                    alignment={alignment}
                                />

                                <div className="profile-info-container-style1 hover-effect-parent1 text-left">
                                    {ssInfoList1.map( (val , i) => (
                                        <div className="profile-section-info-item d-flex flex-wrap" key={i}>
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h6 className="font-weight-bold mb-2 mt-0">{val.title}</h6>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    ))} 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 order-lg-first pr_lg--100">
                            <div className="profile-section-image image1">
                                <img
                                    src="assets/images/home/signup.png" 
                                    alt="Profile Setup"
                                 />
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="profile-section-content text-center text-lg-left">
                                <SectionHeader 
                                    title={ title2 } 
                                    subtitle={ subtitle2 }
                                    alignment={alignment2}
                                />
                                <div className="profile-info-container-style2 text-left">
                                    {ssInfoList2.map( (val , i) => (
                                        <div className="profile-section-info-item-style2 d-flex flex-wrap" key={i}>
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 pl_lg--100">
                            <div className="profile-section-image image2">
                                <img 
                                    src="assets/images/home/email-alert.png" 
                                    alt="Alert"
                                />
                            </div>
                        </div>  
                    </div>
                </div>

            </section>
        )
    }
}

export default ProfileSection;
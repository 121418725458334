import React, { Component } from "react";
import Select from "../../common/select";
import { Editor } from "primereact/editor";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { TextEditorHeader } from "../../common/editor-header";
import { Calendar } from "primereact/calendar";
import { GlobalInfoService } from "../../service/GlobalInfoService";
import { MultiSelect } from "primereact/multiselect";
import {
  FaDollarSign,
  FaMapMarkerAlt,
  FaRegCalendarAlt,
  FaRegClock,
  FaTextHeight,
  FaUsers,
} from "react-icons/fa";
import { DateHandler } from "../../utils/DateHandler";
import { JobInfoService } from "../../service/JobInfoService";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { Dropdown } from 'primereact/dropdown';

const statusOptions = [
  {label: 'ACTIVE', value: 1},
  {label: 'DEACTIVE', value: 2}
]

class EditJobPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobType: "",
      jobTypes: [],
      jobCategories: [],
      selectedCategories: [],
      jobInfo: {
        jobTitle: "",
        jobDescription: "",
        jobLocation: "",
        jobApplyingWay: "",
        jobApplyingWayValue: "",
        jobPublishDate: "",
        jobExpireDate: "",
        jobOfferingAmount: "",
        numberOfPosition: "",
        byEmail: false
      },
      previwDialog: false,
      showProgress: false,
      errors: {},
    };
    this.globalInfoService = new GlobalInfoService();
    this.jobInfoService = new JobInfoService();
  }

  componentDidMount() {
    this.onLoadSelectedJob();
    this.onLoadJobType();
    this.onLoadJobCategory();
  }

  onLoadSelectedJob =()=> {
    let { jobInfo, selectedCategories, jobType, showProgress } = this.state;
    const {job} = this.props;
    if(job === null) {
      localStorage.setItem("jobPost", "list");
      // localStorage.setItem("jobPost", 0);
      this.props.handleVisible();
      return;
    }
    this.setState({ showProgress: true });
    this.jobInfoService.findJobDetailsForEmployer(job)
    .then(res => {
      if(res.status === 302) {
        return res.json().then(body => {
          jobInfo = body;
          jobType = body.jobType.globalId;
          body.jobCategories.forEach((b) => {
            selectedCategories.push({
              globalName: b.globalName,
              globalId: b.globalId,
            });
          });
          showProgress= false;
          this.setState({jobInfo, selectedCategories, jobType, showProgress});
        })
      }
      else {
        return res.json().then(err => {
          console.log('err', err);
          showProgress= false;
          this.setState({showProgress});
        })
      }
    })
  }

  onLoadJobType = () => {
    let { jobTypes } = this.state;
    jobTypes.push({ label: "Select Job Type", value: 0 });
    this.globalInfoService
      .getGlobalInfosByUseForAndIn("Job", "Type")
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            body.forEach((b) => {
              jobTypes.push({ label: b.globalName, value: b.globalId });
            });
            this.setState({ jobTypes });
          });
        } else {
          this.setState({ jobTypes });
        }
      });
  };

  onLoadJobCategory = () => {
    let { jobCategories } = this.state;
    this.globalInfoService
      .getGlobalInfosByUseForAndIn("Job", "Category")
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            body.forEach((b) => {
              jobCategories.push({
                globalName: b.globalName,
                globalId: b.globalId,
              });
            });
            this.setState({ jobCategories });
          });
        }
      });
  };
  onClickBackJobs = () => {
    // localStorage.setItem("jobPost", 0);
    localStorage.setItem("jobPost", "list");
    this.props.handleVisible();
    this.props.reloadJobList();
  };
 

  onChangeJobInfo = (e) => {
    let { jobInfo, errors } = this.state;
    jobInfo[e.target.name] = e.target.value;
    errors[e.target.name] = "";
    if (e.target.name === "byEmailInput" || e.target.name === "byUrlInput") {
      jobInfo.jobApplyingWayValue = e.target.value;
    }
    this.setState({ jobInfo, errors });
  };

  onTextChangeJobDecrp = (e) => {
    let { jobInfo, errors } = this.state;
    jobInfo.jobDescription = e.htmlValue;
    errors["jobDescription"] = "";
    this.setState({ jobInfo, errors });
  };

  onChangeRadio = (e) => {
    let { jobInfo, errors } = this.state;
    if (e.value === "byEmail") {
      jobInfo.byEmail = true;
      jobInfo["byUrl"] = false;
    } else if (e.value === "byUrl") {
      jobInfo.byEmail = false;
      jobInfo["byUrl"] = true;
    }
    jobInfo.jobApplyingWay = e.value;
    errors["applyingWay"] = "";
    this.setState({ jobInfo, errors });
  };

  onChangeJobType = (e) => {
    let { jobType, errors } = this.state;
    jobType = e.target.value;
    errors["jobType"] = "";
    this.setState({ jobType, errors });
  };

  onChangeJobStatus = (e) => {
    let { jobInfo, errors } = this.state;
    jobInfo.status = e.value;
    errors["jobStatus"] = "";
    this.setState({ jobInfo, errors });
  };

  onClickJobPreview = () => {
    let {
      jobInfo,
      jobType,
      selectedCategories,
      previwDialog,
      errors,
    } = this.state;

    if (!this.validateJobInfo()) {
      return;
    }

    jobInfo["jobType"] = { globalId: jobType };
    jobInfo["jobCategories"] = selectedCategories;
    
    previwDialog = true;
    this.setState({ jobInfo, previwDialog });
  };

  confirmToUpdate = () => {
    let { jobInfo, previwDialog, showProgress, errors } = this.state;

    previwDialog = false;
    this.setState({ showProgress: true });
    this.jobInfoService.updateJobDetails(jobInfo).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          
          this.props.toast(1, "Job Details has successfully Updated");
          showProgress = false;
          this.setState({ previwDialog, showProgress, errors });
          this.onClickBackJobs();
        });
      } else {
        return res.json().then((err) => {
          this.props.toast(4, err.message);
          showProgress = false;
          this.setState({ previwDialog, showProgress, errors });
        });
      }
    });
  };

  validateJobInfo = () => {
    let { jobInfo, jobType, selectedCategories, errors } = this.state;
    let val = true;
    if (jobInfo.jobTitle === "" || jobInfo.jobTitle === undefined) {
      errors["jobTitle"] = "Enter Job Title";
      val = false;
    }
    if (jobInfo.jobDescription === "" || jobInfo.jobDescription === undefined) {
      errors["jobDescription"] = "Enter Job Description";
      val = false;
    }
    if (jobInfo.jobApplyingWay === "" || jobInfo.jobApplyingWay === undefined) {
      errors["applyingWay"] = "Check any one from those type";
      val = false;
    }
    if (
      jobInfo.jobApplyingWayValue === "" ||
      jobInfo.jobApplyingWayValue === undefined
    ) {
      errors[jobInfo.jobApplyingWay] = "Enter a valid address";
      val = false;
    }
    if (jobInfo.jobExpireDate === "" || jobInfo.jobExpireDate === undefined) {
      errors["jobExpireDate"] = "Select a expire date";
      val = false;
    }
    if (jobType === "" || jobType === "undefined") {
      errors["jobType"] = "Select Job Type";
      val = false;
    }
    if (selectedCategories.length === 0 || selectedCategories === "undefined") {
      errors["jobCategories"] = "Select Job Categories";
      val = false;
    }
    this.setState({ errors });
    return val;
  };

 
  render() {
    const {
      jobInfo,
      jobType,
      jobTypes,
      jobCategories,
      previwDialog,
      showProgress,
      errors,
    } = this.state;

    console.log("Appling Type : ",jobInfo.jobApplyingWay);
    // let redioSelection = jobInfo.jobApplyingWay=="byEmail" ? jobInfo.byEmail==true : jobInfo["byUrl"] = true;
    let  pblasDate = jobInfo.jobPublishDate ? DateHandler.getDateFromStringDDMMYYYY(jobInfo.jobPublishDate) : null;
    let  expDate = jobInfo.jobExpireDate ? DateHandler.getDateFromStringDDMMYYYY(jobInfo.jobExpireDate) : null;
    return (
      <div className="row">
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="col-9">
          <div className="tab-header card pl-1 mb--5">
            <span className="tab-header-text">Update Job</span>
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-outline-primary"
                onClick={this.onClickBackJobs}
                style={{ padding: "revert" }}
              >
                Back to all jobs
              </button>
            </div>
          </div>
          <div>
            <div className="profile-info-container-style1 hover-effect-parent1">
              <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
                <div className="content max-width-425">
                  <h4 className="font-weight-bold mb--30 mb-lg-30 mt-1">
                    Update Job Info
                  </h4>
                  <div className="row">
                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Job Code</label>
                        <input
                          id="jobCode"
                          type="text"
                          disabled
                          className="form-control"
                          value={jobInfo.code}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Job Status</label>
                        <Dropdown id="jobStatus" value={jobInfo.status} options={statusOptions} 
                        onChange={this.onChangeJobStatus} optionLabel="label" placeholder="Select Status" />
                      </div>
                      <span className="error-msg">{errors["jobStatus"]}</span>
                    </div>

                    <div className="col-lg-12 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Job Title *</label>
                        <input
                          id="jobTitle"
                          name="jobTitle"
                          type="text"
                          className="form-control"
                          value={jobInfo.jobTitle}
                          onChange={this.onChangeJobInfo}
                        />
                      </div>
                      <span className="error-msg">{errors["jobTitle"]}</span>
                    </div>

                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Job Type *</label>
                        <Select
                          className="form-control"
                          name="jobType"
                          value={jobType}
                          onChange={this.onChangeJobType}
                          options={jobTypes}
                        />
                      </div>
                      <span className="error-msg">{errors["jobType"]}</span>
                    </div>

                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Categories *</label>
                        <MultiSelect
                          value={this.state.selectedCategories}
                          options={jobCategories}
                          onChange={(e) =>
                            this.setState({ selectedCategories: e.value })
                          }
                          optionLabel="globalName"
                          placeholder="Select Categories"
                          filter
                        />
                      </div>
                      <span className="error-msg">
                        {errors["jobCategories"]}
                      </span>
                    </div>

                    <div className="col-6 mb--10 mb-lg-10">
                      <label>Offering Salary</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <FaDollarSign />
                          </span>
                        </div>

                        <input
                          id="jobOfferingAmount"
                          type="text"
                          name="jobOfferingAmount"
                          className="form-control"
                          value={jobInfo.jobOfferingAmount}
                          onChange={this.onChangeJobInfo}
                        />
                      </div>
                      <span className="error-msg">
                        {errors["jobOfferingAmount"]}
                      </span>
                    </div>

                    <div className="col-6 mb--10 mb-lg-10">
                      <label>Number of Position</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <FaUsers />
                          </span>
                        </div>

                        <input
                          id="numberOfPosition"
                          type="text"
                          name="numberOfPosition"
                          className="form-control"
                          value={jobInfo.numberOfPosition}
                          onChange={this.onChangeJobInfo}
                        ></input>
                      </div>
                      <span className="error-msg">
                        {errors["numberOfPosition"]}
                      </span>
                    </div>

                    <div className="col-lg-12 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Job Description *</label>
                        <Editor
                          headerTemplate={TextEditorHeader}
                          style={{ height: "200px" }}
                          value={jobInfo.jobDescription}
                          onTextChange={this.onTextChangeJobDecrp}
                        />
                      </div>
                      <span className="error-msg">
                        {errors["jobDescription"]}
                      </span>
                    </div>

                    <div className="col-lg-12 mb--10 mb-lg-10">
                      <label>Location</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <FaMapMarkerAlt />
                          </span>
                        </div>
                        <input
                          id="location"
                          type="text"
                          name="jobLocation"
                          className="form-control"
                          value={jobInfo.jobLocation}
                          onChange={this.onChangeJobInfo}
                        />
                      </div>
                      <span className="error-msg">{errors["jobLocation"]}</span>
                    </div>

                    <div className="col-lg-12 mb--10 mb-lg-10">
                      <span className="font-weight-bold mb--10 mb-lg-10 mt--10 mt_lg-10">
                        How to Apply
                      </span>
                    </div>

                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <RadioButton
                          name="howToApply"
                          value="byEmail"
                          onChange={this.onChangeRadio}
                          checked={jobInfo.jobApplyingWay ==='byEmail'}
                        />
                        <label style={{ marginLeft: "10px", marginBottom: "0px" }}>By Email</label>
                        <input
                          id="byEmailInput"
                          name="byEmailInput"
                          type="text"
                          disabled={jobInfo.byUrl}
                          className="form-control"
                          value={jobInfo.byEmailInput}
                          placeholder={
                            jobInfo.jobApplyingWayValue
                          }
                          onChange={this.onChangeJobInfo}
                        />
                      </div>
                      <span className="error-msg">
                        {errors["byEmailInput"]}
                      </span>
                    </div>

                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <RadioButton
                          name="howToApply"
                          value="byUrl"
                          onChange={this.onChangeRadio}
                          checked={jobInfo.jobApplyingWay === 'byUrl'}
                        />
                        <label style={{ marginLeft: "10px", marginBottom: "0px" }}>By URL</label>

                        <input
                          id="byUrlInput"
                          name="byUrlInput"
                          type="text"
                          disabled={jobInfo.byEmail}
                          className="form-control"
                          value={jobInfo.byUrlInput}
                          placeholder={
                            jobInfo.jobApplyingWayValue
                          }
                          onChange={this.onChangeJobInfo}
                        />
                      </div>
                      <span className="error-msg">{errors["byUrlInput"]}</span>
                    </div>

                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Publish Date *</label>
                        <Calendar
                          showIcon
                          name="jobPublishDate"
                          value={jobInfo.jobPublishDate}
                          placeholder={pblasDate}
                          onChange={this.onChangeJobInfo}
                          monthNavigator
                        />
                      </div>
                      <span className="error-msg">
                        {errors["jobPublishDate"]}
                      </span>
                    </div>

                    <div className="col-lg-6 mb--10 mb-lg-10">
                      <div className="form-group">
                        <label>Expiration Date *</label>
                        <Calendar
                          showIcon
                          name="jobExpireDate"
                          value={jobInfo.jobExpireDate}
                          placeholder={expDate}
                          onChange={this.onChangeJobInfo}
                          monthNavigator
                        />
                      </div>
                      <span className="error-msg">
                        {errors["jobExpireDate"]}
                      </span>
                    </div>

                    <div className="w-100 text-center mb--10 mb-lg-10">
                      <button
                        className="btn btn-primary w-50"
                        type="submit"
                        onClick={this.onClickJobPreview}
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* product */}
        <div className="col-3">
          <div className="card">
            <div className="card-header">Product Info</div>
            <div className="card-body">
              <b>{jobInfo.productInfoDto && jobInfo.productInfoDto.productName}</b>
              <br />
              {jobInfo.productInfoDto && jobInfo.productInfoDto.productDescription}
            </div>
            <div className="card-footer">
              {jobInfo.productInfoDto && jobInfo.productInfoDto.productPrice > 0
                ? `$${jobInfo.productInfoDto && jobInfo.productInfoDto.productPrice}`
                : "FREE"}
            </div>
          </div>
        </div>

        <Dialog
          header="Job Preview"
          visible={previwDialog}
          maximizable
          modal
          style={{ width: "50vw", maxHeight: "75vh" }}
          footer={
            <div>
              <Button
                label="Back to Edit"
                icon="pi pi-arrow-left"
                onClick={() => this.setState({ previwDialog: false })}
              />
              <Button
                label="Confirm to Update"
                icon="pi pi-check"
                onClick={this.confirmToUpdate}
                autoFocus
              />
            </div>
          }
          onHide={() => this.setState({ previwDialog: false })}
        >
          <div className="job-details" style={{ boxShadow: "none" }}>
            
            <div className="col-12 pd0 job-title">
              <p>{jobInfo.jobTitle}</p>
              <span className="tags">
                <span>
                  <FaTextHeight />{" "}
                  {jobTypes.map((j, i) => {
                    if (
                      jobInfo.jobType &&
                      j.value === jobInfo.jobType.globalId
                    ) {
                      return <span>{j.label}</span>;
                    }
                  })}
                </span>
                <span>
                  <FaDollarSign /> {jobInfo.jobOfferingAmount}
                </span>
                <span>
                  <FaUsers /> {jobInfo.numberOfPosition}
                </span>
              </span>
              <span className="tags">
                <span>
                  Published:
                  {DateHandler.getDateFromStringAnyFormat(
                    jobInfo.jobPublishDate,
                    "DD MMM, YYYY"
                  )}
                </span>
                <span>
                  Expiration:
                  {DateHandler.getDateFromStringAnyFormat(
                    jobInfo.jobExpireDate,
                    "DD MMM, YYYY"
                  )}
                </span>
              </span>
            </div>
            <div className="col-12 pd0">
              <p>
                Location: <br />
                {jobInfo.jobLocation}
              </p>
              <p>
                Categories: <br />
                <ul className="ul-li-column">
                  {jobInfo.jobCategories &&
                    jobInfo.jobCategories.map((c, i) => {
                      return <li key={i}>{c.globalName}</li>;
                    })}
                </ul>
              </p>
            </div>
            <div className="col-12 pd0">
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{ __html: jobInfo.jobDescription }}
                ></p>
              </div>
            </div>
            <div className="col-12 pd0">
              <span>
                Apply To: <b>{jobInfo.jobApplyingWayValue}</b>
              </span>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default EditJobPost;

import { PrivateService } from "../auth/PrivateService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class JobApplicationService {
    constructor(){
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    submitJobApplication(jobApp) {
        let uri = this.baseUriHolder.getJobSeeker() +'/job/apply';
        return this.privateService.postFetch(uri, jobApp);
    }

    findApplicantsForEmployer(jobId){
        let uri = this.baseUriHolder.getEmployer() +'/job/applicants?jobId='+jobId;
        return this.privateService.getFetch(uri);
    }

    getApplicantAppliedJobs(){
        let uri = this.baseUriHolder.getJobSeeker() +'/jobs/applied';
        return this.privateService.getFetch(uri);
    }
}
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import SectionHeader from "../../common/section-header";
import { MessageSenderService } from "../../service/MessageSenderService";
import { DateHandler } from "../../utils/DateHandler";
import { FaCircle, FaRegCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { UserService } from "../../service/UserService";
import TokenHandler from "../../auth/TokenHandler";


const title = <h4 className="mb--15">Notifications</h4>;
const alignment = "section-header text-lg-center";

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
      notifications: [],
      selectedNotification: null,
      messageDialog: false,
      showProgress: false,
      basicInfo: null,
      paginator: {
        page: 0,
        limit: 20,
        totalPage: 0,
        totalFound: 0,
      },
    };
    this.userService = new UserService();
    this.messageSenderService = new MessageSenderService();
    this.tokenHandler = new TokenHandler();
  }

  componentDidMount() {
    let isAuthenticated = this.tokenHandler.isLoggedIn();
    const token = this.tokenHandler.getToken();
    const roles = this.tokenHandler.getUserRoles();

    if(isAuthenticated) {
      if(roles.length>0 && roles[0] === 'ROLE_JOBSEEKER'){
        let url =  `${process.env.REACT_APP_REDIRECT_TO_SEEKER}/auth?token=${token.access_token}`;
        window.location.replace(url);
      }
    }

    // fetch("metaData.json").then((res) =>
    //   res.json().then((data) => {
    //     this.setState({ metadata: data.notifications });
    //   })
    // );
    // this.loadNotifications();
  }

  // loadLoggedUserInfo = () => {
  //   let { basicInfo } = this.state;
  //   this.userService.loadLoggedUserProfile().then((res) => {
  //     if (res.status === 302) {
  //       return res.json().then((body) => {
  //         console.log("Body : ",body);
  //         this.setState({ basicInfo: body });
  //         this.loadNotifications(body.profileId);
  //       });
  //     }
      
  //   });
  // };

  loadNotifications = () => {
    let { notifications, paginator } = this.state;
    // console.log("Profile id : ",profileId);

    this.messageSenderService.findUnseenMessages().then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          notifications = body;
          this.setState({ notifications, paginator });
        });
      }
    });
  };

  // loadNotifications = (page) => {
  //   let { notifications, paginator } = this.state;
  //   let paginatorObj = {
  //     page: page,
  //     limit: paginator.limit
  //   };
  //   this.messageSenderService.findUserMessages(paginatorObj).then((res) => {
  //     if (res.status === 302) {
  //       return res.json().then((body) => {
  //         notifications = body.list;
  //         paginator.totalPage = body.pages;
  //         paginator.totalFound = body.count;
  //         this.setState({ notifications, paginator });
  //       });
  //     }
  //   });
  // }

  onChangePaginator = (e) => {
    let { paginator } = this.state;
    paginator.page = e.page * paginator.limit;
    this.setState({ paginator });
    this.loadCityWiseJobsCount(e.page);
  };
  appliedDateTemp = (rowData) => {
    return (
      <span>
        {DateHandler.getDateFromStringAnyFormat(rowData.date, "DD MMM, YYYY")}
      </span>
    );
  };
  statusTemp = (rowData) => {
    if (rowData.status === 0) {
      return <FaCircle style={{ color: "#0782e3" }} />;
    } else if (rowData.status === 1) {
      return <FaRegCircle style={{ color: "#0782e3" }} />;
    }
  };

  messageSubject = (rowData) => {
    let shortTitle =rowData.title;  
    if(shortTitle.length > 20){
      shortTitle = shortTitle.substring(0,20).concat('...');
    }
      return  <><span>{shortTitle}</span></>;

  }

  detailsButtonTemp = (rowData) => {
    return (
      <button
        className="btn btn-outline-primary"
        title="Show details"
        onClick={() => window.location.replace(`/notification/details/${rowData.jobId}/${rowData.senderId}`)}
      >
        <FaExternalLinkAlt />
      </button>
    );
  };

  showNotificationDetails = (selectedObj) => {
    let { selectedNotification, showProgress } = this.state;

    this.setState({ showProgress: true });
    this.setState({ messageDialog: true });
    this.messageSenderService
      .showMessageDetails(
        selectedObj.recipientId,
        selectedObj.title,
        selectedObj.status
      )
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            selectedNotification = body;
            showProgress = false;
            this.setState({ selectedNotification, showProgress });
          });
        } else {
          return res.json().then((err) => {
            showProgress = false;
            this.setState({ showProgress });
          });
        }
      });
  };
  render() {
    const {
      notifications,
      selectedNotification,
      paginator,
      messageDialog,
      metadata,
      showProgress,
    } = this.state;
    return (
      <section id="notify" className="profile-section bg-ash-color pb--60">
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container position-relative pt--120 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-lg-1 col-xl-2"></div>
            <div className="col-lg-12 col-12 col-xl-12">
              {/* <div className="col-lg-10 col-12 col-xl-8"> */}
              <SectionHeader title={title} alignment={alignment} />

              <div className="col-12 pd0">
                <DataTable value={notifications}>
                  <Column
                    field="status"
                    headerStyle={{ width: "1.5em" }}
                    body={this.statusTemp}
                  ></Column>
                  <Column
                    field="date"
                    header="Date"
                    headerStyle={{ width: "6.5em" }}
                    body={this.appliedDateTemp}
                  ></Column>
                  <Column field="sendFor" header="Job Title"></Column>                  
                  <Column field="sendBy" header="Company"></Column>
                  <Column body={this.messageSubject} header="Message Subject"></Column>
                  {/* <Column field="title" header="Message Subject"></Column> */}
                  <Column
                    style={{ textAlign: "center" }}
                    body={this.detailsButtonTemp}
                    header="Details"
                    headerStyle={{ width: "5.5em" }}
                  ></Column>
                </DataTable>
              </div>
              <div className="col-12 pd0">
                <Paginator
                  rows={paginator.limit}
                  totalRecords={paginator.totalFound}
                  totalPages={paginator.totalPage}
                  first={paginator.page}
                  last={paginator.limit}
                  onPageChange={this.onChangePaginator}
                  style={{ background: "initial", border: "none" }}
                ></Paginator>
              </div>
            </div>
            <div className="col-lg-1 col-xl-2"></div>

            <Dialog
              header="Notification Details"
              visible={messageDialog}
              modal
              style={{ width: "50vw", maxHeight: "75vh" }}
              footer={
                <div>
                  <Button
                    label="Close"
                    icon="pi pi-times"
                    onClick={() => this.setState({ messageDialog: false })}
                  />
                </div>
              }
              onHide={() => this.setState({ messageDialog: false })}
            >
              <div className="col-12 mb--5">
                <strong>Title</strong>
                <br />
                {selectedNotification && selectedNotification.title}
              </div>
              <div className="col-12 mb--5">
                <strong>Details</strong>
                <br />
                {selectedNotification && selectedNotification.details}
              </div>
              <div className="col-12 mb--5">
                <strong>Send For</strong>
                <br />
                {selectedNotification && selectedNotification.sendFor}
              </div>

              <div className="col-12 mb--5">
                <strong>Send By</strong>
                <br />
                {selectedNotification && selectedNotification.sendBy}
              </div>
              <div className="col-12">
                <strong>Send Date</strong>
                <br />
                {selectedNotification &&
                  DateHandler.getDateFromStringAnyFormat(
                    selectedNotification.date,
                    "DD MMMM, YYYY"
                  )}
              </div>
            </Dialog>
          </div>
        </div>
      </section>
    );
  }
}
export default NotificationList;
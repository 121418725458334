import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaBriefcase, FaMapMarkerAlt } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { CompanyInfoService } from "../../service/CompanyInfoService";
import { FileHandler } from "../../utils/FileHandler";

class CompanyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
    };
    this.companyInfoService = new CompanyInfoService();
  }

  componentDidMount() {
    let { companies } = this.state;
    this.companyInfoService.loadAllCompaniesForPublic().then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          companies = body;
          this.setState({ companies });
        });
      }
    });
  }

  render() {
    const { companies } = this.state;

    
    return (
      <div className="row hover-effect-parent4 justify-content-center justify-content-lg-start">
        {companies.map((val, i) => (
          <div className="col-md-6 mb--30 mb-lg-0" key={i}>
            <Link to={`/company/${val.companyId}/${val.companyName}`}>
              <div className="job-item text-lg-left">
                <div className="job-thumb">
                  <img
                    src={FileHandler.getLogoSource(val)}
                    alt={`${val.logoName}`}
                  />

                  <small style={{ color: "#6d63e4", marginLeft: "10px" }}>
                    {val.rating}
                    <StarRatings
                      numberOfStars={1}
                      rating={val.rating}
                      starDimension={"15px"}
                      starRatedColor="#6d63e4"
                    />
                  </small>
                  <small style={{ marginLeft: "10px" }}>
                    Review(<b>{val.totalReview}</b>)
                  </small>
                </div>
                <div className="job-content border-effect">
                  <h5 className="title" style={{ margin: "0px" }}>
                    <FaBriefcase /> {val.companyName}
                  </h5>
                  <span className="company-location">
                    <FaMapMarkerAlt /> {val.location}
                  </span>
                  <div className="mt--15">
                    <span className="curnt-job">
                      Current Jobs(<b>{val.totalJob}</b>)
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  }
}
export default CompanyList;

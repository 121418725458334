import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import { Link } from 'react-router-dom';
import TokenHandler from '../auth/TokenHandler';
import { FaRegBell, FaSignOutAlt, FaUserAlt } from 'react-icons/fa';
import { ClearSession } from '../auth/ClearSession';
import { MessageSenderService } from '../service/MessageSenderService';



class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
          unseenMessages:0
        }
        this.tokenHandler=new TokenHandler();
        this.messageSenderService = new MessageSenderService();
      }
      componentDidMount(){
        let {unseenMessages} = this.state;
        let isAuthenticated= this.tokenHandler.isLoggedIn();
        const roles = this.tokenHandler.getUserRoles();
        if(isAuthenticated && roles.length>0){
        this.messageSenderService.countUnseenMessage()
        .then(res => {
          if(res.status === 200){
            return res.json().then(body => {
              unseenMessages = body.message;
              this.setState({unseenMessages});
            })
          }else{
            unseenMessages = 0;
            this.setState({unseenMessages});
          }
        })
      }
      }
    searchTrigger() {
        document.querySelector('body').classList.add('search-open')
    }
    menuTrigger() {
        document.querySelector('body').classList.add('mobile-menu-open')
    }
    closeMenuTrigger() {
        document.querySelector('body').classList.remove('mobile-menu-open')
    }
    onSignOut=()=>{
        this.tokenHandler.clearSession();
        this.props.history.push('/');
    }
    render(){
      const {unseenMessages} = this.state;
        const { headerStyle } = this.props;
        let isAuthenticated= this.tokenHandler.isLoggedIn();
        const roles = this.tokenHandler.getUserRoles();
        let userPath = '/';
        if(isAuthenticated && roles.length>0){
            if(roles[0] === 'ROLE_JOBSEEKER'){
                userPath = '/profile';
            } else if(roles[0] === 'ROLE_EMPLOYER'){
                userPath = '/employer';
            }
        }

        var elements = document.querySelectorAll('.has-childmenu > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        new ClearSession();
        return (
          <header className={`header ${headerStyle}`}>
            <div id="mainNav" className="liviam-main-menu-area header--fixed">
              <div className="container container0p">
                <div className="row m-0 align-items-center">
                  <div className="col-lg-2 p-0 d-flex justify-content-between align-items-center">
                    <div className="logo">
                      <Link
                        style={{ paddingBottom: "0px" }}
                        className="navbar-brand navbar-brand1"
                        to="/#"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                          alt="logo"
                        />
                      </Link>
                      <Link className="navbar-brand navbar-brand2" to="/">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/footer-logo.png`}
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <div
                      className="menu-bar d-lg-none"
                      onClick={this.menuTrigger}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div className="op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end">
                    <div className="m-menu-header d-flex justify-content-between align-items-center d-lg-none">
                      <Link to="/" className="logo">
                        <img src="assets/images/logo.png" alt="logo" />
                      </Link>
                      <span
                        className="close-button"
                        onClick={this.closeMenuTrigger}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                    </div>
                    <Scrollspy
                      className="custom-border-top nav-menu d-lg-flex flex-wrap list-unstyled mr_lg--50  mb-0 justify-content-center"
                      items={[
                        "Home",
                        "jobs",
                        "companies",
                        "Post a Job",
                        "Create Resume",
                      ]}
                      currentClassName="is-current"
                      offset={-200}
                    >
                      <li>
                        <a href="/">
                          <span>Home</span>
                        </a>
                      </li>
                      <li>
                        <a href="/jobs">
                          <span>Jobs</span>
                        </a>
                      </li>
                      <li>
                        <a href="/companies">
                          <span>Companies</span>
                        </a>
                      </li>
                      <li>
                        <a href="/post-job">
                          <span>Post a Job</span>
                        </a>
                      </li>
                      <li>
                        <a href="/resume">
                          <span>Create Resume</span>
                        </a>
                      </li>
                    </Scrollspy>
                    {isAuthenticated ? (
                      <ul className="custom-border-top nav-menu d-lg-flex flex-wrap list-unstyled  mb-0 justify-content-center sign-in-out">
                        <li>
                          <a href="/notifications">                          
                         
                            <span className="profile">
                              <FaRegBell /> 
                              {/* {unseenMessages !==null && unseenMessages > 0 && <span className='position-absolute top-0 start-100 translate-middle badge badge rounded-pill bg-danger p-1'>{unseenMessages}</span>} */}
                             
                            </span>
                            {unseenMessages !==null && unseenMessages > 0 && <span className='counter'>{unseenMessages}</span>} 

                          </a>
                        </li>
                        <li>
                          <a href={userPath}>
                            <span className="profile">
                              <FaUserAlt />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <span
                              title="Sign Out"
                              className="profile"
                              onClick={this.onSignOut}
                            >
                              <FaSignOutAlt />
                            </span>
                            {/* <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={this.onSignOut}
                            >
                              Sign Out
                            </button> */}
                          </a>
                        </li>
                      </ul>
                    ) : (
                      <ul className="custom-border-top nav-menu d-lg-flex flex-wrap list-unstyled  mb-0 justify-content-center">
                        <li>
                          <a href="/signup" className="pr_lg--0 pr_xl--0">
                            <span
                              style={{
                                fontWeight: "400",
                                padding: "0.375rem 0.75rem",
                                border: "1px solid transparent",
                                display: "inline-block",
                                color: "#6f64e7",
                              }}
                            >
                              Sign Up
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/signin">
                            <span className="btn btn-outline-primary">
                              Sign In
                            </span>
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
        );
    }
}
export default Header

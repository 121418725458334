import React, { Component } from "react";
import { Helmet } from "react-helmet";
import TokenHandler from "../../auth/TokenHandler";
import SectionHeader from "../../common/section-header";
import SeekerDenier from "../jobSeeker/SeekerDenier";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { DateHandler } from "../../utils/DateHandler";
import { ResumeInfoService } from "../../service/ResumeInfoService";
import { RESUME_STATUS } from "../../utils/StatusConstants";
import { JobApplicationService } from "../../service/JobApplicationService";
import { JobInfoService } from "../../service/JobInfoService";
import { Toast } from "primereact/toast";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";

const title = <h3 className="m-0">Apply for the Job</h3>;
const subtitle = (
  <h6 className="sub-title" style={{ lineHeight: "normal" }}>
    Fillup some information to submit your application
  </h6>
);
const alignment = "section-header text-center pb--20";

class JobApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metaData: null,
      jobseeker: false,
      job: null,
      resumeList: [],
      selectedResume: null,
      jobApplication: {
        expectedSalary: "",
        applicantMessage: "",
      },
      errors: {},
    };
    this.tokenHandler = new TokenHandler();
    this.resumeInfoService = new ResumeInfoService();
    this.jobApplicationService = new JobApplicationService();
    this.jobInfoService = new JobInfoService();
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    if (!params.jobId || !params.jobCode || !params.companyId) {
      return;
    }
    let selectedJob = {
      jobId: params.jobId,
      jobCode: params.jobCode,
      companyId: params.companyId,
    };
    fetch(`${process.env.PUBLIC_URL}/metaData.json`).then((res) => {
      res.json().then((data) => {
        this.setState({ metaData: data.jobApply });
      });
    });
    const roles = this.tokenHandler.getUserRoles();
    if (roles.length > 0 && roles[0] === "ROLE_JOBSEEKER") {
      this.onLoadJobInfo(selectedJob);
      this.onLoadResumes();
    }
  }

  onLoadJobInfo = (filter) => {
    let { jobseeker, job } = this.state;
    this.jobInfoService.findJobDetailsForJobSeeker(filter).then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          job = body;
          jobseeker = true;
          this.setState({ jobseeker, job });
        });
      }
      //  else {
      //   job = null;
      //     jobseeker = true;
      //     this.setState({jobseeker, job});
      // }
    });
  };
  onLoadResumes = () => {
    let { resumeList } = this.state;
    this.resumeInfoService.loggedJobSeekerResumes().then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          resumeList = body;
          this.setState({ resumeList });
        });
      }
    });
  };

  onChangeJobApplication = (e) => {
    let { jobApplication, errors } = this.state;
    jobApplication[e.target.name] = e.target.value;
    errors[e.target.name] = "";
    this.setState({ jobApplication, errors });
  };

  onSubmitJobApplication = () => {
    let { jobApplication, job, selectedResume, showProgress, errors } = this.state;
    if(job.alreadyApplied) {
      this.toast.show({
        severity: "warn",
        summary: 'Attention',
        detail: 'You have already applied for this Job',
        life: 3000,
      });
      return;
    }
    if (!this.validateJobApplication()) {
      return;
    }
    if (
      selectedResume === "" ||
      selectedResume === null ||
      selectedResume === undefined
    ) {
      errors["resume"] = "Select your resume to complete application";
      this.setState({ errors });
      return;
    }

    if (job === null || job.jobId === "" || job.jobId === undefined) {
      errors["job"] = "Seleect your desire job";
      this.setState({ errors });
      return;
    }
    let resumeDto = {
      resumeId: selectedResume,
    };
    jobApplication["resumeInfoDto"] = resumeDto;

    let jobDto = {
      jobId: job.jobId,
    };
    jobApplication["jobInfoDto"] = jobDto;

    let companyDto = {
      companyId: job.company.companyId,
    };
    jobApplication["companyInfoDto"] = companyDto;

    this.setState({showProgress:true});
    this.jobApplicationService
      .submitJobApplication(jobApplication)
      .then((res) => {
        if (res.status === 201) {
          return res.json().then((body) => {
            jobApplication = {
              expectedSalary: "",
              applicantMessage: "",
            };
            selectedResume = null;
            this.toast.show({
              severity: "success",
              summary: body.message,
              detail: body.details,
              life: 3000,
            });
            showProgress = false;
            this.setState({ jobApplication, selectedResume,showProgress });
          });
        } else {
          return res.json().then((body) => {
            this.toast.show({
              severity: "error",
              summary: body.message != null ? body.message : "Failed",
              detail: body.details,
              life: 3000,
            });
            showProgress = false;
            this.setState({ showProgress });
          });
        }
      });
  };

  validateJobApplication = () => {
    let { jobApplication, errors } = this.state;
    let val = true;
    if (
      jobApplication.expectedSalary === "" ||
      jobApplication.expectedSalary === undefined
    ) {
      errors["expectedSalary"] = "Enter your expected salary";
      val = false;
    }
    this.setState({ errors });
    return val;
  };

  onSelectResume = (e) => {
    let { selectedResume, errors } = this.state;
    selectedResume = e.value;
    errors["resume"] = "";
    this.setState({ selectedResume, errors });
  };
  render() {
    const {
      metaData,
      jobseeker,
      job,
      resumeList,
      jobApplication,
      selectedResume,
      errors,
      showProgress
    } = this.state;

    let resumeOpt = [];
    resumeList.map((v, i) => {
      if (v.resumeStatus === RESUME_STATUS.ACTIVE) {
        resumeOpt.push({ label: v.desireJobTitle, value: v.resumeId });
      }
    });

    let pageTitle = `${metaData && metaData.title} ${job && job.jobTitle}`;
    return (
      <section
        id="job-apply"
        className="inner-page-section pt--100 pt_lg--130 pb--70 pb_lg-120"
      >
        <Toast ref={(el) => (this.toast = el)} />
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={metaData && metaData.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        {jobseeker ? (
          <div className="container text-left">
            <div className="row">
              <div className="col-12">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  alignment={alignment}
                />
              </div>
              <div className="col-12">
                <div className="row">
                  <main className="col-lg-8 order-lg-first main-content mb--60 mb-lg-0">
                    <div className="entry-wrapper">
                      <div className="entry-single">
                        <div className="entry-content">
                          <h2 className="title mb-3">{job.jobTitle}</h2>
                          <h5>
                            {job.company.companyName}, {job.jobLocation}
                          </h5>
                          <ul className="list-unstyled">
                            <li>
                              <span>Employment Status: </span>
                              <span>{job.jobType}</span>
                            </li>
                            <li>
                              <span>Salary: </span>
                              <span>$</span><span>{job.jobOfferingAmount.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="response-comment-section pb--30 pt--20">
                        <div className="col-lg-12 mb--10 mb-lg-10 p-0">
                          <div className="form-group">
                            <p>
                              <strong>Expected Salary *</strong>
                              {/* <strong>Expected Salary * (in $)</strong> */}
                            </p>
                            <input
                              id="expectedSalary"
                              name="expectedSalary"
                              type="text"
                              className="form-control"
                              value={jobApplication.expectedSalary}
                              placeholder={'$'+job.jobOfferingAmount.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={this.onChangeJobApplication}
                            />
                          </div>
                          <span className="error-msg">
                            {errors["expectedSalary"]}
                          </span>
                        </div>

                        <div className="col-lg-12 mb--10 mb-lg-10 p-0">
                          <div className="form-group">
                            <p>
                              <strong>Resume *</strong>
                            </p>
                            <Dropdown
                              value={selectedResume}
                              options={resumeOpt}
                              onChange={this.onSelectResume}
                              placeholder="Select a Resume"
                            />
                          </div>
                          <span className="error-msg">{errors["resume"]}</span>
                        </div>

                        <div className="col-lg-12 mb--10 mb-lg-10 p-0">
                          <div className="form-group">
                            <p>
                              <strong>Message </strong>
                            </p>
                            <InputTextarea
                              rows={5}
                              cols={30}
                              name="applicantMessage"
                              value={jobApplication.applicantMessage}
                              onChange={this.onChangeJobApplication}
                            />
                          </div>
                          <span className="error-msg">
                            {/* {errors["jobTitle"]} */}
                          </span>
                        </div>

                        <div className="col-lg-12 mb--10 mb-lg-10 p-0 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary w-25"
                            onClick={this.onSubmitJobApplication}
                            disabled={job.alreadyApplied}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </main>
                  <aside className="col-lg-4 sidebar order-lg-last">
                    { job.alreadyApplied &&
                      <div className="card mb--10">
                        <div className="card-body bg-danger text-center">
                        <b className="text-white">You have already applied for this Job</b>
                        </div>
                      </div>
                    }
                    <div className="card">
                      <div className="card-header">Job Summary</div>
                      <div className="card-body">
                        <span>
                          <strong>Published: </strong>
                          {DateHandler.getDateFromStringAnyFormat(
                            job.jobPublishDate,
                            "DD MMM, YYYY"
                          )}
                        </span>
                        <br />
                        <span>
                          <strong>Deadline: </strong>
                          {DateHandler.getDateFromStringAnyFormat(
                            job.jobExpireDate,
                            "DD MMM, YYYY"
                          )}
                        </span>
                        <br />
                        <span>
                          <strong>Vacancy: </strong>
                          {job.numberOfPosition}
                        </span>
                        <br />
                        <span>
                          <strong>Salary: $</strong>
                          {job.jobOfferingAmount.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                        <br />
                        <span>
                          <strong>Status: </strong>
                          {job.jobType}
                        </span>
                      </div>
                    </div>

                    <div className="card mt--10">
                      <div className="card-header">Your Resumes</div>
                      <div className="card-body p-2">
                        {resumeList.map((val, i) => (
                          <div
                            className="mb--10 p-2"
                            key={i}
                            style={{ boxShadow: "0 0 5px 0 rgba(0,0,0,.1)" }}
                          >
                            <h4>{val.desireJobTitle}</h4>
                            <span
                              style={{
                                display: "grid",
                                gridAutoFlow: "column",
                              }}
                            >
                              <span>{val.jobType}</span>
                              <span>
                                {val.resumeStatus === RESUME_STATUS.ACTIVE
                                  ? "Active"
                                  : "Inactive"}
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SeekerDenier />
        )}
      </section>
    );
  }
}
export default JobApply;

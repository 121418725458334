
export class DateHandler {
    static getDateFromStringDDMMYYYY(date) {
        var moment = require('moment/moment');
        var dateFromString = moment(date).format('DD/MM/YYYY');
        return dateFromString;
    }
    static getDateFromStringAnyFormat(date, format) {
        var moment = require('moment/moment');
        var dateFromString = moment(date).format(format);
        return dateFromString;
    }

    static countDays(fromDate, toDate) {
        var moment = require('moment/moment');
        var start = moment(fromDate);
        var end = moment(toDate);
        return end.diff(start, "days");
    }

    static compareDate(date1, date2) {
        var moment = require('moment/moment');
        return moment(date1).isAfter(date2);
    }
}
import React, { Component } from "react";
import { JobApplicationService } from "../../service/JobApplicationService";
import { DateHandler } from "../../utils/DateHandler";
import { APPLICANTION_STATUS } from "../../utils/StatusConstants";

class AppliedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedJobList: [],
    };
    this.jobApplicationService = new JobApplicationService();
  }

  componentDidMount() {
    this.onLoadAppliedJobs();
  }

  onLoadAppliedJobs = () => {
    let { appliedJobList } = this.state;
    this.jobApplicationService.getApplicantAppliedJobs().then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          for (let a = 0; a < body.length; a++) {
            if (body[a].applicationStatus === APPLICANTION_STATUS.PENDING) {
              body[a].applicationStatus = "Pending";
            } else if (body[a].applicationStatus === APPLICANTION_STATUS.APPLIED) {
              body[a].applicationStatus = "Applied";
            }
          }
          appliedJobList = body;
          this.setState({ appliedJobList });
        });
      }
    });
  };
  render() {
    const { appliedJobList } = this.state;
    return (
      <div className="row">
        {appliedJobList.length > 0 ? (
          <div className="col-12">
            <div className="tab-header card p-1 mb--5">
              <span className="tab-header-text">
                Applied [{appliedJobList.length}] Jobs
              </span>
            </div>


            {appliedJobList.map((val, i) => (
              <div className="col-md-12 col-lg-12 p-0" key={i}>
                <div
                  className="liviam-pricing-item mb--10 mb_lg--0"
                  style={{ padding: "5px 10px 5px 10px" }}
                >
                  <div className="pricing-head">
                    <div className="category-type">
                      <span>{val.jobInfo.jobTitle}</span>
                    </div>
                    <p className="free-trial-content">
                      {val.companyInfo.companyName}
                    </p>
                  </div>
                  <div className="pricing-body" style={{ padding: "6px 18px" }}>
                    <p className="hidden-charge row">
                      <div className="col-sm-6">
                        <span className="mr--10" style={{color:'#273167'}}>{val.applicationStatus}</span>
                        <span className="mr--10" style={{color:'#882222'}}>
                          ${val.jobInfo.jobOfferingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                        <span>
                          {DateHandler.getDateFromStringAnyFormat(
                            val.jobInfo.jobExpireDate,
                            "DD MMM, YYYY"
                          )}
                        </span>
                      </div>
                      <div className="col-sm-6 right-grid">
                        <span className="mr--10">
                          Expected: ${val.expectedSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                        <span>
                          Applied:{" "}
                          {DateHandler.getDateFromStringAnyFormat(
                            val.applyDate,
                            "DD MMM, YYYY"
                          )}
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
            <div className="col-12">
          <div className="alert alert-warning">
            <h5 className="alert-heading">Empty!</h5>
            You have no applied jobs so far. Apply now
          </div>
          </div>
        )}
      </div>
    );
  }
}
export default AppliedJobs;

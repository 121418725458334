import React, { Component } from "react";
import SectionHeader from "../common/section-header";
import { Helmet } from "react-helmet";

const title = <h2 className="mb--15">About Us</h2>;
const alignment = "section-header text-lg-center pb--40";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
    };
  }
  componentDidMount() {
    fetch("metaData.json").then((res) =>
      res.json().then((data) => {
        this.setState({ metadata: data.aboutUs });
      })
    );
  }

  render() {
    const { metadata } = this.state;
    return (
      <section id="aboutUs" className="profile-section bg-ash-color pb--60">
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <div className="container position-relative pt--120 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <SectionHeader title={title} alignment={alignment} />

              <p>
                With Liviam, Inc. you will have total access to an extensive
                network to 1000's of current jobs from every industry near you.
              </p>
              <p>
                Creating an account enables searching for jobs, posting your
                resume, posting jobs, and search resumes.
              </p>
              <p>
                Add desired keywords, interests, and skills, and Liviam, Inc.
                will use this information to suggest the best matched and most
                revelant jobs for you.
              </p>
              <p>
                Get noticed by employers seeking the best candidates! Upload or
                build your resume. You will be featured on our home page to
                increase your possibilities of landing your dream job!
              </p>
              <p>
                Sign up to receive email alerts. Liviam, Inc. will notify you by
                email with jobs or resumes that match your criteria of your
                dream job, or perfect candidate.
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </section>
    );
  }
}
export default AboutUs;

import React, { Component } from "react";
import SectionHeader from "../../common/section-header";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { UserService } from "../../service/UserService";
import TokenHandler from '../../auth/TokenHandler';

const title = <h4 className="mt--10 mb--15">Change Password</h4>;
const alignment = "section-header text-lg-left";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showProgress:false,
          currentPass:'',
          newPass:'',
          verifyCode:'',
          stepCurrentPassword:true,
          stepNewPassword:false,
          stepSuccess:false,
          info:{},
          errors:{}
        };

        this.userService = new UserService();
        this.tokenHandler=new TokenHandler();
    }


    onChangeCurrentPass =(e)=> {
        let { currentPass, errors } = this.state;
        currentPass = e.target.value;
        errors['curntPass'] = '';

        this.setState({currentPass, errors});
    }

    checkAndProcessBtn =()=> {
        let { currentPass, stepCurrentPassword, stepNewPassword, info, showProgress, errors } = this.state;
        if (currentPass === '' || currentPass === undefined) {
            errors['curntPass'] = 'Enter current password here';

            this.setState({currentPass, errors});
            return;
        }

        showProgress = true;
        this.setState({showProgress});

        
        info["currentPass"] = currentPass;

        this.userService.checkCurrentPass(info)
        .then(res => {
            if (res.status === 200) {
                return res.json().then(body => {
                    console.log('ok',body);
                    showProgress = false;
                    if (body?.CodeSent) {
                        stepCurrentPassword = false;
                        stepNewPassword = true;
                        info = body;
                        this.setState({stepCurrentPassword, stepNewPassword, info});
                    }
                    else {
                        this.props.toast(4, "Problem on this process");
                    }

                    this.setState({showProgress});
                })
            }
            else {
                return res.json().then(err => {
                    this.props.toast(4, err.message);
                    showProgress = false;
                    this.setState({ showProgress });
                })
            }
        });

        
    }

    onChangeVerifyCode =(e)=> {
        let { verifyCode, errors } = this.state;
        verifyCode = e.target.value;
        errors['verifyCode'] = '';
        this.setState({verifyCode, errors});
    }

    onChangeNewPass =(e)=> {
        let { newPass, errors} = this.state;
        newPass = e.target.value;
        errors['newPass'] = '';
        this.setState({newPass, errors});
    }

    verifyAndChangePassBtn =()=> {
        let { info, stepNewPassword, stepSuccess, showProgress, verifyCode, newPass, errors } = this.state;
        if (verifyCode === '' || verifyCode === undefined) {
            errors['verifyCode'] = 'Enter valid code here';
            this.setState({errors});
            return;
        }
        if (newPass === '' || newPass === undefined) {
            errors['newPass'] = 'Enter new password here';
            this.setState({errors});
            return;
        }

        showProgress = true;
        this.setState({showProgress});

        info['verifyCode'] = verifyCode;
        info['newPass'] = newPass;

        this.userService.checkCodeAndChangePass(info)
        .then(res => {
            if (res.status === 200) {
                return res.json().then(body => {
                    showProgress = false;
                    if (body?.changedPass) {
                        stepNewPassword =false;
                        stepSuccess = true;

                        this.setState({stepNewPassword, stepSuccess});

                        setTimeout(() => {
                            this.onSuccessChangePasswordClearSession();
                        }, 5000);
                    } else {
                        this.props.toast(4, "Problem on this process");
                    }
                    this.setState({showProgress});
                });

            }
            else {
                return res.json().then(err => {
                    this.props.toast(4, err.message);
                    showProgress = false;
                    this.setState({ showProgress });
                });
            }
        });

    }

    onSuccessChangePasswordClearSession =()=> {
        this.tokenHandler.clearSession();
        window.location.reload();
    }

    render() {
        const { 
            showProgress,
            stepCurrentPassword,
            stepNewPassword,
            stepSuccess,
            errors
         } = this.state;
        return (
            <div className="container ">
                <ProgressSpinnerLoader showBar={showProgress} />
                <div className="row">
                    <div className="col-lg-2 col-2 col-xl-2"></div>
                    <div className="col-lg-8 col-8 col-xl-8">
                        <SectionHeader title={title} alignment={alignment} />

                        {stepCurrentPassword &&
                        <div>
                            <div className="form-group">
                                <label>Current Password</label>
                                <input id="curntPass" type="password" className="form-control" onChange={this.onChangeCurrentPass}/>
                                <div className="error-msg">
                                    <span>{errors["curntPass"]}</span>
                                </div>
                            </div>
                            <div className="w-100 text-center mt--20 mb--10 mb-lg-10">
                                <button className="btn btn-primary w-25" type="button"
                                    onClick={this.checkAndProcessBtn}>Check & Process</button>
                            </div>
                        </div>
                         }

                         {stepNewPassword &&
                         <div>
                            <div>
                                <h6>
                                <span>A verification code is sent to your email</span><br/>
                                <span>Please check mail Inbox and enter code and new password</span>
                                </h6>
                            </div>
                            <div className="form-group mt--10">
                                <label>Verify Code</label>
                                <input id="verifyCode" type="text" className="form-control" onChange={this.onChangeVerifyCode}/>
                                <div className="error-msg">
                                    <span>{errors["verifyCode"]}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>New Password</label>
                                <input id="newPass" type="password" className="form-control" onChange={this.onChangeNewPass}/>
                                <div className="error-msg">
                                    <span>{errors["newPass"]}</span>
                                </div>
                            </div>

                            <div className="w-100 text-center mt--20 mb--10 mb-lg-10">
                                <button className="btn btn-primary w-50" type="button"
                                    onClick={this.verifyAndChangePassBtn}>Verify & Change Password</button>
                            </div>
                         </div>
                         }

                         { stepSuccess &&
                         <div className="text-center">
                            <h3>Congratulations !!!</h3>
                            <b>Password is successfully changed</b><br/>
                            <span className="mt--10">You will be Sign out immediately</span><br/>
                            <span>Try to Sign In again with new password</span>
                         </div>
                         }
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
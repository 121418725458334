import { Paginator } from "primereact/paginator";
import React, { Component } from "react";
import { JobInfoService } from "../../service/JobInfoService";
import { JobFilterUtils } from "../../utils/JobFilterUtils";


const typeState = "State";
class StateWise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateWiseCount: [],
      paginator: {
        page: 0,
        limit: 100,
        totalPage: 0,
        totalFound: 0,
      },
    };

    this.jobInfoService = new JobInfoService();
  }
  componentDidMount() {
    this.loadStateWiseJobsCount(0);
  }

  loadStateWiseJobsCount = (page) => {
    let { stateWiseCount, paginator } = this.state;
    let paginatorObj = {
      page: page,
      limit: paginator.limit,
      filter: {
        locationType: typeState,
      },
    };
    this.jobInfoService.countJobsByLocationPublic(paginatorObj).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          stateWiseCount = body.list;
          paginator.totalPage = body.pages;
          paginator.totalFound = body.count;
          this.setState({ stateWiseCount, paginator });
        });
      }
    });
  };
  onChangePaginator = (e) => {
    let { paginator } = this.state;
    paginator.page = e.page * paginator.limit;
    this.setState({ paginator });
    this.loadStateWiseJobsCount(e.page);
  };

  onClickFindJobs = (location) => {   
    
    let searchObj = {
      jobTitle: null,
      address:location,
      selected:true
    }
    JobFilterUtils.updateLocatStore(searchObj);
  
    window.location.replace("/jobs");
  };
  render() {
    const { stateWiseCount, paginator } = this.state;
    return (
        <div>
      <div className="city-state-data">
        {stateWiseCount.map((ob, i) => (
          <a href="#" onClick={() => this.onClickFindJobs(ob.name)} key={i}>
            <p key={i}>
              {ob.name} (<span>{ob.totalJob}</span>)
            </p>
          </a>
        ))}
      </div>
      <Paginator
          rows={paginator.limit}
          totalRecords={paginator.totalFound}
          totalPages={paginator.totalPage}
          first={paginator.page}
          last={paginator.limit}
          onPageChange={this.onChangePaginator}
          style={{ background: "initial", border: "none" }}
        ></Paginator>
      </div>
    );
  }
}

export default StateWise;

import { PrivateService } from "../auth/PrivateService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class ProductInfoService {
    constructor(){
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    getProductList() {
        let uri = this.baseUriHolder.getEmployer() + '/products';
        return this.privateService.getFetch(uri);
    }
}
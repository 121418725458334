import React, { Component } from "react";
import SectionHeader from "../common/section-header";
import { Helmet } from "react-helmet";

const title = <h2 className="mb--15">Terms & Conditions</h2>;
const alignment = "section-header text-lg-center pb--40";

class TermsOfUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
    };
  }
  componentDidMount() {
    fetch("metaData.json").then((res) =>
      res.json().then((data) => {
        this.setState({ metadata: data.termsOfUse });
      })
    );
  }

  render() {
    const { metadata } = this.state;
    return (
      <section id="termsOfUse" className="profile-section bg-ash-color pb--60">
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <div className="container position-relative pt--120 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <SectionHeader title={title} alignment={alignment} />

              <h5>Introduction</h5>
              <p>
                Please read these Terms & Conditions carefully before using
                https://Liviaminc.com.
                <br />
                https://Liviaminc.com reserves the right to modify these Terms &
                Conditions at any time.
              </p>

              <h5 className="mt--15">Services Provided</h5>
              <p>
                https://Liviaminc.com provides a service to bring Job Seekers
                and Employers together. Job Seekers and Employers can register,
                create profiles/job posts and search for jobs and resumes.
              </p>

              <h5 className="mt--15">Website Use</h5>
              <p className="mb--5">
                https://Liviaminc.com may not be used for any of the following
                purposes:
              </p>
              <ol style={{ color: "#677294" }}>
                <li>
                  To contact https://Liviaminc.com users regarding any issue
                  apart from the purpose of recruitment.
                </li>
                <li>
                  To contact https://Liviaminc.com users to offer any services
                  from a 3rd party company.
                </li>
                <li>To post any illegal content.</li>
              </ol>
              <p>
                The user is required to provide truthful information in their
                profile or job post.
                <br />
                It is prohibited to use any text or images from
                https://Liviaminc.com for personal or commercial use.
              </p>

              <h5 className="mt--15">User Information</h5>
              <p>
                https://Liviaminc.com does not hold responsibility for any
                untruthful and/or inaccurate information included in job posts
                and profiles.
              </p>
              <p>
                https://Liviaminc.com reserves the right to edit or delete any
                information submitted by the user to the website.
              </p>

              <h5 className="mt--15">Liability</h5>
              <p>
                https://Liviaminc.com will not be responsible for any loss or
                damage the user may encounter from using the website.
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </section>
    );
  }
}
export default TermsOfUse;

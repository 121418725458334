import React, { Component } from "react";
import { Helmet } from "react-helmet";
import SectionHeader from "../../common/section-header";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import TokenHandler from "../../auth/TokenHandler";
import SeekerDenier from "../jobSeeker/SeekerDenier";
import { FaBell } from "react-icons/fa";
import { JobAlertService } from "../../service/JobAlertService";
import { Toast } from "primereact/toast";
import { ToastMessage } from "../../utils/ToastMessage";

const title = <h4 className="mb--15">Job Alert</h4>;
const alignment = "section-header text-lg-center";

class JobAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
      showProgress: false,
      jobseeker: false,
      alertObj: {
        jobTitle: "",
        jobLocation: "",
        jobAlertEmail: "",
      },
      errors: {},
    };
    this.tokenHandler = new TokenHandler();
    this.jobAlertService = new JobAlertService();
  }

  componentDidMount() {
    let { alertObj, jobseeker } = this.state;
    fetch("metaData.json").then((res) =>
      res.json().then((data) => {
        this.setState({ metadata: data.jobAlert });
      })
    );
    
    const roles = this.tokenHandler.getUserRoles();
    if (roles.length > 0 && roles[0] === "ROLE_JOBSEEKER") {
      jobseeker = true;
      const username = this.tokenHandler.getUsername();
      let jobFilter = localStorage.getItem("jobFilter");
      if (jobFilter) {
        jobFilter = JSON.parse(jobFilter);
        console.log("Data : "+jobFilter);
        if (jobFilter !== null) {
          alertObj.jobAlertEmail = username;
          alertObj.jobTitle = jobFilter.jobTitle;
          // alertObj.jobTitle = jobFilter[0];
          alertObj.jobLocation = jobFilter.jobLocation;
          // alertObj.jobLocation = jobFilter[1];
          this.setState({ alertObj, jobseeker });
        }
      }
    }
  }

  onChangeJobAlert = (e) => {
    let { alertObj, errors } = this.state;
    alertObj[e.target.name] = e.target.value;
    errors[e.target.name] = "";
    this.setState({ alertObj, errors });
  };

  saveJobAlert = () => {
    let { alertObj, showProgress, errors } = this.state;

    if (alertObj.jobTitle === null || alertObj.jobTitle === undefined || alertObj.jobTitle === '') {
      errors["jobTitle"] = "Enter a job title";
      this.setState({ errors });
      return;
    }
    this.setState({ showProgress: true });
    this.jobAlertService.saveSubscription(alertObj).then((res) => {
      if (res.status === 201) {
        return res.json().then((body) => {
          this.toast.show(
            ToastMessage.getMessage(
              1,
              "Your Subscription has successfully submitted"
            )
          );
          showProgress = false;
          this.setState({ showProgress });
        });
      } else {
        return res.json().then((err) => {
          this.toast.show(ToastMessage.getMessage(4, err.message));
          showProgress = false;
          this.setState({ showProgress });
        });
      }
    });
  };
  render() {
    const { alertObj, jobseeker, metadata, showProgress, errors } = this.state;
    return (
      <section id="notify" className="profile-section bg-ash-color pb--60">
        <Toast ref={(el) => (this.toast = el)} />
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        {jobseeker ? (
          <div className="container position-relative pt--120 pb--30 pt_lg--120 pb_lg--50">
            <div className="row">
              <div className="col-lg-1 col-xl-2"></div>
              <div className="col-lg-10 col-12 col-xl-8">
                <SectionHeader title={title} alignment={alignment} />

                <div className="col-lg-12 mb--10 mb-lg-10">
                  <div className="form-group">
                    <label>Job Title *</label>
                    <input
                      id="jobTitle"
                      name="jobTitle"
                      type="text"
                      className="form-control"
                      value={alertObj.jobTitle}
                      placeholder="Enter a title"
                      onChange={this.onChangeJobAlert}
                    />
                  </div>
                  <span className="error-msg">{errors["jobTitle"]}</span>
                </div>

                <div className="col-lg-12 mb--10 mb-lg-10">
                  <div className="form-group">
                    <label>Job Location *</label>
                    <input
                      id="jobLocation"
                      name="jobLocation"
                      type="text"
                      className="form-control"
                      value={alertObj.jobLocation}
                      placeholder="Enter a location"
                      onChange={this.onChangeJobAlert}
                    />
                  </div>
                </div>

                <div className="col-lg-12 mb--10 mb-lg-10">
                  <div className="form-group">
                    <label>Email </label>
                    <input
                      id="alertEmail"
                      name="jobAlertEmail"
                      type="text"
                      className="form-control"
                      value={alertObj.jobAlertEmail}
                      placeholder="Enter a email"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb--10 mb-lg-10 text-right">
                  <button
                    type="submit"
                    className="btn btn-outline-primary"
                    onClick={this.saveJobAlert}
                  >
                    {" "}
                    <FaBell /> Alert Me
                  </button>
                </div>
              </div>
              <div className="col-lg-1 col-xl-2"></div>
            </div>
          </div>
        ) : (
          <SeekerDenier />
        )}
      </section>
    );
  }
}
export default JobAlert;

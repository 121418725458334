import React, { Component } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { DateHandler } from "../../utils/DateHandler";
import SectionHeader from "../../common/section-header";
import { FaCircle, FaRegCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { MessageSenderService } from "../../service/MessageSenderService";



const title = <h4 className="mb--15">Received Messages</h4>;
const alignment = "section-header text-lg-left";

class ReceivedMessages extends Component {
    constructor(props) {
      super(props);
      this.state = {
        notifications: [],
        paginator: {
            page: 0,
            limit: 20,
            totalPage: 0,
            totalFound: 0,
          },
      };
      this.messageSenderService = new MessageSenderService();
    }
    componentDidMount(){
        this.loadNotifications();
      }
    loadNotifications = (page) => {
    let { notifications, paginator } = this.state;
    let paginatorObj = {
      page: page,
      limit: paginator.limit
    };
    this.messageSenderService.findUserMessages(paginatorObj).then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          notifications = body.list;
          paginator.totalPage = body.pages;
          paginator.totalFound = body.count;
          this.setState({ notifications, paginator });
        });
      }
    });
  }
    appliedDateTemp = (rowData) => {
        return (
          <span>
            {DateHandler.getDateFromStringAnyFormat(rowData.date, "DD MMM, YYYY")}
          </span>
        );
      };
      statusTemp = (rowData) => {

        let shortTitle =rowData.title;  
        if(shortTitle.length > 20){
          shortTitle = shortTitle.substring(0,20).concat('...');
        }      

        if (rowData.status === 0) {
          return  <><span>{shortTitle}</span><span style={{ marginLeft : '3px' }} class="badge bg-warning">New</span></>;
          // return <FaCircle style={{ color: "#0782e3" }} />;
        } else if (rowData.status === 1) {
          return  <span>{shortTitle}</span>;
          // return <FaRegCircle style={{ color: "#0782e3" }} />;
        }
      };
      detailsButtonTemp = (rowData) => {
        return (
          <button
            className="btn btn-outline-primary"
            title="Show details"
            onClick={() => window.location.replace(`/notification/details/${rowData.jobId}/${rowData.senderId}`)}
          >
            <FaExternalLinkAlt />
          </button>
        );
      };
    render() {
        const {
          notifications,
          selectedNotification,
          paginator,
          messageDialog,
          metadata,
          showProgress,
        } = this.state;         
          console.log("List data :",notifications);
        return ( 
            <div className="container ">
            <ProgressSpinnerLoader showBar={showProgress} />
              <div className="row">
                <div className="col-lg-1 col-xl-2"></div>
                <div className="col-lg-12 col-12 col-xl-12">
                  <SectionHeader title={title} alignment={alignment} />
    
                  <div className="col-12 pd0">
                    <DataTable value={notifications}>
                      {/* <Column
                        field="status"
                        headerStyle={{ width: "8.5em" }}
                        body={this.statusTemp}
                      ></Column> */}
                      <Column
                        field="date"
                        header="Date"
                        headerStyle={{ width: "6.5em" }}
                        body={this.appliedDateTemp}
                      ></Column>
                      <Column field="sendFor" header="Job Title"></Column>                     
                      <Column field="sendBy" header="Company"></Column>
                       {/* <Column field="title" header="Message Title"></Column> */}
                       <Column
                        field="status"
                        header="Message Subject"
                        // headerStyle={{ width: "8.5em" }}
                        body={this.statusTemp}
                      ></Column>
                      <Column
                        style={{ textAlign: "center" }}
                        body={this.detailsButtonTemp}
                        header="Details"
                        headerStyle={{ width: "5.5em" }}
                      ></Column>
                    </DataTable>
                  </div>
                  <div className="col-12 pd0">
                    <Paginator
                      rows={paginator.limit}
                      totalRecords={paginator.totalFound}
                      totalPages={paginator.totalPage}
                      first={paginator.page}
                      last={paginator.limit}
                      onPageChange={this.onChangePaginator}
                      style={{ background: "initial", border: "none" }}
                    ></Paginator>
                  </div>
                </div>
                <div className="col-lg-1 col-xl-2"></div>
    
                
              </div>
            </div>
        );
      }

}
export default ReceivedMessages;
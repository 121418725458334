import { Toast } from "primereact/toast";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import SectionHeader from "../../common/section-header";
import { ContactUsService } from "../../service/ContactUsService";
import { ToastMessage } from "../../utils/ToastMessage";
import Validation from "../../utils/validationUtil";

const title = <h3>Contact Us</h3>;
const alignment = "section-header text-center pb--60";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactObj: {
        contactorName: "",
        contactorEmail: "",
        contactorMessage: "",
      },
      errors: {},
      metadata:{},
    };
    this.contactUsService = new ContactUsService();
  }

  componentDidMount(){
    fetch('metaData.json').then(res=>res.json().then(data=>{this.setState({metadata:data.contactUs})}));
  }
  onChangeName = (e) => {
    let { contactObj, errors } = this.state;
    errors["contactorName"] = "";
    contactObj.contactorName = e.target.value;
    this.setState({ contactObj, errors });
  };
  onChangeEmail = (e) => {
    let { contactObj, errors } = this.state;
    errors["contactorEmail"] = "";
    contactObj.contactorEmail = e.target.value;
    this.setState({ contactObj, errors });
  };

  onChangeMessage = (e) => {
    let { contactObj, errors } = this.state;
    errors["contactorMessage"] = "";
    contactObj.contactorMessage = e.target.value;
    this.setState({ contactObj, errors });
  };
  formValid() {
    let { errors, contactObj } = this.state;
    let isValid = true;
    if (contactObj.contactorName === "") {
      errors["contactorName"] = "Enter your name.";
      isValid = false;
    }
    if (contactObj.contactorEmail === "") {
      errors["contactorEmail"] = "Enter your Email.";
      isValid = false;
    } else if (!Validation.isCurrectEmail(contactObj.contactorEmail)) {
      errors["contactorEmail"] = "Enter your currect email.";
      isValid = false;
    }
    if (contactObj.contactorMessage === "") {
      errors["contactorMessage"] = "Enter message.";
      isValid = false;
    }
    this.setState({ errors });
    return isValid;
  }

  onSubmitContact = () => {
    let { contactObj, errors } = this.state;
    if (!this.formValid) {
      errors["error"] = "Something wrong";
      this.setState({ errors });
      return;
    }
    
    this.contactUsService.saveContactMessage(contactObj)
    .then(res => {
        if(res.status === 201) {
            return res.json().then(body => {
                this.toast.show(ToastMessage.getMessage(1, 'Your Message has successfully submited'));
                contactObj = {
                    contactorName: '',
                    contactorEmail: '',
                    contactorMessage: ''
                }
                this.setState({contactObj, errors});
            })
        }
        else {
            return res.json().then(err => {
                this.toast.show(ToastMessage.getMessage(4, err.message));
            })
        }
    })
  };

  render() {
    const { contactObj, metadata, errors } = this.state;
    return (
      <section
        id="contact"
        className="contact-section pt--90 pt_lg--120 pb--70 pb_lg--120"
      >
          <Toast ref={(el) => (this.toast = el)}/>
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <div className="container">
          <div className="contact-wrapper">
            <SectionHeader title={title} alignment={alignment} />
            <div className="contact-form">
              <div className="row">
                <div className="col-lg-6 mb--20 mb-lg-10">
                  <input
                    type="text"
                    name="contactorName"
                    id="name"
                    value={contactObj.contactorName}
                    onChange={this.onChangeName}
                    placeholder="Your Name *"
                  />
                  <span className="error-msg">{errors["contactorName"]}</span>
                </div>
                <div className="col-lg-6 mb--20 mb-lg-10">
                  <input
                    type="email"
                    name="contactorEmail"
                    id="email"
                    value={contactObj.contactorEmail}
                    onChange={this.onChangeEmail}
                    placeholder="Your email *"
                  />
                  <span className="error-msg">{errors["contactorEmail"]}</span>
                </div>
                <div className="col-lg-12">
                  <textarea
                    type="text"
                    rows="5"
                    id="message"
                    name="contactorMessage"
                    value={contactObj.contactorMessage}
                    onChange={this.onChangeMessage}
                    placeholder="Your Message"
                  />
                  <span className="error-msg">{errors["contactorMessage"]}</span>
                </div>
                <div className="col-12 text-center pt--50">
                  <button className="submit-btn" onClick={this.onSubmitContact}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ContactUs;

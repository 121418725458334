

export class PublicService {
    constructor() {
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    getFetch(url){
        const headers=this.headers;
        return fetch(url, {method:'GET', headers});
    }

    postFetch(url, data) {
        const headers = this.headers;
        return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(data) });
    }
}
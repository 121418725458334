import React, {Component} from 'react';


function FileInput({value, onSelect, className, accept}){
   
    function onChange(e){
        const files= e.target.files;
        if(files.length>0)
            onSelect(files[0]);
    }

    return(
        <div className={className}>
        <input type="file" 
       accept={accept}
       onChange={onChange}/>
           </div>
    )

}
export default FileInput;
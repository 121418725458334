import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import StarRatings from "react-star-ratings";
import CompanyJobs from "./CompanyJobs";
import { Helmet } from "react-helmet";
import { CompanyInfoService } from "../../service/CompanyInfoService";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { FileHandler } from "../../utils/FileHandler";

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metaData: null,
      companyName: null,
      company: null,
      showProgress: false,
    };

    this.companyInfoService = new CompanyInfoService();
  }

  componentDidMount() {
    let { company, companyName, showProgress } = this.state;
    fetch(`${process.env.PUBLIC_URL}/metaData.json`).then((res) => {
      res.json().then((data) => {
        this.setState({ metaData: data.jobApply });
      });
    });
    const {
      match: { params },
    } = this.props;
    if (!params.companyId || !params.companyName) {
      return;
    }
    companyName = params.companyName;
    let companyId = params.companyId;
    this.setState({ showProgress: true });
    this.companyInfoService.loadCompannyByIdForPublic(companyId).then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          company = body;
          showProgress = false;
          this.setState({ company, showProgress });
        });
      } else {
        return res.json().then((err) => {
          showProgress = false;
          this.setState({ showProgress });
        });
      }
    });
    this.setState({ companyName });
  }
  render() {
    const { metaData, companyName, company, showProgress } = this.state;
    let rating = 0;
    rating = company && company.rating;
    let pageTitle = `${metaData && metaData.title} ${companyName}`;
    
    return (
      <section
        id="companies"
        className="inner-page-section pt--90 pt_lg--130 pb--70 pb_lg-120"
      >
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={metaData && metaData.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container">
          <div className="row">
            <div className="profile-box">
              <div align="left" className="profile-top" />
              <img
                align="left"
                className="profile-image thumbnail"
                src={FileHandler.getLogoSource(company)}
                alt={`${company && company.logoName}`}
              />

              <div className="profile-box-text">
                <h1>{company && company.companyName}</h1>
                {/* <p>{"location"}</p> */}
                <p>{company && company.location}</p>
                <span>
                  <small style={{ color: "#6d63e4" }}>
                    {company && company.rating}
                    <StarRatings
                      numberOfStars={5}
                      rating={rating ? rating : 0}
                      starDimension={"15px"}
                      starRatedColor="#6d63e4"
                      starSpacing={"1px"}
                    />
                  </small>
                  <b className="flw" style={{ margin: "0px" }}>
                    Follow
                  </b>
                </span>
              </div>
            </div>
            <div className="col-12">
              <Tabs>
                <TabList>
                  <Tab>Overview</Tab>
                  <Tab>Review</Tab>
                  <Tab>Salaries</Tab>
                  <Tab>Jobs</Tab>
                </TabList>
                <TabPanel>
                  <div className="pricing-tab-content-body">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: company && company.companyDescription,
                      }}
                    ></span>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="pricing-tab-content-body">Rerview</div>
                </TabPanel>
                <TabPanel>
                  <div className="pricing-tab-content-body">Salaries</div>
                </TabPanel>
                <TabPanel>
                  <div className="pricing-tab-content-body">
                    <CompanyJobs
                      company={company && company.companyId}
                      history={this.props.history}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default CompanyDetails;

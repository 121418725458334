import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import {
  FaAlignJustify,
  FaRegClock,
} from "react-icons/fa";
import { ResumeInfoService } from "../../service/ResumeInfoService";
import { DateHandler } from "../../utils/DateHandler";
import ResumeDetails from "./ResumeDetails";

class ResumeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeList: [],
      selectedResumeInfoId:null,
      resumeDetailsDialog:false,
    };
    this.resumeInfoService = new ResumeInfoService();
  }
  componentDidMount() {
    this.loadResumes();
  }

  loadResumes = () => {
    let { resumeList } = this.state;
    this.resumeInfoService.loggedJobSeekerResumes().then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          resumeList = body;
          this.setState({ resumeList });
        });
      }
    });
  };
  onClickCreateResume = () => {
    // localStorage.removeItem("resume");
    localStorage.setItem("resume", "new");
    // localStorage.setItem("resume", 1);
    this.props.handleVisible();
  };

  render() {
    const { resumeList, selectedResumeInfoId, resumeDetailsDialog} = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="tab-header card pl-1">
            <div className="tab-header-text">
              You have [{resumeList.length}] resume(s)
            </div>
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-outline-primary"
                style={{ padding: "revert" }}
                onClick={this.onClickCreateResume}
              >
                Create Resume
              </button>
            </div>
          </div>
          {resumeList.length > 0 ? (
            <div className="hover-effect-parent4 justify-content-center justify-content-lg-start mt--5">
              {resumeList.map((val, i) => (
                <div
                  className="col-md-12 mb--5 mb-lg-3 p-0"
                  key={i}
                >
                  <div className="job-item text-lg-left">
                    <div className="job-content border-effect pt-1">
                      <h5 className="title" style={{ margin: "0px" }}>
                        {val.desireJobTitle}
                      </h5>
                      <span className="company-location">{val.jobType}</span>
                      <br />
                      <ul className="ul-li-column">
                        {val.jobCategories.map((c, i) => (
                          <li key={i}>{c}</li>
                        ))}
                      </ul>
                      <p>{val.resumeStatus === 1 ? "ACTIVE" : "INACTIVE"}</p>

                      <div className="meta-job list-unstyled pl-0 d-flex justify-content-between">
                        <div>
                          <span className="icon">
                            <FaRegClock />
                          </span>
                          <span className="meta-content">
                            {DateHandler.getDateFromStringAnyFormat(
                              val.createDate,
                              "DD MMMM, yyyy"
                            )}
                          </span>
                        </div>

                        <div style={{cursor:'pointer'}} title="View Resume Details"
                        onClick={() => this.setState({selectedResumeInfoId:val.resumeId, resumeDetailsDialog:true})}>
                          <span className="icon">
                            <FaAlignJustify />
                          </span>
                          <span className="meta-link">
                            Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="col-12 alert alert-warning">
              <h5 className="alert-heading">Empty!</h5>
              You have no resumes so far. Please create new resume
            </div>
          )}
        </div>
        <Dialog
            header="Resume Details"
            visible={resumeDetailsDialog}
            maximizable
            modal
            style={{ width: "50vw", height: "75vh" }}
            footer={
              <div>
                <Button
                  label="Close"
                  icon="pi pi-times"
                  onClick={() => this.setState({ resumeDetailsDialog: false })}
                />
              </div>
            }
            onHide={() => this.setState({ resumeDetailsDialog: false })}
          >
            <ResumeDetails resumeId={selectedResumeInfoId}/>
          </Dialog>
      </div>
    );
  }
}
export default ResumeList;

import { PublicService } from "../auth/PublicService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class HomeService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
    }

    loadHomePageData() {
        let uri = this.baseUriHolder.getPublic();
        return this.publicService.getFetch(uri);
    }
}
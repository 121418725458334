import React, { Component } from "react";
import { FaEnvelope, FaSignInAlt } from "react-icons/fa";
import SectionHeader from "../common/section-header";
import TokenHandler from "../auth/TokenHandler";
import { Helmet } from "react-helmet";
import { ProgressSpinnerLoader } from "../common/ProgressSpinnerLoader";
import Validation from "../utils/validationUtil";
import { UserAccountRecoveryService } from "../service/UserAccountRecoveryService";
import { Link } from "react-router-dom";


const title = <h3 className="mb--15">Recover Password</h3>;
const subtitle = (
  <h6 className="sub-title" style={{ lineHeight: "normal" }}>
    If you have forgot your password, recover now
  </h6>
);
const alignment = "section-header text-lg-center pb--40";


class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state={
      email:'',
      verifyCode:'',
      newPassword:'',
      retypePassword:'',
      metadata:null,
      showProgress:false,
      emailSec:true,
      verifySec:false,
      passSec:false,
      info:{},
      errors: {}
    }
    this.tokenHandler=new TokenHandler();
    this.recoveryService = new UserAccountRecoveryService();
  }
  componentDidMount() {
    fetch('metaData.json').then(res=>res.json().then(data=>{this.setState({metadata:data.forgotPassword})}))
  }
 
  sendVerifyCode=()=>{
    let {email, info, errors, emailSec, verifySec, showProgress} = this.state;
    if(email === '' || email === undefined) {
      errors['email'] = 'Please, enter your email';
      this.setState({errors});
      return;
    }
    if (!Validation.isCurrectEmail(email)) {
      errors['email'] = 'Incurrect email address, input valid email address';
        this.setState({ errors });
      return;
    }
    info['Email'] = email;

    this.setState({showProgress:true});
    this.recoveryService.sendCodeIfExistByEmail(info)
    .then((res) => {
      if(res.status === 201) {
        return res.json().then(body => {
          if(body.CodeSent === true){
            emailSec = false;
            verifySec = true;
            this.setState({emailSec, verifySec});
          }
          info = body;
          showProgress = false;
          this.setState({info, showProgress});
        })
      }
      else {
        return res.json().then(err => {
          errors['error'] = err.message;
          showProgress = false;
          this.setState({emailSec, verifySec, showProgress});
        })
      }
    });
    
  }
  
  onVerifyCode=()=> {
    let {info, verifyCode, verifySec, passSec, errors, showProgress} = this.state;
    if(verifyCode === '' || verifyCode === undefined){
      errors['verifyCode'] = 'Please, enter verification code.';
      this.setState({errors});
      return;
    }

    info['verifyCode'] = verifyCode;

    this.setState({showProgress:true});
    this.recoveryService.checkVerificationCode(info)
    .then((res) => {
      if(res.status === 200) {
        return res.json().then(body => {
          if(body.CheckCode === true) {
            verifySec = false;
            passSec = true;
            this.setState({verifySec, passSec});
          }
          info = body;
          showProgress = false;
          this.setState({info, showProgress});
        })
      }
      else {
        return res.json().then(err => {
          errors['error'] = err.message;
          showProgress = false;
          this.setState({errors, showProgress});
        })
      }
    })
    
  }

  onResetPassword=()=> {
    let {info, newPassword, retypePassword, passSec, errors, showProgress} = this.state;
    if(newPassword === '' || newPassword === undefined) {
      errors['newPass'] = 'Please, enter password';
      this.setState({errors});
      return;
    }
    if(retypePassword === '' || retypePassword === undefined) {
      errors['retypePass'] = 'Please, enter retype password';
      this.setState({errors});
      return;
    }
    if(newPassword !== retypePassword) {
      errors['error'] = `Password doesn't match`;
      this.setState({errors});
      return;
    }

    info['newPassword'] = newPassword;
    info['retypePassword'] = retypePassword;
    this.setState({showProgress:true});
    this.recoveryService.resetPassword(info)
    .then((res) => {
      if(res.status === 202) {
        passSec = false;
        showProgress = false;
        this.setState({passSec, showProgress});
      }
      else {
        return res.json().then(err => {
          errors['error'] = err.message;
          showProgress = false;
          this.setState({errors, showProgress});
        })
      }
    })
    
  }
  render() {
    const {email, verifyCode, metadata, showProgress, emailSec, verifySec, passSec, errors, 
    newPassword, retypePassword} = this.state;

    let resetSuccess = false;
    if(!emailSec && !verifySec && !passSec) {
      resetSuccess = true;
    }
    return (
      <section id="forgotPassword" className="profile-section bg-ash-color pb--60">
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container position-relative pt--100 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="profile-section-content text-center text-lg-left">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  alignment={alignment}
                />

                <div className="profile-info-container-style1 hover-effect-parent1 text-left">
                  <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
                    <div className="content max-width-425">
                      <div className="row">
                        {emailSec && 
                        <div className="col-12 pd0">
                        <div className="col-lg-12 mb--10 mb-lg-10">
                            <span>Email:</span>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaEnvelope />
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter email"
                              value={email}
                              onChange={(e) => this.setState({email:e.target.value, errors:{}})}
                            />
                          </div>
                          <span className="error-msg">{errors["email"]}</span>
                          <span className="error-msg">{errors["error"]}</span>
                        </div>
                       
                        <div className="col-lg-12 mt--15 text-center">
                          
                            <button
                              className="btn btn-primary w-50"
                              type="submit"
                              onClick={this.sendVerifyCode}
                            >
                              <span>
                                <FaSignInAlt />
                              </span>{" "}
                              Submit
                            </button>
                        </div>
                        </div>}

                        {verifySec && 
                        <div className="col-12 pd0">
                          <p className="text-center">A verification code has sent to this mail <b>{email}</b><br/>
                          Enter code for next steps.</p>
                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <span>Verification code:</span>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaEnvelope />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter code"
                              value={verifyCode}
                              onChange={(e) => this.setState({verifyCode:e.target.value, errors:{}})}
                            />
                          </div>
                          <span className="error-msg">{errors["verifyCode"]}</span>
                          <span className="error-msg">{errors["error"]}</span>
                        </div>
                       
                        <div className="col-lg-12 mt--15 text-center">
                          
                            <button
                              className="btn btn-primary w-50"
                              type="submit"
                              onClick={this.onVerifyCode}
                            >
                              <span>
                                <FaSignInAlt />
                              </span>{" "}
                              Verify
                            </button>
                        </div>
                        </div>}

                        {passSec && 
                        <div className="col-12 pd0">
                          <p>Provide a strong Password to secure your Account.</p>
                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <span>New Password:</span>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaEnvelope />
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter new password"
                              value={newPassword}
                              onChange={(e) => this.setState({newPassword: e.target.value, errors:{}})}
                            />
                          </div>
                          <span className="error-msg">{errors["newPass"]}</span>
                        </div>

                        <div className="col-lg-12 mb--10 mb-lg-10">
                            <span>Retype Password:</span>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaEnvelope />
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter retype password"
                              value={retypePassword}
                              onChange={(e) => this.setState({retypePassword:e.target.value, errors:{}})}
                            />
                          </div>
                          <span className="error-msg">{errors["retypePass"]}</span>
                          <span className="error-msg">{errors["error"]}</span>
                        </div>
                       
                        <div className="col-lg-12 mt--15 text-center">
                          
                            <button
                              className="btn btn-primary w-50"
                              type="submit"
                              onClick={this.onResetPassword}
                            >
                              <span>
                                <FaSignInAlt />
                              </span>{" "}
                              Reset Password
                            </button>
                        </div>
                        </div>}

                        {resetSuccess && 
                        <div className="col-12 pd0 text-center">
                          
                           <h2>Your Password has reset successfully</h2>
                           <h4>You can <Link to="/signin">Sign In</Link> now with your new password.</h4>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </section>
    );
  }
}
export default ForgotPassword;

import { BaseUriHolder } from "../utils/BaseUriHolder";
import TokenHandler from "./TokenHandler";


export class PrivateService {
    constructor() {
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        this.baseUriHolder = new BaseUriHolder();
        this.tokenHandler = new TokenHandler();
    }

    signin(email, password) {
        let uri = this.baseUriHolder.getHost() +'/oauth/token?username='+email+'&password='+password+'&grant_type=password';
        const headers = {
            'Authorization': 'Basic bGl2aWFtLWNsaWVudDpsaXZpYW1pbmM='
        }

        return fetch(uri, {method: 'POST', headers});
    }

isAuthorised() {
    if(!this.tokenHandler.isLoggedIn()){
        window.location.replace("/signin");
    }
}

    getFetch(url){
        this.isAuthorised();
        let headers=this.headers;
        headers['Authorization'] = 'bearer '+this.tokenHandler.getToken().access_token;
        return fetch(url, {method:'GET', headers});
    }

    postFetch(url, data) {
        this.isAuthorised();
        const headers = this.headers;
        headers['Authorization'] = 'bearer '+this.tokenHandler.getToken().access_token;
        return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(data) });
    }

    putFetch(url, data) {
        this.isAuthorised();
        const headers = this.headers;
        headers['Authorization'] = 'bearer '+this.tokenHandler.getToken().access_token;
        return fetch(url, { method: 'PUT', headers: headers, body: JSON.stringify(data) });
    }
}
import { PublicService } from "../auth/PublicService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class ContactUsService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
    }

    saveContactMessage(info) {
        let uri = this.baseUriHolder.getPublic()+ '/contactus';
        return this.publicService.postFetch(uri, info);
    }
}
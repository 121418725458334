import React, { Component } from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import {
  FaRegClock,
  FaTextHeight,
  FaRegHeart,
  FaDollarSign,
  FaRegCalendarAlt,
  FaPaperPlane,
  FaBell,
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare, FaTimes
} from "react-icons/fa";
import { JobInfoService } from "../../service/JobInfoService";
import { DateHandler } from "../../utils/DateHandler";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { FileHandler } from "../../utils/FileHandler";

class JobInSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: null,
      jobApplyDialog: false,
      showProgress:false,
      jobSaveBtn:false,
    };
    this.jobInfoService = new JobInfoService();
  }
  componentWillMount() {
    const { selectedJob } = this.props;
    this.onChangeJobDetails(selectedJob);
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedJob } = this.props;
    if (prevState.job !== null && prevState.job.jobId !== selectedJob.jobId) {
      this.onChangeJobDetails(selectedJob);
    }
  }

  onChangeJobDetails = (selectedJob) => {
    let { job } = this.state;

    this.jobInfoService.findJobDetailsForPublic(selectedJob).then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          job = body;
          this.setState({ job });
        });
      } else {
        return res.json().then((err) => {
          console.log("err", err);
        });
      }
    });
  };

  applyJobProcess=()=>{
    let {job} = this.state;
    this.props.history.push(`/apply-job/${job.jobId}/${job.jobCode}/${job.company.companyId}`);
  }

  jobAlertBtn =()=> {
    this.props.history.push('/job-alert');
  }

  render() {
    const { job, jobApplyDialog, showProgress } = this.state;
    return (
      <div className="col-12 pd0 job-details">
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="col-12 details-header" >
          <span>
            <img
              src={FileHandler.getLogoSource(job && job.company)}
              alt={job && job.company.companyName}
            />
            <br />
          </span>
          <span style={{paddingLeft:'5px'}}>
            <Link to={`/company/${job && job.company.companyId}/${job && job.company.companyName}`} 
            title="Company Details" className="company" style={{color:'white'}}>
              {job && job.company.companyName}
            </Link>

            <p style={{ margin: "0px", lineHeight:'normal', fontSize:'18px' }}>{job && job.jobLocation}</p>
            <small style={{ }}>
              {job && job.company.rating}
              <StarRatings
                numberOfStars={1}
                rating={5}
                // rating={job && job.company.rating === null ? 0 : job.company.rating}
                starDimension={"15px"}
                starRatedColor="#6d63e4"
              />
            </small>
            <b className="flw" style={{color:'inherit', border:'0.5px solid'}}>Follow</b>
          </span>
          <span style={{color:'#fff', cursor:'pointer'}}>
            <FaTimes title="Close" onClick={() => this.props.onCloseDetails()}/>
            </span>
        </div>
        
        <div className="col-12 job-title">
          <p>{job && job.jobTitle}</p>
          <span className="tags">
            <span>
              <FaTextHeight /> {job && job.jobType}
            </span>
            <span>
              <FaDollarSign /> {job && job.jobOfferingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
            <span>
              <FaRegCalendarAlt />{" "}
              {DateHandler.getDateFromStringAnyFormat(
                job && job.jobExpireDate,
                "DD MMM, YYYY"
              )}
            </span>
            <span>
              <FaRegClock /> {DateHandler.countDays(job && job.jobPublishDate, new Date())} days ago
              {/* <FaRegClock /> 10 days ago */}
            </span>

            <span>
              <FaRegHeart /> Save Job
            </span>
          </span>
        </div>
        {job && job.jobCategories.length>0 && 
        <div className="col-12 job-cat">
          {job && job.jobCategories.map((c, i) => (
            <span className="cat" key={i}>{c}</span>
          ))}
        </div>}
        <div className="col-12">
          <div className="description" dangerouslySetInnerHTML={{__html: job && job.jobDescription}}></div>
        </div>
        <div className="col-12 job-apply">
          <span className="btn btn-apply" onClick={this.applyJobProcess}>
            <FaPaperPlane /> Apply Now
          </span>
          <span className="btn btn-alert" onClick={this.jobAlertBtn}>
          <FaBell /> Alert Me
          </span>
          {/* <span className="btn btn-share">
            <FaFacebookSquare />
            <FaLinkedin />
            <FaTwitterSquare />
          </span> */}
        </div>


        {/* Apply Dialog */}
        <Dialog
          header="Job Apply"
          visible={jobApplyDialog}
          modal
          style={{ width: "50vw" }}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-arrow-left"
                onClick={() => this.setState({ jobApplyDialog: false })}
              />
              <Button
                label="Submit Application"
                icon="pi pi-check"
                onClick={this.confirmToSave}
                autoFocus
              />
            </div>
          }
          onHide={() => this.setState({ jobApplyDialog: false })}
        >
          <div
            className="col-12"
            style={{
              position: "initial",
              top: "0px",
              border: "none",
              minHeight: "auto",
            }}
          >
            Apply
          </div>
        </Dialog>
      </div>
    );
  }
}

export default JobInSide;

import React, { Component } from "react";
import SectionHeader from "../../common/section-header";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PostedJobs from "./PostedJobs";
import CompanyProfile from "./CompanyProfile";
import Applicants from "./Applicants";
import BasicInfo from "../jobSeeker/BasicInfo";
import ProductSubscription from "./ProductSubscription";
import { CompanyInfoService } from "../../service/CompanyInfoService";
import { Helmet } from "react-helmet";
import { Toast } from "primereact/toast";
import { ToastMessage } from "../../utils/ToastMessage";


const alignment = "section-header text-lg-center";

class EmployerLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      metadata:null,
      companyInfo: null
    }
    this.companyInfoService = new CompanyInfoService();
  }

  componentDidMount(){
    fetch('metaData.json').then(res=>res.json().then(data=>{this.setState({metadata:data.employer})}));
    this.onLoadCompanyInfo();
  }
  onLoadCompanyInfo = () => {
    let { companyInfo } = this.state;
    this.companyInfoService.loggedEmployerCompany().then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          companyInfo = body;
          companyInfo.logoType = "image/png";
          this.setState({ companyInfo });
        });
      }
    });
  }

  showToast=(severity, details)=>{
    this.toast.show(ToastMessage.getMessage(severity, details));
  }
  render() {
    const {companyInfo, metadata} = this.state;


    let title = <img src={`data:${companyInfo && companyInfo.logoType};base64, ${companyInfo && companyInfo.logoContent}`} alt="Company Logo"
    style={{width:'80px', height:'75px', border:'1px solid gray', borderRadius:'4px'}}/>;
    let subtitle = (
      <span className="sub-title" style={{ lineHeight: "normal" }}>
        <h3>{companyInfo && companyInfo.companyName}</h3>
      </span>
    );
    return (
      <section
        id="employer-layout"
        className="profile-section bg-ash-color pb--60"
      >
        <Toast ref={(el) => (this.toast = el)}/>
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <div className="container position-relative pt--100 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-lg-12 order-lg-last">
              <div className="profile-section-content text-center text-lg-left">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  alignment={alignment}
                />
                <div className="col-12">
                  <Tabs>
                    <TabList>
                      <Tab>Admin Profile</Tab>
                      <Tab>Company Profile</Tab>
                      <Tab>Job Postings</Tab>
                      <Tab>Applicants</Tab>
                      <Tab>Subscription</Tab>
                    </TabList>
                    <TabPanel>
                      <div>
                        <BasicInfo toast={this.showToast}/>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <CompanyProfile toast={this.showToast}/>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <PostedJobs company={companyInfo} toast={this.showToast}/>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <Applicants company={companyInfo} toast={this.showToast}/>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <ProductSubscription company={companyInfo} toast={this.showToast}/>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default EmployerLayout;

export class ToastMessage {
    static getMessage(severity, details) {
       let message = null;
       switch(severity) {
            case 1:
                message = {severity:'success', summary:'Successful !!'};
                break;
            case 2:
                message = {severity:'info', summary:'Successful !!'};
                break;
            case 3:
                message = {severity:'warn', summary:'Warn !!'};
                break;
            case 4:
                message = {severity:'error', summary:'Failed !!'};
                break;
            default:
                message = null;
                break;
        }
       if(message) {
           message['detail'] = details;
       }
        return message;
    }
    
}
import { PrivateService } from "../auth/PrivateService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class FavoriteJobService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    saveFavoriteJob(jobId) {
        let obj = {
            jobId : jobId
        }
        let uri = this.baseUriHolder.getJobSeeker() + '/job/favorites';
        return this.privateService.postFetch(uri, obj);
    }

    findFavoriteJobListByUser(){
        let uri = this.baseUriHolder.getJobSeeker() + '/job/favorites';
        return this.privateService.getFetch(uri);
    }
}
import React, { Component } from "react";
import { UserService } from "../../service/UserService";
import { Calendar } from "primereact/calendar";
import { DateHandler } from "../../utils/DateHandler";
import Validation from '../../utils/validationUtil';
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { Toast } from "primereact/toast";

class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: {
        fullName: "",
        email: "",
        phone: "",
        dob: "",
        showProgress: false,
      },
      errors: {},
    };
    this.userService = new UserService();
  }
  componentDidMount() {
    this.userService.loadLoggedUserProfile().then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          this.setState({ basicInfo: body });
        });
      }
    });
  }

  onChangeName = (e) => {
    let { basicInfo, errors } = this.state;
    basicInfo.fullName = e.target.value;
    errors["fullName"] = " ";
    this.setState({ basicInfo });
  };
  onChangePhone = (e) => {
    let { basicInfo, errors } = this.state;
    basicInfo.phone = e.target.value;
    errors["phone"] = " ";
    this.setState({ basicInfo });
  };
  onChangeDob = (e) => {
    let { basicInfo } = this.state;
    basicInfo.dob = e.target.value;
    this.setState({ basicInfo });
  };

  updateBasicInfo = () => {
    let { basicInfo, showProgress } = this.state;
    if (!this.validateInfo()) {
      return;
    }
    this.setState({ showProgress: true });
    this.userService.updateUserProfile(basicInfo).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          basicInfo = body;
          showProgress = false;
          this.props.toast(1, "Profile successfully updated");
          this.setState({ basicInfo, showProgress });
        });
      } else {
        return res.json().then((err) => {
          this.props.toast(4, err.message);
          showProgress = false;
          this.setState({ showProgress });
        });
      }
    });
  };
  validateInfo = () => {
    let { basicInfo, errors } = this.state;
    let val = true;
    if (basicInfo.fullName === '' || basicInfo.fullName === undefined) {
      errors["fullName"] = "Enter full name";
      val = false;
      // this.setState({errors});
      // return;
    }
    if (basicInfo.phone !== "") {
      console.log("condition does'nt work");
      if (!Validation.isCurrectPhone(basicInfo.phone)) {
        errors["phone"] = "Enter valid phone number";
        val = false;
      }
    }
    if (!val) {
      this.setState({ errors });
    }
    return val;
  };
  render() {
    const { basicInfo, showProgress, errors } = this.state;
    let dob = basicInfo.dob
      ? DateHandler.getDateFromStringDDMMYYYY(basicInfo.dob)
      : null;
    return (
      <div className="col-12 p-0">
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="col-3"></div>
        <div className="col-lg-9 col-sm-12 p-0">
          <div className="profile-info-container-style1 hover-effect-parent1">
            <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
              <div className="content max-width-425">
                <h4 className="font-weight-bold mb--30 mb-lg-30 mt-1">
                  Basic Information
                </h4>
                <div className="row">
                  <div className="col-lg-12 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Full Name *</label>
                      <input
                        id="name"
                        type="text"
                        className="form-control"
                        value={basicInfo.fullName}
                        onChange={this.onChangeName}
                      />
                      <div className="error-msg">
                        <span>{errors["fullName"]}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        id="email"
                        type="email"
                        disabled
                        className="form-control"
                        value={basicInfo.email}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        id="phone"
                        type="text"
                        className="form-control"
                        value={basicInfo.phone}
                        onChange={this.onChangePhone}
                      />
                      <div className="error-msg">
                        <span>{errors["phone"]}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Birth Date</label>
                      <Calendar
                        value={basicInfo.dob}
                        placeholder={dob}
                        showIcon
                        monthNavigator
                        yearNavigator
                        yearRange={`1970:${new Date().getFullYear()}`}
                        onChange={this.onChangeDob}
                      />
                    </div>
                  </div>
                  <div className="w-100 text-center mt--20 mb--10 mb-lg-10">
                    <button
                      className="btn btn-primary w-25"
                      type="submit"
                      onClick={this.updateBasicInfo}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-outline-primary w-25 ml-2"
                      type="submit"
                      onClick={this.onSignin}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BasicInfo;

import React, { Component } from 'react';
import JobSearch from './JobSearch';
import KeywordSection from './keyword-section';
import ProfileSection from  './ProfileSection';
// import VideoSection from './video-section';
// import InterfaceSliderSection from './interface-slider-section';
import PostJob from './post-job';
// import TestimonialSection from './testimonial-section';
// import FaqSection from './faq-section';
import CityStateWiseJobSection from './CityStateWiseSection';
import { HomeService } from '../../service/HomeService';

class Home extends Component {
    constructor(props){
        super(props);

        this.state = {
            homeData:null
        }
        this.homeService = new HomeService();
    }

    componentDidMount(){
        let {homeData} = this.state;

        this.homeService.loadHomePageData()
        .then(res => {
            if(res.status === 200) {
                return res.json().then(body => {
                    homeData = body;
                    this.setState({homeData});
                })
            }
        })
        .catch(err => {
            console.log('errCatch', err);
        })
    }
    render(){
        const {homeData} = this.state;
        return(
            <div>
                <JobSearch totalJob={homeData && homeData.totalJob}></JobSearch>
                <KeywordSection trendingSearch={homeData && homeData.top20Keywords}></KeywordSection>
                <ProfileSection></ProfileSection>
                {/* <VideoSection></VideoSection> */}
                {/* <InterfaceSliderSection></InterfaceSliderSection> */}
                <PostJob></PostJob>
                {/*<CityStateWiseJobSection></CityStateWiseJobSection>*/}
                {/* <TestimonialSection></TestimonialSection> */}
                {/* <FaqSection></FaqSection> */}
            </div>
        )
    }
}
export default Home;
import { PrivateService } from "../auth/PrivateService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class ProductSubscriptionService {
    constructor(){
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    newSubscription(ProductSubsInfo) {
        let uri = this.baseUriHolder.getEmployer() + '/product/subscription';
        return this.privateService.postFetch(uri,ProductSubsInfo);
    }
} 
import { PrivateService } from "../auth/PrivateService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class JobAlertService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }

    saveSubscription(alertObj) {
        let uri = this.baseUriHolder.getJobSeeker() + '/job/alerts';
        return this.privateService.postFetch(uri, alertObj);
    }
}
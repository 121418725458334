import React, { Component } from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import {
  FaRegClock,
  FaTextHeight,
  FaRegHeart,
  FaDollarSign,
  FaRegCalendarAlt,
  FaPaperPlane,
  FaBell,
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
} from "react-icons/fa";
import { DateHandler } from "../../utils/DateHandler";
import { JobInfoService } from "../../service/JobInfoService";
import { FileHandler } from "../../utils/FileHandler";

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: null,
    };
    this.jobInfoService = new JobInfoService();
  }
  componentWillMount() {
    if (!this.props.location.state) {
      return;
    }
    const { selectedJob } = this.props.location.state;
    this.onChangeJobDetails(selectedJob);
  }

  onChangeJobDetails = (selectedJob) => {
    let { job } = this.state;
    this.jobInfoService.findJobDetailsForPublic(selectedJob).then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          job = body;
          this.setState({ job });
        });
      } else {
        return res.json().then((err) => {
          console.log("err", err);
        });
      }
    });
  };


  applyJobProcess=()=>{
    let {job} = this.state;
    this.props.history.push(`/apply-job/${job.jobId}/${job.jobCode}/${job.company.companyId}`);
  }
  render() {
    const { job } = this.state;
    return (
      <section
        id="jobs"
        className="inner-page-section pt--115 pt_lg--90 pb--135 pb_lg-120"
      >
        {job ? (
          <div
            className="col-12 job-details"
            style={{ position: "relative", top: "0px", border: "none" }}
          >
            <div className="col-12">
              <div className="text-center">
                <span>
                  <img
                    src={FileHandler.getLogoSource(job && job.company)}
                    alt={job && job.company.companyName}
                  />
                </span>
                <br />
              </div>
              <span>
                <Link to={`/company/${job && job.company.companyId}/${job && job.company.companyName}`}
                title="Company Details" className="company">
                  {job && job.company.companyName}
                </Link>

                <p style={{ margin: "0px" }}>{job && job.jobLocation}</p>
                <small style={{ color: "#6d63e4" }}>
                  {job && job.company.rating}
                  <StarRatings
                    numberOfStars={1}
                    rating={5}
                    starDimension={"15px"}
                    starRatedColor="#6d63e4"
                  />
                </small>
                <b className="flw">Follow</b>
              </span>
            </div>
            <div className="col-12 job-title">
              <p>{job && job.jobTitle}</p>
              <span
                className="tags"
                style={{
                  gridTemplateColumns: "1fr 1fr",
                  gridAutoFlow: "inherit",
                }}
              >
                <span>
                  <FaTextHeight /> {job && job.jobType}
                </span>
                <span>
                  <FaDollarSign /> {job && job.jobOfferingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
                <span>
                  <FaRegCalendarAlt />{" "}
                  {DateHandler.getDateFromStringAnyFormat(
                    job && job.jobExpireDate,
                    "DD MMM, YYYY"
                  )}
                </span>
                <span>
                  <FaRegClock /> {DateHandler.countDays(job && job.jobPublishDate, new Date())} days ago
                  {/* <FaRegClock /> 10 days ago */}
                </span>
                <span>
                  <FaRegHeart /> Save Job
                </span>
              </span>
            </div>
            <div className="col-12">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: job && job.jobDescription }}
              ></div>
            </div>
            <div
              className="col-12 job-apply"
              style={{ gridRowGap: "5px", gridAutoFlow: "inherit" }}
            >
              <span className="btn btn-apply" onClick={this.applyJobProcess}>
                <FaPaperPlane /> Apply Now
              </span>
              <span className="btn btn-alert">
                <FaBell /> Alert Me
              </span>
              <span className="btn btn-share">
                <FaFacebookSquare />
                <FaLinkedin />
                <FaTwitterSquare />
              </span>
            </div>
          </div>
        ) : (
          <div className="col-12">No Job Details available</div>
        )}
      </section>
    );
  }
}

export default JobDetails;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import {
  FaRegClock,
  FaTextHeight,
  FaRegHeart,
  FaDollarSign,
  FaRegCalendarAlt,
  FaPaperPlane,
  FaBell,
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare, FaTimes
} from "react-icons/fa";
import { JobInfoService } from "../../service/JobInfoService";
import { SiteConfigService } from "../../service/SiteConfigService";
import { DateHandler } from "../../utils/DateHandler";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { FileHandler } from "../../utils/FileHandler";

class JobDetailsDesktopUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: null,
      jobApplyDialog: false,
      showProgress:false,
      jobSaveBtn:false,
      externalJobApplySetting:{}
    };
    this.jobInfoService = new JobInfoService();
    this.siteConfigService = new SiteConfigService();
  }

  componentWillMount() {
    if (!this.props.location.state) {
      return;
    }
    const { selectedJob } = this.props.location.state;
    this.onChangeJobDetails(selectedJob);
    this.onLoadSiteConfigs();
  }

  onChangeJobDetails = (selectedJob) => {
    let { job } = this.state;

    this.jobInfoService.findJobDetailsForPublic(selectedJob).then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          job = body;
          this.setState({ job });
        });
      } else {
        return res.json().then((err) => {
          console.log("err", err);
        });
      }
    });
  };

  onLoadSiteConfigs =()=> {
    let {externalJobApplySetting} = this.state;

    let names = ['EXTERNAL_JOB_APPLY'];

    this.siteConfigService.getConfigsByNames(names)
    .then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          const external = body.find(({configName}) => configName === 'EXTERNAL_JOB_APPLY');
          externalJobApplySetting = external;

          this.setState({externalJobApplySetting});
        })
      }
      else {
        return res.json().then((err) => {
          console.log("err", err);
        });
      }
    })
  }

  applyJobProcess=()=>{
    let {job, externalJobApplySetting } = this.state;

    console.log(job);

    let externalLink = externalJobApplySetting && externalJobApplySetting.configValue === 'yes';

    if (externalLink && job.company.companyName === null) {
      window.location.replace(job.jobApplyingWayValue);
    }
    else {
      this.props.history.push(`/apply-job/${job.jobId}/${job.jobCode}/${job.company.companyId}`);
    }
  }

  jobAlertBtn =()=> {
    this.props.history.push('/job-alert');
  }

  render() {
    const { job, jobApplyDialog, showProgress } = this.state;
    return (
      <section id="jobs" className="inner-page-section pt--100 pt_lg--90 pb--70 pb_lg-120">
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container text-left">
        <div className="col-12 pd0 mt--15 job-details">
        <div className="col-12 details-header" >
          <span>
            <img
              src={FileHandler.getLogoSource(job && job.company)}
              alt={job && job.company.companyName}
            />
            <br />
          </span>
          <span className="pl--15 d-flex flex-column align-items-start flex-grow-1">
            <span className="mb-auto">
              <Link to={`/company/${job && job.company.companyId}/${job && job.company.companyName}`} 
              title="Company Details" className="company" style={{color:'white'}}>
                {job && job.company.companyName}
              </Link>

              <p style={{ margin: "0px", lineHeight:'normal', fontSize:'18px' }}>{job && job.jobLocation}</p>
            </span>
            <span className="d-flex w-100">
              <small className="w-100">
                {job && job.company.rating}
                <StarRatings
                  numberOfStars={1}
                  rating={5}
                  // rating={job && job.company.rating === null ? 0 : job.company.rating}
                  starDimension={"15px"}
                  starRatedColor="#6d63e4"
                />
              </small>
              <span style={{color:'#fff', cursor:'pointer'}}>
              <b className="flw" style={{border:'0.5px solid', padding:'5px 10px', fontSize:'inherit'}}>Follow</b>
              </span>
            </span>
          </span>
          
        </div>
        
        <div className="col-12 job-title mt--15">
          <p>{job && job.jobTitle}</p>
          <span className="tags mt--10">
            <span>
              <FaTextHeight /> {job && job.jobType}
            </span>
            {job && job.jobOfferingAmount &&
            <span>
              <FaDollarSign /> {job && job.jobOfferingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>}
            <span>
              <FaRegCalendarAlt />{" "}
              {DateHandler.getDateFromStringAnyFormat(
                job && job.jobExpireDate,
                "DD MMM, YYYY"
              )}
            </span>
            <span>
              <FaRegClock /> {DateHandler.countDays(job && job.jobPublishDate, new Date())} days ago
              {/* <FaRegClock /> 10 days ago */}
            </span>

            <span>
              <FaRegHeart /> Save Job
            </span>
          </span>
        </div>
        {job && job.jobCategories.length>0 && 
        <div className="col-12 job-cat mt--10">
          {job && job.jobCategories.map((c, i) => (
            <span className="cat" key={i}>{c}</span>
          ))}
        </div>}
        <div className="col-12 mt--15">
          <div><b>Description</b></div>
          <div className="description" dangerouslySetInnerHTML={{__html: job && job.jobDescription}}></div>
        </div>
        <div className="col-12 job-apply mt--15">
          <span className="btn btn-apply" onClick={this.applyJobProcess}>
            <FaPaperPlane /> Apply Now
          </span>
          <span className="btn btn-alert" onClick={this.jobAlertBtn}>
          <FaBell /> Alert Me
          </span>
          {/* <span className="btn btn-share">
            <FaFacebookSquare />
            <FaLinkedin />
            <FaTwitterSquare />
          </span> */}
        </div>


        {/* Apply Dialog */}
        <Dialog
          header="Job Apply"
          visible={jobApplyDialog}
          modal
          style={{ width: "50vw" }}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-arrow-left"
                onClick={() => this.setState({ jobApplyDialog: false })}
              />
              <Button
                label="Submit Application"
                icon="pi pi-check"
                onClick={this.confirmToSave}
                autoFocus
              />
            </div>
          }
          onHide={() => this.setState({ jobApplyDialog: false })}
        >
          <div
            className="col-12"
            style={{
              position: "initial",
              top: "0px",
              border: "none",
              minHeight: "auto",
            }}
          >
            Apply
          </div>
        </Dialog>
        </div>
        </div>
      </section>
    );
  }
}

export default JobDetailsDesktopUI;

import { PrivateService } from "../auth/PrivateService";
import { PublicService } from "../auth/PublicService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class JobInfoService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
        this.publicService = new PublicService();
    }

   
    findActiveJobs(paginator) {
        let uri = this.baseUriHolder.getPublic() + '/jobs/active';
        return this.publicService.postFetch(uri, paginator);
    }

    findJobsForPublic(jobTitle, address) {
        let uri = this.baseUriHolder.getPublic() + `/jobs?jobTitle=${jobTitle}&address=${address}`;
        return this.publicService.getFetch(uri);
    }

    findJobsForPublicWithPage(paginator) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/page/by/title/address`;
        return this.publicService.postFetch(uri, paginator);
    }
    
    findJobsForPublicByJobTitle(jobTitle) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/by/title?jobTitle=`+jobTitle;
        return this.publicService.getFetch(uri);
    }

    findJobsByJobTitleWithPage(paginator) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/page/by/title`;
        return this.publicService.postFetch(uri, paginator);
    }

    findJobsForPublicByLocation(address) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/by/address?address=`+address;
        return this.publicService.getFetch(uri);
    }
    
    findJobsForPublicByLocationWithPage(paginator) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/page/by/address`;
        return this.publicService.postFetch(uri, paginator);
    }

    findJobDetailsForPublic(filter) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/details`;
        return this.publicService.postFetch(uri, filter);
    }

    
    findJobDetailsForEmployer(filter) {
        let uri = this.baseUriHolder.getEmployer() + `/jobs/details`;
        return this.privateService.postFetch(uri, filter);
    }

    findJobDetailsForJobSeeker(filter) {
        let uri = this.baseUriHolder.getJobSeeker() + `/jobs/details`;
        return this.privateService.postFetch(uri, filter);
    }

    findJobsByCompanyForPublic(companyId) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/company/q?companyId=${companyId}`;
        return this.publicService.getFetch(uri);
    }

    countJobsByLocationPublic(paginator) {
        let uri = this.baseUriHolder.getPublic() + `/jobs/count/by/location`;
        return this.publicService.postFetch(uri, paginator);
    }
}
export class JobFilterUtils {
    static updateLocatStore(searchObj){
        
        let jobFilter = localStorage.getItem('jobFilter');
        if (jobFilter) {
        jobFilter = JSON.parse(jobFilter);
        } else {
        jobFilter =[];
        }
        let filters = jobFilter.filter(f => f.jobTitle === searchObj.jobTitle);
        if(filters.length === 0){
        jobFilter.push(searchObj);
        } 
        jobFilter.forEach((v) => {
        if(v.jobTitle === searchObj.jobTitle){
            v.selected = true;
            v.address = searchObj.address === null ? v.address : searchObj.address;
        }
        else {
            v.selected = false;
        }
        });

        localStorage.setItem("jobFilter", JSON.stringify(jobFilter));
    }
}
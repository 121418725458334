import React, {Component} from 'react';
import PostedJobList from './PostedJobList';
import NewJobPost from './NewJobPost';
import { JobInfoService } from '../../service/JobInfoService';
import EditJobPost from './EditJobPost';


class PostedJobs extends Component {
    constructor(props){
        super(props);
        this.state={
            // visible:0,
            visible:null,
            jobList:[],
            selectedJob :{}
        }
        this.jobInfoService = new JobInfoService ();
    }
    componentDidMount(){
        this.onUpdateVisible();
        setTimeout(() => { 
            this.onLoadJobList();
        }, 1500);
        
    }

    onLoadJobList =()=> {
        let {jobList} = this.state;
        const {company} = this.props;
if(company) {
        this.jobInfoService.findEmployerJobs(company.companyId)
        .then(res => {
            if(res.status === 200) {
                return res.json().then(body => {
                    jobList = body;
                    this.setState({jobList});
                })
            }
        });
    }
    }
    onUpdateVisible=()=>{
        let {visible} = this.state;
        visible=localStorage.getItem('jobPost');
        this.setState({visible});
    }

    showToast=(severity, details)=>{
        this.props.toast(severity, details);
    }
    onUpdateSelectedJob=(jobId, jobCode)=> {
        if(jobId && jobCode) {
            let {selectedJob} = this.state;
            const {company} = this.props;
            selectedJob['jobId'] = jobId;
            selectedJob['jobCode'] = jobCode;
            selectedJob['companyId'] = company.companyId;
            this.setState({selectedJob});
        } else {
            localStorage.setItem("jobPost", "list");
            // localStorage.setItem("jobPost", 0);
            // this.setState({visible:0});
            this.setState({visible: "list"});
        }
    }
    render(){
        let CompJobPost;
        const {visible, jobList, selectedJob}=this.state;
        console.log("Job Post Status : ", visible);
        // visible=1;
        // if(visible===null || visible===0){
        if(visible===null || visible==="list"){
            CompJobPost=<PostedJobList jobList={jobList} handleVisible={this.onUpdateVisible} reloadJobList={this.onLoadJobList} company={this.props.company}
            onUpdateJob={this.onUpdateSelectedJob}/>;
        }else if(visible==="new"){
            CompJobPost=<NewJobPost handleVisible={this.onUpdateVisible} toast={this.showToast}/>;
        }else if(visible==="edit"){
            if((selectedJob !== null && selectedJob.jobId !== null) || selectedJob.jobId !== undefined) {
                 CompJobPost=<EditJobPost job={selectedJob} handleVisible={this.onUpdateVisible} toast={this.showToast} reloadJobList={this.onLoadJobList}/>;
            }
            else {
                CompJobPost=<PostedJobList jobList={jobList} handleVisible={this.onUpdateVisible} reloadJobList={this.onLoadJobList} company={this.props.company}
                onUpdateJob={this.onUpdateSelectedJob}/>;
            }
        }
        return(
            <div className="row">
                <div className="col-lg-12">
                    {CompJobPost}
                </div>
            </div>
        )
    }
}
export default PostedJobs;
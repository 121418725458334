import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import SectionHeader from "../../common/section-header";
import { JobFilterUtils } from "../../utils/JobFilterUtils";

const title = <h3 className="mb--25">People are searching now</h3>;
const alignment = "section-header";

class KeywordSection extends Component {
  onClickFindJobs = (val) => {   
    let searchObj = {
      jobTitle: val,
      address:null,
      selected:true
    }
    JobFilterUtils.updateLocatStore(searchObj); 
    window.location.replace("/jobs");
  };
  render() {
    let recentSearch = localStorage.getItem('jobFilter');

    if (recentSearch) {
      recentSearch = JSON.parse(recentSearch);
    } else {
      recentSearch =[]
    }
    if(recentSearch){
      recentSearch = recentSearch.filter(f => f.jobTitle != null);
    }
    let trendingSearch = this.props.trendingSearch != null ? this.props.trendingSearch : [];
    return (
      <section id="keywordSection" className="keyword-section pb--60">
        <div className="container container0p">
          <SectionHeader title={title} alignment={alignment} />
        </div>
        <div className="container container0p">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-md-6 col-lg-6 mb--30 mb-lg-0">
              <div className="keyword-item text-left">
                <h5>Recent searches</h5>
                <div className="keywords">
                  {recentSearch && recentSearch.map((val, i) => (
                    
                   <p key={i}>
                   <a href="#" onClick={() => this.onClickFindJobs(val.jobTitle)}>
                      <span>
                        {/* <FaSearch /> {val} */}
                        <FaSearch /> {val.jobTitle}
                      </span>
                      </a>
                    </p>
                    
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb--30 mb-lg-0">
              <div className="keyword-item text-left">
                <h5>Trending searches</h5>
                <div className="keywords">
                  {trendingSearch.map((val, i) => (
                   
                    <p key={i}>
                      <a href="#" onClick={() => this.onClickFindJobs(val)}>
                       <span >
                        <FaSearch /> {val} 
                      </span>
                      </a> 
                     </p>
                   
                    
                  //   <button type="click"
                  //   onClick={() => this.onClickFindJobs(val)}
                  //   >
                  //   <span>
                  //     <FaSearch /> {val}
                  //    </span>
                  // </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default KeywordSection;

import React, {Component} from 'react';


function Select({options, value, onChange, className}){
   

    return(
        <select value={value} onChange={onChange} className={className}>
            {options.map((o, i)=>{
                return <option value={o.value} key={i}>{o.label}</option>;
            })}
        </select>
    )

}
export default Select;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "./auth";

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useAuth();
  // const { authTokens } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/signin", state: { referer: props.location } }} />
        )
      }
    />
  );
}
export default PrivateRoute;
import { BaseUriHolder } from "../utils/BaseUriHolder";
import { PrivateService } from "../auth/PrivateService";

export class ResumeInfoService {
    constructor () {
        this.baseUriHolder = new BaseUriHolder();
        this.privateService = new PrivateService();
    }


    createNewResume(info) {
        let uri = this.baseUriHolder.getJobSeeker() +'/resumes';
        return this.privateService.postFetch(uri, info);
    }

    loggedJobSeekerResumes() {
        let uri = this.baseUriHolder.getJobSeeker() +'/resumes';
        return this.privateService.getFetch(uri);
    }

    loggedJobSeekerResumeDetails(resumeId) {
        let uri = this.baseUriHolder.getJobSeeker() +'/resumes/q?resumeId='+resumeId;
        return this.privateService.getFetch(uri);
    }
}
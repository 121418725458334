import React, {Component} from 'react';
import { TextEditorHeader } from '../../common/editor-header';
import { Editor } from 'primereact/editor';
import FileInput from '../../common/file-input';
import Select from '../../common/select';
import { GlobalInfoService } from '../../service/GlobalInfoService';
import Validation from '../../utils/validationUtil';
import { CompanyInfoService } from '../../service/CompanyInfoService';
import { AddressInfoService } from '../../service/AddressInfoService';
import { ProgressSpinnerLoader } from '../../common/ProgressSpinnerLoader';


class CompanyProfile extends Component{
  constructor(props) {
    super(props);
    this.state = {
      companyType:'',
      // companyType:null,
      companyTypes:[],
      companyCategory:'',
      // companyCategory:null,
      companyCategories:[],
      companyInfo:{
        companyId:'',
        companyName:'',
        companyDescription:'',
        companyEmail:'',
        companyCode:'',
        logoName:'',
        logoType:'',
        logoContent:[],
        websiteUrl:''
      },
      locationInfo:{
        locationName:'',
        zipCode:'',
      },
     
      cityList:[],
      selectedCity:'',
      // selectedCity:null,
      stateList:[],
      selectedState:'',
      // selectedState:null,
      showProgress: false,
      errors:{}
    }
    this.globalInfoService = new GlobalInfoService();
    this.companyInfoService = new CompanyInfoService();
    this.addressInfoService = new AddressInfoService();
  }

componentDidMount() {
  this.onLoadCompanyInfoIfExist();
  this.onLoadCompanyType();
  this.onLoadCompanyCategory();
  this.onLoadAddressCityList();
  this.onLoadAddressStateList();
}

onLoadCompanyInfoIfExist =()=> {
  let {companyInfo, companyType, companyCategory, selectedCity, selectedState, locationInfo} = this.state;
  this.companyInfoService.loggedEmployerCompany()
  .then(res=> {
    if (res.status === 302) {
      return res.json().then(body=> {
        companyInfo = body;
        companyInfo.logoType='image/png'
        if (body.companyType) {
          companyType = body.companyType.globalId;
        }
        if (body.companyCategory) {
          companyCategory = body.companyCategory.globalId;
        }
        if(body.locationInfoDto) {
          locationInfo = body.locationInfoDto;
          if(body.locationInfoDto.cityInfoDto) {
            selectedCity = body.locationInfoDto.cityInfoDto.addressId;
          }
          if(body.locationInfoDto.stateInfoDto) {
            selectedState = body.locationInfoDto.stateInfoDto.addressId;
          }
        }
        this.setState({companyInfo, companyType, companyCategory, selectedCity, selectedState, locationInfo});
      })
    }
  })
}
  onLoadCompanyType =()=> {
    let {companyTypes} = this.state;
    companyTypes.push({label: 'Select Company Type', value: 0});
    this.globalInfoService.getGlobalInfosByUseForAndIn('Company', 'Type')
    .then(res => {
      if(res.status === 302) {
        return res.json().then(body => {
          body.forEach(b => {
            
            companyTypes.push({label: b.globalName, value: b.globalId})
          })
          this.setState({companyTypes});
        })
      } else {
        this.setState({companyTypes});
      }
    })
  }
  onLoadCompanyCategory =()=> {
    let {companyCategories} = this.state;
    companyCategories.push({label: 'Select Company Category', value: 0});
    this.globalInfoService.getGlobalInfosByUseForAndIn('Company', 'Category')
    .then(res => {
      if(res.status === 302) {
        return res.json().then(body => {
          body.forEach(b => {
            
            companyCategories.push({label: b.globalName, value: b.globalId})
          })
          this.setState({companyCategories});
        })
      } else {
        this.setState({companyCategories});
      }
    })
  }

  onLoadAddressCityList =()=> {
    let {cityList} = this.state;
    cityList.push({label: 'Select City', value: 0});
    this.addressInfoService.getAllAddressByType('City')
    .then(res => {
      if(res.status === 200) {
        return res.json().then(body => {
          body.forEach(b => {
            
            cityList.push({label: b.addressName, value: b.addressId})
          })
          this.setState({cityList});
        })
      }
    })
  }

  onLoadAddressStateList =()=> {
    let {stateList} = this.state;
    stateList.push({label: 'Select State', value: 0});
    this.addressInfoService.getAllAddressByType('State')
    .then(res => {
      if(res.status === 200) {
        return res.json().then(body => {
          body.forEach(b => {
            
            stateList.push({label: b.addressName, value: b.addressId})
          })
          this.setState({stateList});
        })
      }
    })
  }

  onChangeCompanyInfo=(e)=>{
    let {companyInfo} = this.state;
    companyInfo[e.target.name] = e.target.value;
    this.setState({companyInfo});
  }

  onChangeLocationInfo =(e)=> {
    let {locationInfo} = this.state;
    locationInfo[e.target.name] = e.target.value;
    this.setState({locationInfo});
  }
  onUploadLogo=(e)=> {
    let {companyInfo} = this.state;
    var reader = new FileReader();
    
    const scope = this;
    reader.readAsDataURL(e);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + e.type + ";base64,"; 
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      companyInfo.logoName = e.name;
      companyInfo.logoType = e.type;
      companyInfo.logoContent = urlStr;
      scope.setState({companyInfo});
    };
  }

  onTextChangeCopmnyDecrp=(e)=> {
    let {companyInfo} = this.state;
    companyInfo.companyDescription = e.htmlValue;
    this.setState({companyInfo});
  }

  onSaveCompanyInfo =()=> {
    let {companyInfo, companyType, companyCategory, locationInfo,
      selectedCity, cityList, selectedState, stateList, showProgress, errors} = this.state;
    if (!this.validateCompanyInfo()) {
      return;
    }

    if (companyInfo.logoName === '' || companyInfo.logoContent.length ===0) {
      errors['companyLogo'] = 'Upload company logo';
      return;
    }
    if (companyType !== '') {
      companyInfo['companyType'] = {globalId: companyType};
    }
    if (companyCategory !=='') {
      companyInfo['companyCategory'] = {globalId: companyCategory};
    }

    if(locationInfo !== null) {

      // cityList.forEach((c) => {
      //   if(selectedCity && c.addressId === selectedCity) {
      //     locationInfo['cityInfoDto'] = c;
      //   }
      // });

      // stateList.forEach((s) => {
      //   if(selectedState && s.addressId === selectedState) {
      //     locationInfo['stateInfoDto'] = s;
      //   }
      // });

      // companyInfo['locationInfoDto'] = locationInfo;

      let city = cityList.find(f => selectedCity !== null && f.value === selectedCity);
      
      if(city){
        let cityObj ={
          addressId:selectedCity
        }
        locationInfo['cityInfoDto'] = cityObj;
      }

     let state = stateList.find(s => selectedState !== null && s.value === selectedState);
     if(state){
      let stateObj ={
        addressId:selectedState
      }
      locationInfo['stateInfoDto'] = stateObj;
    }
      console.log(locationInfo);

      companyInfo.locationInfoDto = locationInfo;
    }
    
    this.setState({showProgress:true});
    this.companyInfoService.createNewCompany(companyInfo)
    .then(res => {
      if(res.status === 201) {
        this.onLoadCompanyInfoIfExist();
        return res.json().then(body => {
          showProgress = false;
          this.props.toast(1, 'Company Profile has successfully saved');
          this.setState({showProgress});
        })
      }else{
        return res.json(err => {
          showProgress = false;
          this.props.toast(4, err.message);
          this.setState({showProgress});
        })
      }
    })
this.setState({errors});
  }


  
  onUpdateCompanyInfo =()=> {
    let {companyInfo, companyType, companyCategory, locationInfo,
      selectedCity, cityList, selectedState, stateList, showProgress, errors} = this.state;
    if (!this.validateCompanyInfo()) {
      return;
    }

    if (companyType !== '') {
      companyInfo['companyType'] = {globalId: companyType};
    }
    if (companyCategory !=='') {
      companyInfo['companyCategory'] = {globalId: companyCategory};
    }
    
    if(locationInfo !== null) {

      let city = cityList.find(f => selectedCity !== null && f.value === selectedCity);
      
      if(city){
        let cityObj ={
          addressId:selectedCity
        }
        locationInfo['cityInfoDto'] = cityObj;
      }

     let state = stateList.find(s => selectedState !== null && s.value === selectedState);
     if(state){
      let stateObj ={
        addressId:selectedState
      }
      locationInfo['stateInfoDto'] = stateObj;
    }
      console.log(locationInfo);

      companyInfo.locationInfoDto = locationInfo;
    }

    this.setState({showProgress:true});
    this.companyInfoService.updateCompany(companyInfo)
    .then(res => {
      if(res.status === 202) {
        this.onLoadCompanyInfoIfExist();
        return res.json().then(body => {
          showProgress = false;
          this.props.toast(1, 'Profile is successfully updated');
          this.setState({showProgress});
        })
      }else{
        return res.json().then(err => {
          showProgress = false;
          this.props.toast(4, err.message);
          this.setState({showProgress});
        })
      }
    })
this.setState({errors});
  }


  validateCompanyInfo =()=> {
    let {companyInfo, errors} = this.state;
    let val = true;
    if (companyInfo.companyName === '' || companyInfo.companyName === undefined) {
      errors['companyName'] = 'Enter your company name';
      val =false;
    }

    if (companyInfo.companyEmail === '' || companyInfo.companyEmail === undefined) {
      errors['companyEmail'] = 'Enter company email';
      val =false;
    }

    if (!Validation.isCurrectEmail(companyInfo.companyEmail)) {
      errors['companyEmail'] = 'Enter valid email address';
      val =false;
    }


    return val;
  }
    render(){
      const {companyInfo, companyType, companyTypes, companyCategory, companyCategories, 
        locationInfo, cityList,selectedCity, stateList, selectedState, showProgress, errors} = this.state;
        
        return(
            <div className="col-12 p-0">
              <ProgressSpinnerLoader showBar={showProgress} />
          <div className="col-3"></div>
          <div className="col-lg-9 col-sm-12 p-0">
        <div className="profile-info-container-style1 hover-effect-parent1">
          <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
            <div className="content max-width-425">
              <h4 className="font-weight-bold mb--30 mb-lg-30 mt-1">
                Basic Information
              </h4>
              <div className="row">
              <div className="col-lg-6 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Company Name *</label>
                      <input id="companyName" name="companyName" type="text" className="form-control"
                          value={companyInfo.companyName}
                        onChange={this.onChangeCompanyInfo}
                      />
                    </div>
                    <span className="error-msg">{errors['companyName']}</span>
                  </div>

                  <div className="col-lg-6 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Company Code</label>
                      <input type="text" disabled
                        className="form-control"
                          value={companyInfo.companyCode}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Company Description</label>
                      <Editor
                      headerTemplate={TextEditorHeader}
                        style={{ height: "200px" }}
                        value={companyInfo.companyDescription}
                        onTextChange={this.onTextChangeCopmnyDecrp}
                      />
                    </div>
                    <span className="error-msg">{errors['companyDescription']}</span>
                  </div>

                  <div className="col-lg-6 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Email *</label>
                      <input id="companyEmail" name="companyEmail" type="text" className="form-control"
                          value={companyInfo.companyEmail}
                        onChange={this.onChangeCompanyInfo}
                      />
                    </div>
                    <span className="error-msg">{errors['companyEmail']}</span>
                  </div>

                  <div className="col-lg-6 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Website</label>
                      <input id="website" name="websiteUrl" type="text" className="form-control"
                          value={companyInfo.websiteUrl}
                        onChange={this.onChangeCompanyInfo}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 mb--10 mb-lg-10">
                    <div className="form-group mb-0">
                      <label>Company Type</label>
                      <Select className="form-control"
                        value={companyType}
                        onChange={(e) => this.setState({companyType: e.target.value})}
                        options={companyTypes}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 mb--10 mb-lg-10">
                  <div className="form-group mb-0">
                      <label>Company Category</label>
                      <Select className="form-control" selected={companyCategory}
                        value={companyCategory}
                        onChange={(e) => this.setState({companyCategory: e.target.value})}
                        options={companyCategories}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mb--10 mb-lg-10">
                  <div className="form-group mb-0">
                      <label>Address</label>
                      <input id="locationName" name="locationName" type="text" className="form-control"
                          value={locationInfo.locationName}
                        onChange={this.onChangeLocationInfo}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-12 mb--10 mb-lg-10">
                  <div className="form-group mb-0">
                      <label>Zip Code</label>
                      <input id="zipCode" name="zipCode" type="text" className="form-control"
                          value={locationInfo.zipCode}
                        onChange={this.onChangeLocationInfo}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-12 mb--10 mb-lg-10">
                  <div className="form-group mb-0">
                      <label>City</label>
                      <Select className="form-control" selected={selectedCity}
                        value={selectedCity}
                        onChange={(e) => this.setState({selectedCity: e.target.value})}
                        options={cityList}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-12 mb--10 mb-lg-10">
                  <div className="form-group mb-0">
                      <label>State</label>
                      <Select className="form-control" selected={selectedState}
                        value={selectedState}
                        onChange={(e) => this.setState({selectedState: e.target.value})}
                        options={stateList}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mb--10 mb-lg-10">
                    <div className="form-group">
                      <label>Logo *</label>
                      {companyInfo.companyId !=='' ? 
                        <span>
                          <br/>
                          <img src={`data:${companyInfo.logoType};base64, ${companyInfo.logoContent}`} alt="Company Logo"
                          style={{width:'96px', height:'80px', border:'1px solid gray'}}/>
                        </span>
                        :
                        <FileInput
                        className="form-control-file"
                        onSelect={this.onUploadLogo}
                      />
                      }
                      
                    </div>
                    <span className="error-msg">{errors['companyLogo']}</span>
                  </div>

                  <div className="w-100 text-center mt--20 mb--10 mb-lg-10">
                   {companyInfo.companyId !== '' ? 
                      <span>
                        <button className="btn btn-primary w-25" type="submit"
                          onClick={this.onUpdateCompanyInfo}>Edit</button>
                        <button className="btn btn-outline-primary w-25 ml-2" type="submit"
                        onClick={this.onLoadCompanyInfoIfExist}>Reset</button>
                      </span>
                      : 
                      <span>
                        <button  className="btn btn-primary w-25" type="submit"
                          onClick={this.onSaveCompanyInfo}>Save</button>
                      </span>
                    }
                    
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
        )
    }
}
export default CompanyProfile;
import decode from 'jwt-decode';

class TokenHandler {

    setToken=(token)=>{
        localStorage.setItem('token', JSON.stringify(token));
    }
    getToken=()=>{
        let existingToken = JSON.parse(localStorage.getItem('token'));
        return existingToken;
    }
    
    isLoggedIn(){
        let token=this.getToken();
        if(token==='' || token===null){
            return false;
        }else{
            return true;
        }
    }
    getUserRoles =()=> {
        const token = this.getToken();
        if(token === null){
            return [];
        }
        const accessToken = decode(token.access_token);
        return accessToken.authorities;
    }
    getUsername =()=> {
        const token = this.getToken();
        if(token === null){
            return null;
        }
        const accessToken = decode(token.access_token);
        return accessToken.user_name;
    }
    clearSession=()=>{
        localStorage.setItem('token', null);
        const cloneStore = localStorage.getItem('jobFilter');
        localStorage.clear();
        localStorage.setItem('jobFilter', cloneStore);
    }
}
export default TokenHandler;
import { PublicService } from "../auth/PublicService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class KeywordInfoService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
    }

    getKeywords() {
        let uri = this.baseUriHolder.getPublic() + '/keywords/q';
        return this.publicService.getFetch(uri);
    }
}
import { BaseUriHolder } from "../utils/BaseUriHolder";
import { PublicService } from "../auth/PublicService";

export class SiteConfigService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
    }


    getConfigsByNames(configNames) {
        let uri = this.baseUriHolder.getPublic() + '/site/configs/by/names';
        return this.publicService.postFetch(uri, configNames);
    }

   
}
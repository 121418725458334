import React, { Component } from "react";
import { ResumeInfoService } from "../../service/ResumeInfoService";
import { DateHandler } from "../../utils/DateHandler";

class ResumeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeInfo: {},
      errMessage: null,
    };
    this.resumeInfoService = new ResumeInfoService();
  }
  componentDidMount() {
    const { resumeId } = this.props;
    this.loadResume(resumeId);
  }
  loadResume = (resumeId) => {
    let { resumeInfo, errMessage } = this.state;
    this.resumeInfoService
      .loggedJobSeekerResumeDetails(resumeId)
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            resumeInfo = body;
            this.setState({ resumeInfo });
          });
        } else {
          return res.json().then((err) => {
            errMessage = err.message;
            this.setState({ errMessage });
          });
        }
      });
  };

  render() {
    const { resumeInfo, errMessage } = this.state;
    return (
      <div className="row">
        {errMessage === null ? <div className="col-12 pd0">
        <div className="col-12">
          <h4>{resumeInfo.desireJobTitle}</h4>
        </div>
        <div className="col-6">
          <b>Phone:</b>
          <br />
          <span>{resumeInfo.phoneNo}</span>
        </div>
        <div className="col-6">
          <b>Resume File Name:</b>
          <br />
          {resumeInfo.attachmentName}
        </div>
        <div className="col-6">
          <b>Job Type:</b>
          <br />
          <span>{resumeInfo.jobType}</span>
        </div>
        <div className="col-6">
          <b>Employers can find my resume:</b>
          <br />
          <span>{resumeInfo.resumeFindable ? "Yes" : "No"}</span>
        </div>
        <div className="col-12">
          <b>Job Categories:</b>
          <br />
          <ul className="ul-li-column">
            {resumeInfo.jobCategories &&
              resumeInfo.jobCategories.map((c, i) => {
                return <li key={i}>{c.globalName}</li>;
              })}
          </ul>
        </div>
        <div className="col-12">
          <b>Personal Summary:</b>
          <p
            dangerouslySetInnerHTML={{
              __html: resumeInfo.personalSummary,
            }}
          ></p>
        </div>
        <div className="col-12">
          <h6>Work Experience:</h6>
          {resumeInfo.jobExperiences &&
            resumeInfo.jobExperiences.map((exp, i) => (
              <div className="col-lg-12 mt--10 mt-lg-10 more-info-box" key={i}>
                <b>{exp.position}</b>
                <br />
                <span>{exp.company}</span>
                <p>
                  [{DateHandler.getDateFromStringDDMMYYYY(exp.fromDate)}] - [
                  {exp.currentlyWorking
                    ? "Ongoing"
                    : DateHandler.getDateFromStringDDMMYYYY(exp.toDate)}
                  ]
                </p>
                <span>
                  Description: <br />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: exp.expDescription,
                    }}
                  ></p>
                </span>
              </div>
            ))}
        </div>

        <div className="col-12">
          <h6>Education:</h6>
          {resumeInfo.jobEducations &&
            resumeInfo.jobEducations.map((ed, i) => (
              <div className="col-lg-12 mt--10 mt-lg-10 more-info-box" key={i}>
                <b>{ed.degreeName}</b>
                <br />
                <span>{ed.instituteName}</span>
                <p>
                  [{DateHandler.getDateFromStringDDMMYYYY(ed.fromDate)}] - [
                  {DateHandler.getDateFromStringDDMMYYYY(ed.toDate)}]
                </p>
              </div>
            ))}
        </div>
        </div> : 
        <div className="col-12">
          <h4>{errMessage}</h4>
        </div>}
      </div>
    );
  }
}
export default ResumeDetails;

export class FileHandler {
    constructor () {
        this.fileInfo = null;
    }
  readFileContent(file) {
    var reader = new FileReader();
    
    reader.readAsDataURL(file);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + file.type + ";base64,"; 
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      this.fileInfo = {
        fileType: file.type,
        fileContent: urlStr,
        fileName: file.name,
      };
    };
    
    return this.fileInfo;
  }


  static getLogoSource(val) {
    let lgo = val && val.logoContent !== null ? `data:${val && val.logoType};base64, ${val && val.logoContent}` : `${process.env.PUBLIC_URL}/assets/images/noimage.png`;
    return lgo;
  }
}

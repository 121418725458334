import React, {Component} from 'react';
import ResumeList from './ResumeList';
import NewResume from './NewResume';
import { ResumeInfoService } from '../../service/ResumeInfoService';

class MyResumes extends Component {
    constructor(props){
        super(props);
        this.state={
            // visible:0,
            visible: null,
        }
        this.resumeInfoService = new ResumeInfoService();
    }
    componentDidMount(){
        this.onUpdateVisible();
        
    }

    
    onUpdateVisible=()=>{
        let {visible} = this.state;
        visible=localStorage.getItem('resume');
        this.setState({visible});
    }

    showToast=(severity, details)=>{
        this.props.toast(severity, details);
    }
    render(){
        let CompResume;
        const {visible}=this.state;

        console.log('Resume Status : ', visible);

        // if(visible===null || visible===0){
        if(visible===null || visible==="list"){
            CompResume=<ResumeList handleVisible={this.onUpdateVisible}/>;
        // }else if(visible===1){
        }else if(visible==="new"){
            CompResume=<NewResume handleVisible={this.onUpdateVisible} toast={this.showToast}/>;
        }else if(visible==="edit"){
            CompResume=<div>Edit Resume</div>;
        }
        
        return(
            <div className="row">
                <div className="col-lg-9">
                    {CompResume}
                    </div>
                <div className="col-lg-3">Product</div>

            </div>
        )
    }
}
export default MyResumes;
import React, { Component } from "react";
import { Paginator } from "primereact/paginator";
import { JobInfoService } from "../../service/JobInfoService";
import { JobFilterUtils } from "../../utils/JobFilterUtils";

const city = "City";
class CityWise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityWiseCount: [],
      paginator: {
        page: 0,
        limit: 100,
        totalPage: 0,
        totalFound: 0,
      },
    };

    this.jobInfoService = new JobInfoService();
  }
  componentDidMount() {
    setTimeout(() => { 
      this.loadCityWiseJobsCount(0);
  }, 1500);
    
  }

  loadCityWiseJobsCount = (page) => {
    let { cityWiseCount, paginator } = this.state;
    let paginatorObj = {
      page: page,
      limit: paginator.limit,
      filter: {
        locationType: city,
      },
    };
    this.jobInfoService.countJobsByLocationPublic(paginatorObj).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          cityWiseCount = body.list;
          paginator.totalPage = body.pages;
          paginator.totalFound = body.count;
          this.setState({ cityWiseCount, paginator });
        });
      }
    });
  };
  onChangePaginator = (e) => {
    let { paginator } = this.state;
    paginator.page = e.page * paginator.limit;
    this.setState({ paginator });
    this.loadCityWiseJobsCount(e.page);
  };

  onClickFindJobs = (location) => {   
    
    let searchObj = {
      jobTitle: null,
      address:location,
      selected:true
    }
    JobFilterUtils.updateLocatStore(searchObj);
  
    window.location.replace("/jobs");
  };
  

  render() {
    const { cityWiseCount, paginator } = this.state;
    return (
      <div>
        <div className="city-state-data">
          {cityWiseCount.map((ob, i) => (
                     
            <a href="#" onClick={() => this.onClickFindJobs(ob.name)}>
              <p key={i}>
                {ob.name} (<span>{ob.totalJob}</span>)
              </p>
            </a>
          ))}
        </div>
        <Paginator
          rows={paginator.limit}
          totalRecords={paginator.totalFound}
          totalPages={paginator.totalPage}
          first={paginator.page}
          last={paginator.limit}
          onPageChange={this.onChangePaginator}
          style={{ background: "initial", border: "none" }}
        ></Paginator>
      </div>
    );
  }
}

export default CityWise;

import React, { Component } from "react";
import SectionHeader from "../common/section-header";
import { Helmet } from "react-helmet";

const title = <h2 className="mb--15">Policies</h2>;
const alignment = "section-header text-lg-center pb--40";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
    };
  }
  componentDidMount() {
    fetch("metaData.json").then((res) =>
      res.json().then((data) => {
        this.setState({ metadata: data.privacyPolicy });
      })
    );
  }

  render() {
    const { metadata } = this.state;
    return (
      <section
        id="privacyPolicy"
        className="profile-section bg-ash-color pb--60"
      >
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <div className="container position-relative pt--120 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <SectionHeader title={title} alignment={alignment} />

              <h5>Privacy Policy</h5>
              <p>
                Job Seeker personal data will be available to Employers visiting
                https://Liviaminc.com. Personal data includes a Name which is
                mandatory, an email Address which is mandatory and a Telephone
                Number which is optional.
              </p>
              <p>
                Personal data provided by the user may be used by
                https://Liviaminc.com to notify the user of any news, and or
                promotional offers relating only to the https://Liviaminc.com
                website. The user can unsubscribe from these notifications at
                anytime.
              </p>
              <p>
                https://Liviaminc.com will not disclose user personal data to
                any third party.
              </p>

              <h5 className="mt--20">Cookies Policy</h5>
              <p>Our website uses cookies:</p>
              <p>
                A cookie is a file containing an identifier (a string of letters
                and numbers) that is sent by a web server to a web browser and
                is stored by the browser. The identifier is then sent back to
                the server each time the browser requests a page from the
                server.
              </p>
              <p>We use Google Analytics to analyse the use of our website.</p>
              <p>
                Our analytics service provider generates statistical and other
                information about website use by means of cookies.
              </p>
              <p>
                You can delete cookies already stored on your computer. Please
                visit the 'Help' option in your browser menu to learn how to do
                this. Deleting cookies will have a negative impact on the
                usability of this website.
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </section>
    );
  }
}
export default PrivacyPolicy;

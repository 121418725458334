import { BaseUriHolder } from "../utils/BaseUriHolder";
import { PublicService } from "../auth/PublicService";


export class SignupService {

    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
    }

    forwordVerifyCode(info) {
        let uri = this.baseUriHolder.getSignup()+ '/forword/verify-code';
        return this.publicService.postFetch(uri, info);
    }

    forwordReVerifyCode(info) {
        let uri = this.baseUriHolder.getSignup()+ '/forword/re/verify-code';
        return this.publicService.postFetch(uri, info);
    }

    
    delVerifyInfo(info) {
        let uri = this.baseUriHolder.getSignup()+ '/del/info';
        return this.publicService.postFetch(uri, info);
    }

    verifyCodeWithEmail(email, code) {
        let uri = this.baseUriHolder.getSignup()+ '/verify/code/'+email+'/'+code;
        return this.publicService.getFetch(uri);
    }

    createNewUser(user) {
        let uri = this.baseUriHolder.getSignup();
        return this.publicService.postFetch(uri, user);
    }
}
import React, { Component } from "react";
import Select from "../../common/select";
import FileInput from "../../common/file-input";
import { Editor } from "primereact/editor";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/nova/theme.css";
import "primeicons/primeicons.css";
import { FaPlus, FaTimes } from "react-icons/fa";
import { TextEditorHeader } from "../../common/editor-header";
import { MultiSelect } from "primereact/multiselect";
import { GlobalInfoService } from "../../service/GlobalInfoService";
import { DateHandler } from "../../utils/DateHandler";
import { ResumeInfoService } from "../../service/ResumeInfoService";
import Validation from "../../utils/validationUtil";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";

class NewResume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeInfo: {
        desireJobTitle: "",
        jobCategories: null,
        personalSummary: "",
        resumeFindable: true,
        phoneNo: "",
        attachmentName: "",
        attachmentType: "",
        attachmentContent: [],
        includedAttachment: false,
      },
      jobType: "",
      jobTypes: [],
      jobCategories: [],
      selectedCategories: [],
      jobExperiences: [],
      jobExperience: {
        position: "",
        company: "",
        fromDate: "",
        toDate: "",
        currentlyWorking: true,
        expDescription: "",
      },
      jobEducations: [],
      jobEducation: {
        degreeName: "",
        instituteName: "",
        fromDate: "",
        toDate: "",
      },
      previwDialog: false,
      showProgress:false,
      errors: {},
    };
    this.globalInfoService = new GlobalInfoService();
    this.resumeInfoService = new ResumeInfoService();
  }
  onClickBackResumes = () => {
    localStorage.setItem("resume", "list");
    this.props.handleVisible();
  };

  componentDidMount() {
    this.onLoadJobType();
    this.onLoadJobCategory();
  }
  onLoadJobType = () => {
    let { jobTypes } = this.state;
    jobTypes.push({ label: "Select Job Type", value: 0 });
    this.globalInfoService
      .getGlobalInfosByUseForAndIn("Job", "Type")
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            body.forEach((b) => {
              jobTypes.push({ label: b.globalName, value: b.globalId });
            });
            this.setState({ jobTypes });
          });
        } else {
          jobTypes.push({ label: "Select Job Type", value: 0 });
          this.setState({ jobTypes });
        }
      });
  };

  onLoadJobCategory = () => {
    let { jobCategories } = this.state;
    this.globalInfoService
      .getGlobalInfosByUseForAndIn("Job", "Category")
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            body.forEach((b) => {
              jobCategories.push({
                globalName: b.globalName,
                globalId: b.globalId,
              });
            });
            this.setState({ jobCategories });
          });
        }
      });
  };

  onTextChangePersonalSummary = (e) => {
    let { resumeInfo } = this.state;
    resumeInfo.personalSummary = e.htmlValue;
    this.setState({ resumeInfo });
  };

  onChangeExperience = (e) => {
    let { jobExperience } = this.state;
    jobExperience[e.target.name] = e.target.value;
    this.setState({ jobExperience });
  };

  onChangeExperience_Currently = (e) => {
    let { jobExperience } = this.state;
    jobExperience[e.target.name] = e.checked;
    this.setState({ jobExperience });
  };

  onTextChangeExpDecrp = (e) => {
    let { jobExperience } = this.state;
    jobExperience.expDescription = e.htmlValue;
    this.setState({ jobExperience });
  };

  onChangeEducation = (e) => {
    let { jobEducation } = this.state;
    jobEducation[e.target.name] = e.target.value;
    this.setState({ jobEducation });
  };

  pushAtExperiences = () => {
    let { jobExperience, jobExperiences } = this.state;
    if (!this.validExperience()) {
      return;
    }
    jobExperiences.push(jobExperience);
    jobExperience = {
      position: "",
      company: "",
      fromDate: "",
      toDate: "",
      currentlyWorking: true,
      expDescription: "",
    };
    this.setState({ jobExperience, jobExperiences });
  };

  validExperience = () => {
    let { jobExperience } = this.state;
    let valid = true;
    if (jobExperience.position === "" || jobExperience.company === "") {
      valid = false;
    }
    return valid;
  };

  pushAtEducations = () => {
    let { jobEducation, jobEducations } = this.state;
    if (!this.validEducation()) {
      return;
    }
    jobEducations.push(jobEducation);
    jobEducation = {
      degreeName: "",
      instituteName: "",
      fromDate: "",
      toDate: "",
    };
    this.setState({ jobEducation, jobEducations });
  };
  validEducation = () => {
    let { jobEducation } = this.state;
    let valid = true;
    if (jobEducation.degreeName === "" || jobEducation.instituteName === "") {
      valid = false;
    }
    return valid;
  };

  removeExperience = (e, index) => {
    let { jobExperiences } = this.state;
    jobExperiences.splice(index, 1);
    this.setState({ jobExperiences });
  };

  removeEducation = (e, index) => {
    let { jobEducations } = this.state;
    jobEducations.splice(index, 1);
    this.setState({ jobEducations });
  };

  onChangeResumeInfo = (e) => {
    let { resumeInfo, errors } = this.state;
    if (e.target.name === "resumeFindable") {
      resumeInfo[e.target.name] = e.target.checked;
    } else {
      resumeInfo[e.target.name] = e.target.value;
    }
    errors[e.target.name] = "";
    this.setState({ resumeInfo, errors });
  };

  onUploadAttachement = (e) => {
    let { resumeInfo } = this.state;
    var reader = new FileReader();

    const scope = this;
    reader.readAsDataURL(e);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + e.type + ";base64,";
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      resumeInfo.attachmentName = e.name;
      resumeInfo.attachmentType = e.type;
      resumeInfo.attachmentContent = urlStr;
      resumeInfo.includedAttachment = true;
      scope.setState({ resumeInfo });
    };
  };

  previewResume = () => {
    let {
      resumeInfo,
      jobType,
      selectedCategories,
      jobExperience,
      jobExperiences,
      jobEducation,
      jobEducations,
      previwDialog,
      errors,
    } = this.state;
    if (!this.validateResumeData()) {
      return;
    }
    if (!Validation.isCurrectPhone(resumeInfo.phoneNo)) {
      errors["phoneNo"] = "Invalid phone number";
      return;
    }
    if (jobType === "" || jobType === "undefined") {
      errors["jobType"] = "Select Job Type";
      return;
    }
    if (selectedCategories.length === 0 || selectedCategories === "undefined") {
      errors["jobCategories"] = "Select Job Categories";
      return;
    }
    if (this.validExperience()) {
      jobExperiences.forEach((e) => {
        if (e.position !== jobExperience.position) {
          if(jobExperience.currentlyWorking) {
            jobExperience.toDate = null;
          }
          jobExperiences.push(jobExperience);
        }
      });
      if (jobExperiences.length === 0) {
        if(jobExperience.currentlyWorking) {
          jobExperience.toDate = null;
        }
        jobExperiences.push(jobExperience);
      }
    }
    if (this.validEducation()) {
      jobEducations.forEach((e) => {
        if (e.degreeName !== jobEducation.degreeName) {
          jobEducations.push(jobEducation);
        }
      });
      if (jobEducations.length === 0) {
        jobEducations.push(jobEducation);
      }
    }
    resumeInfo["jobType"] = jobType;
    resumeInfo.jobCategories = selectedCategories;
    resumeInfo["jobExperiences"] = jobExperiences;
    resumeInfo["jobEducations"] = jobEducations;

    previwDialog = true;
    this.setState({ resumeInfo, previwDialog });
    
  };

  onChangeJobType = (e) => {
    let { jobType, errors } = this.state;
    jobType = e.target.value;
    errors["jobType"] = "";
    this.setState({ jobType, errors });
  };

  validateResumeData = () => {
    let isValid = true;
    let { resumeInfo, errors } = this.state;
    if (
      resumeInfo.desireJobTitle === "" ||
      resumeInfo.desireJobTitle === "undefined"
    ) {
      errors["desireJobTitle"] = "Enter Job Title";
      isValid = false;
    }
    if (
      resumeInfo.includedAttachment &&
      (resumeInfo.attachmentContent === "" ||
        resumeInfo.attachmentContent.length === 0)
    ) {
      errors["attachment"] = "Attachment is requried";
      isValid = false;
    }
    if (resumeInfo.phoneNo === "" || resumeInfo.phoneNo === "undefined") {
      errors["phoneNo"] = "Enter phone number";
      isValid = false;
    }
    this.setState({ errors });
    return isValid;
  };

  clearFormData = () => {
    let {
      resumeInfo,
      jobType,
      selectedCategories,
      jobExperience,
      jobExperiences,
      jobEducation,
      jobEducations,
    } = this.state;
    resumeInfo = {
      desireJobTitle: "",
      jobCategories: "",
      personalSummary: "",
      resumeFindable: true,
      phoneNo: "",
      attachmentName: "",
      attachmentType: "",
      attachmentContent: [],
      includedAttachment: false,
    };
    jobType = "";
    selectedCategories = [];
    jobExperience = {
      position: "",
      company: "",
      fromDate: "",
      toDate: "",
      currentlyWorking:true,
      expDescription: "",
    };
    jobExperiences = [];
    jobEducation = {
      degreeName: "",
      instituteName: "",
      fromDate: "",
      toDate: "",
    };
    jobEducations = [];
    this.setState({
      resumeInfo,
      jobType,
      selectedCategories,
      jobExperience,
      jobExperiences,
      jobEducation,
      jobEducations,
    });
  };

  confirmToSaveResume =()=> {
    let {resumeInfo, previwDialog, showProgress} = this.state;

    this.setState({showProgress:true});
    this.resumeInfoService.createNewResume(resumeInfo).then((res) => {
      if (res.status === 201) {
        return res.json().then((body) => {
          this.clearFormData();
          previwDialog = false;
          showProgress = false;
          this.props.toast(1, 'New Resume has successfully added');
          this.setState({previwDialog, showProgress})
        });
      } else {
        return res.json().then((err) => {
          previwDialog = false;
          showProgress = false;
          this.props.toast(4, err.message);
          this.setState({previwDialog, showProgress})
        });
      }
    });
  }
  render() {
    const {
      resumeInfo,
      jobType,
      jobTypes,
      jobCategories,
      jobExperience,
      jobExperiences,
      jobEducation,
      jobEducations,
      previwDialog,
      showProgress,
      errors,
    } = this.state;
    const editorHeader = TextEditorHeader;

    return (
      <div className="col-12">
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="text-right">
          <button
            type="submit"
            className="btn btn-outline-primary"
            onClick={this.onClickBackResumes}
          >
            Back to all resumes
          </button>
        </div>
        <div>
          <div className="profile-info-container-style1 hover-effect-parent1">
            <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
              <div className="content max-width-425">
                <h4 className="font-weight-bold mb--30 mb-lg-30 mt-1">
                  Create New Resume
                </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label>Desired Job Title *</label>
                      <input
                        id="jobTitle"
                        name="desireJobTitle"
                        type="text"
                        className="form-control"
                        value={resumeInfo.desireJobTitle}
                        onChange={this.onChangeResumeInfo}
                      />
                    </div>
                    <span className="error-msg">
                      {errors["desireJobTitle"]}
                    </span>
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Job Type *</label>
                      <Select
                        className="form-control"
                        name="jobType"
                        value={jobType}
                        onChange={this.onChangeJobType}
                        options={jobTypes}
                        
                      />
                    </div>
                    <span className="error-msg">{errors["jobType"]}</span>
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Categories *</label>

                      <MultiSelect
                        value={this.state.selectedCategories}
                        options={jobCategories}
                        onChange={(e) =>
                          this.setState({ selectedCategories: e.value })
                        }
                        optionLabel="globalName"
                        placeholder="Select Categories"
                        filter
                      />
                    </div>
                    <span className="error-msg">{errors["jobCategories"]}</span>
                  </div>
                  <div className="col-lg-12 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Upload Resume</label>
                      <FileInput
                        className="form-control-file"
                        onSelect={this.onUploadAttachement}
                      />
                    </div>
                    <span className="error-msg">{errors["attachment"]}</span>
                  </div>
                  {/* <div className="col-lg-12 mt--10 mt-lg-10">
                        <div class="form-check">
        <input class="form-check-input" type="checkbox" />
        <label class="form-check-label" >
        FREE resume evaluation by a professional resume expert from TopResume
        </label>
      </div>
                        </div> */}
                  <div className="col-lg-12 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Personal Summary</label>
                      <Editor
                        id="personalSummary"
                        headerTemplate={editorHeader}
                        style={{ height: "150px" }}
                        value={resumeInfo.personalSummary}
                        onTextChange={this.onTextChangePersonalSummary}
                      />
                    </div>
                    {/* <span className="error-msg">{errors['pass']}</span> */}
                  </div>
                  <div className="col-lg-12 mt--10 mt_lg-10 mt--30 mt_lg-30">
                    <h5 className="font-weight-bold ">Work Experience</h5>
                  </div>

                  {/* For More Experience */}
                  {jobExperiences.length > 0 &&
                    jobExperiences.map((exp, i) => (
                      <div
                        className="col-lg-12 mt--10 mt-lg-10 more-info-box"
                        key={i}
                      >
                        <h5>{exp.position}</h5>
                        <b>{exp.company}</b>
                        <p>
                          [{DateHandler.getDateFromStringDDMMYYYY(exp.fromDate)}
                          ] - [
                          {exp.currentlyWorking ? 'Ongoing' : DateHandler.getDateFromStringDDMMYYYY(exp.toDate)}]
                        </p>
                        <span>
                          Description: <br />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: exp.expDescription,
                            }}
                          ></p>
                        </span>
                        <span style={{ width: "100%" }}>
                          <FaTimes
                            className="x-btn"
                            title="Remove"
                            onClick={(e) => this.removeExperience(e, i)}
                          />
                        </span>
                      </div>
                    ))}

                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Position</label>
                      <input
                        id="position"
                        name="position"
                        type="text"
                        className="form-control"
                        value={jobExperience.position}
                        onChange={this.onChangeExperience}
                      />
                    </div>
                    {/* <span className="error-msg">{errors['pass']}</span> */}
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Company</label>
                      <input
                        id="company"
                        name="company"
                        type="text"
                        className="form-control"
                        value={jobExperience.company}
                        onChange={this.onChangeExperience}
                      />
                    </div>
                    {/* <span className="error-msg">{errors['pass']}</span> */}
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>From Date</label>
                      <Calendar
                        showIcon
                        name="fromDate"
                        value={jobExperience.fromDate}
                        monthNavigator
                        yearNavigator
                        yearRange={`1970:${new Date().getFullYear()}`}
                        onChange={this.onChangeExperience}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>
                        <Checkbox
                          name="currentlyWorking"
                          onChange={this.onChangeExperience_Currently}
                          checked={jobExperience.currentlyWorking}
                        ></Checkbox>
                        <label className="form-check-label ml--5">
                          {jobExperience.currentlyWorking ? 'Currently Working' : 'To Date'}
                        </label>
                      </label>
                      <Calendar
                      disabled={jobExperience.currentlyWorking}
                        showIcon
                        name="toDate"
                        value={jobExperience.toDate}
                        monthNavigator
                        yearNavigator
                        yearRange={`1970:${new Date().getFullYear()}`}
                        onChange={this.onChangeExperience}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt--10 mt-lg-10">
                    <label>Description</label>
                    <Editor
                      id="expDecrp"
                      headerTemplate={editorHeader}
                      style={{ height: "150px" }}
                      value={jobExperience.expDescription}
                      onTextChange={this.onTextChangeExpDecrp}
                    />
                  </div>
                  <div className="col-lg-12 mt--10 mt-lg-10">
                    <button
                      type="submit"
                      className="btn btn-outline-primary"
                      onClick={this.pushAtExperiences}
                    >
                      <FaPlus /> Add more experience
                    </button>
                  </div>

                  <div className="col-lg-12 mt--10 mt_lg-10 mt--30 mt_lg-30">
                    <h5 className="font-weight-bold ">Education</h5>
                  </div>

                  {/* For More Education */}
                  {jobEducations.length > 0 &&
                    jobEducations.map((ed, i) => (
                      <div
                        className="col-lg-12 mt--10 mt-lg-10 more-info-box"
                        key={i}
                      >
                        <h5>{ed.degreeName}</h5>
                        <b>{ed.instituteName}</b>
                        <p>
                          [{DateHandler.getDateFromStringDDMMYYYY(ed.fromDate)}]
                          - [{DateHandler.getDateFromStringDDMMYYYY(ed.toDate)}]
                        </p>

                        <span style={{ width: "100%" }}>
                          <FaTimes
                            className="x-btn"
                            title="Remove"
                            onClick={(e) => this.removeEducation(e, i)}
                          />
                        </span>
                      </div>
                    ))}

                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Degree or Speciality</label>
                      <input
                        id="degree"
                        name="degreeName"
                        type="text"
                        className="form-control"
                        value={jobEducation.degreeName}
                        onChange={this.onChangeEducation}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>University or Institute</label>
                      <input
                        id="institute"
                        name="instituteName"
                        type="text"
                        className="form-control"
                        value={jobEducation.instituteName}
                        onChange={this.onChangeEducation}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>From Date</label>
                      <Calendar
                        name="fromDate"
                        value={jobEducation.fromDate}
                        showIcon
                        monthNavigator
                        yearNavigator
                        yearRange={`1970:${new Date().getFullYear()}`}
                        onChange={this.onChangeEducation}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>To Date</label>
                      <Calendar
                        name="toDate"
                        value={jobEducation.toDate}
                        showIcon
                        monthNavigator
                        yearNavigator
                        yearRange={`1970:${new Date().getFullYear()}`}
                        onChange={this.onChangeEducation}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mt--10 mt-lg-10">
                    <button
                      type="submit"
                      className="btn btn-outline-primary"
                      onClick={this.pushAtEducations}
                    >
                      <FaPlus /> Add education
                    </button>
                  </div>

                  <div className="col-lg-12 mt--10 mt_lg-10 mt--30 mt_lg-30">
                    {/* <div> */}
                    <Checkbox
                      name="resumeFindable"
                      onChange={this.onChangeResumeInfo}
                      checked={resumeInfo.resumeFindable}
                    ></Checkbox>
                    <label className="form-check-label ml--5">
                      Let Employers find my resume
                    </label>
                    {/* </div> */}
                  </div>

                  <div className="col-lg-12 mt--10 mt-lg-10">
                    <div className="form-group mb-0">
                      <label>Phone *</label>
                      <input
                        id="phone"
                        name="phoneNo"
                        type="text"
                        className="form-control"
                        value={resumeInfo.phoneNo}
                        onChange={this.onChangeResumeInfo}
                      />
                    </div>
                    <span className="error-msg">{errors["phoneNo"]}</span>
                  </div>

                  <div className="w-100 text-center mt--10 mt-lg-10">
                    <button
                      className="btn btn-primary w-50"
                      type="submit"
                      onClick={this.previewResume}
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            header="Resume Preview"
            visible={previwDialog}
            maximizable
            modal
            style={{ width: "50vw", height: "75vh" }}
            footer={
              <div>
                <Button
                  label="Back to Edit"
                  icon="pi pi-arrow-left"
                  onClick={() => this.setState({ previwDialog: false })}
                />
                <Button
                  label="Confirm to Save"
                  icon="pi pi-check"
                  onClick={this.confirmToSaveResume}
                  autoFocus
                />
              </div>
            }
            onHide={() => this.setState({ previwDialog: false })}
          >
            <div className="row">
              <div className="col-12">
                <h4>{resumeInfo.desireJobTitle}</h4>
              </div>
              <div className="col-6">
                <b>Phone:</b>
                <br />
                <span>{resumeInfo.phoneNo}</span>
              </div>
              <div className="col-6">
                <b>Resume File Name:</b>
                <br />
                {resumeInfo.attachmentName}
              </div>
              <div className="col-6">
                <b>Job Type:</b>
                <br />
                {jobTypes.map((j, i) => {
                  if (j.value === resumeInfo.jobType) {
                    return <span key={i}>{j.label}</span>;
                  } else {
                    return null;
                  }
                })}
              </div>
              <div className="col-6">
                <b>Employers can find my resume:</b>
                <br />
                <span>{resumeInfo.resumeFindable ? "Yes" : "No"}</span>
              </div>
              <div className="col-12">
                <b>Job Categories:</b>
                <br />
                <ul className="ul-li-column">
                  {resumeInfo.jobCategories &&
                    resumeInfo.jobCategories.map((c, i) => {
                      return <li key={i}>{c.globalName}</li>;
                    })}
                </ul>
              </div>
              <div className="col-12">
                <b>Personal Summary:</b>
                <p
                  dangerouslySetInnerHTML={{
                    __html: resumeInfo.personalSummary,
                  }}
                ></p>
              </div>
              <div className="col-12">
                <h6>Work Experience:</h6>
                {resumeInfo.jobExperiences &&
                  resumeInfo.jobExperiences.map((exp, i) => (
                    <div
                      className="col-lg-12 mt--10 mt-lg-10 more-info-box"
                      key={i}
                    >
                      <b>{exp.position}</b>
                      <br />
                      <span>{exp.company}</span>
                      <p>
                        [{DateHandler.getDateFromStringDDMMYYYY(exp.fromDate)}]
                        - [{exp.currentlyWorking ? 'Ongoing' : DateHandler.getDateFromStringDDMMYYYY(exp.toDate)}]
                      </p>
                      <span>
                        Description: <br />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: exp.expDescription,
                          }}
                        ></p>
                      </span>
                    </div>
                  ))}
              </div>

              <div className="col-12">
                <h6>Education:</h6>
                {resumeInfo.jobEducations &&
                  resumeInfo.jobEducations.map((ed, i) => (
                    <div
                      className="col-lg-12 mt--10 mt-lg-10 more-info-box"
                      key={i}
                    >
                      <b>{ed.degreeName}</b>
                      <br />
                      <span>{ed.instituteName}</span>
                      <p>
                        [{DateHandler.getDateFromStringDDMMYYYY(ed.fromDate)}] -
                        [{DateHandler.getDateFromStringDDMMYYYY(ed.toDate)}]
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default NewResume;

import React, { Component } from "react";
import SectionHeader from "../../common/section-header";
import { FaAddressCard } from "react-icons/fa";
import { ProductInfoService } from "../../service/ProductInfoService";

const title = <h3 className="mb--15">Prouct Info</h3>;
const subtitle = (
  <h6 className="sub-title" style={{ lineHeight: "normal" }}>
    Select Product to Post New Job
  </h6>
);
const alignment = "section-header text-lg-center pb--40";

class JobProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products : []
        }
        this.productInfoService = new ProductInfoService();
    }

    componentDidMount(){
        this.onLoadProductList();
    }

    onLoadProductList =()=> {
        let {products} = this.state;
        this.productInfoService.getProductList()
        .then(res=> {
            if(res.status === 200) {
                return res.json().then(body=>{
                    products = body;
                    this.setState({products});
                })
            }
        })
    }
  onClickPost = (p) => {
   
    this.props.handleProduct(p);
  };
  render() {
      const {products} = this.state;
    return (
      <div className="container">
        <SectionHeader
          title={title}
          subtitle={subtitle}
          alignment={alignment}
        />
        <div className="row justify-content-center justify-content-lg-start">
          {products.map((p, i) => (
              <div className="col-md-6 col-lg-4 mb--30 mb-lg-0" key={i}>
              <div className="feature-item text-left">
                <div className="icon-box">
                  <div className="icon">
                    <FaAddressCard />
                  </div>
                  <img
                    src="assets/images/feature-dot-shape.png"
                    alt="feature shape"
                  />
                </div>
          <h5>{p.productName}</h5>
          <p>{p.productDescription}</p>
  
          <p>{p.productPrice>0 ? `$${p.productPrice}` : 'FREE'}</p>
                <button
                  type="submit"
                  className="btn btn-outline-primary"
                  onClick={() => this.onClickPost(p)}
                >
                  Post a Job
                </button>
              </div>
            </div>
          ))}
          
        </div>
      </div>
    );
  }
}
export default JobProduct;

import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export class ProgressSpinnerLoader extends Component {
    render() {
        const {showBar} = this.props;
        return(
            <React.Fragment>
                { showBar ?
                    <div className="p-spiner-overlay">
                        <ProgressSpinner className="p-spiner" strokeWidth="5"/>
                    </div>
                    : null
                }
            </React.Fragment>
        )
    }
}
import React, { Component } from "react";
import { FavoriteJobService } from "../../service/FavoriteJobService";
import { DateHandler } from "../../utils/DateHandler";

class SavedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteJobs: [],
    };
    this.favoriteJobService = new FavoriteJobService();
  }

  componentDidMount() {
    let { favoriteJobs } = this.state;
    this.favoriteJobService.findFavoriteJobListByUser().then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          favoriteJobs = body;
          this.setState({ favoriteJobs });
        });
      }
    });
  }
  render() {
    const { favoriteJobs } = this.state;
    return (
      <div className="row">
        {favoriteJobs.length > 0 ? (
          <div className="col-12">
            <div className="tab-header card p-1 mb--5">
              <span className="tab-header-text">
                Saved [{favoriteJobs.length}] Jobs
              </span>
            </div>

            {favoriteJobs.map((val, i) => (
              <div className="col-md-12 col-lg-12 p-0" key={i}>
                <div
                  className="liviam-pricing-item mb--10 mb_lg--0"
                  style={{ padding: "5px 10px 5px 10px" }}
                >
                  <div className="pricing-head">
                    <div className="category-type">
                      <span>{val.jobInfo.jobTitle}</span>
                    </div>
                  </div>
                  <div className="pricing-body" style={{ padding: "6px 18px" }}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: val.jobInfo.jobDescription,
                      }}
                    ></span>
                    <p className="hidden-charge row">
                      <div className="col-sm-6">
                        <span className="mr--10" style={{ color: "#273167" }}>
                          {val.jobInfo.jobCode}
                        </span>
                        <span style={{ color: "#882222" }}>
                          ${val.jobInfo.jobOfferingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </div>
                      <div className="col-sm-6 right-grid">
                        <span>
                          {DateHandler.getDateFromStringAnyFormat(
                            val.jobInfo.jobExpireDate,
                            "DD MMM, YYYY"
                          )}
                        </span>
                        <span>{val.jobInfo.jobStatus}</span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="alert alert-warning">
            <h5 className="alert-heading">Empty!</h5>
            You have no saved jobs so far. Find your desire jobs
          </div>
        )}
      </div>
    );
  }
}
export default SavedJobs;

import { PublicService } from "../auth/PublicService";
import { BaseUriHolder } from "../utils/BaseUriHolder";

export class AddressInfoService {
    constructor() {
        this.baseUriHolder = new BaseUriHolder();
        this.publicService = new PublicService();
    }

    getAllAddress(){
        let uri = this.baseUriHolder.getPublic() + '/addresses/q';
        return this.publicService.getFetch(uri);
    }

    getAllAddressByType(type){
        let uri = this.baseUriHolder.getPublic() + '/addresses/q?type='+type;
        return this.publicService.getFetch(uri);
    }
}

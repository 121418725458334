import React, { Component } from "react";
import { Link } from "react-router-dom";

class EmployerDenier extends Component {
  render() {
    
    return (
        <section id="pageNotFound">
        <div className="container pt--100 pt_lg--120 ">
          <div id="pagenotfound" className="row">
            <div className="pagenotfound ">
              <div className="notfound-404"></div>
              <h1>403</h1>
              <h2>OOPS! ACCESS DENIED</h2>
              <p>
                Sorry, to post a job, requires an Employer account. 
              </p>
              <p><Link to="/signup">Create an Employer Account</Link> or <Link to="/signin">Sign In as Employer</Link></p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default EmployerDenier;


export class BaseUriHolder {
    
    getHost() {
        const HOST = process.env.REACT_APP_API_HOST_SERVER;
        return HOST;
    }

    getPublic() {
        return this.getHost()+'/public';
    }
    getSignup() {
        return this.getPublic()+'/signup'
    }

    getJobSeeker() {
        return this.getHost()+'/jobseeker'
    }

    getEmployer() {
        return this.getHost()+'/employer'
    }


    getUser() {
        return this.getHost()+'/user'
    }
}
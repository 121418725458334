import React, { Component } from "react";
import JobProduct from "./JobProduct";
import Select from "../../common/select";
import { Editor } from "primereact/editor";
import { Dialog } from 'primereact/dialog';
import { RadioButton } from "primereact/radiobutton";
import { Button } from 'primereact/button';
import { TextEditorHeader } from "../../common/editor-header";
import { Calendar } from "primereact/calendar";
import { GlobalInfoService } from "../../service/GlobalInfoService";
import { MultiSelect } from "primereact/multiselect";
import { FaDollarSign, FaMapMarkerAlt, FaTextHeight, FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CompanyInfoService } from "../../service/CompanyInfoService";
import { DateHandler } from "../../utils/DateHandler";
import { JobInfoService } from "../../service/JobInfoService";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";


class NewJobPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobProduct: null,
      jobType: "",
      jobTypes: [],
      jobCategories: [],
      selectedCategories: [],
      jobInfo: {
        jobTitle: "",
        jobDescription: "",
        jobLocation: "",
        jobApplyingWay: "",
        jobApplyingWayValue: "",
        numberOfPosition: "",
        jobPublishDate: "",
        jobExpireDate: "",
        jobOfferingAmount: "",
        byEmail: false,
        companyInfoDto: null
      },
      previwDialog: false,
      showProgress:false,
      errors: {},
    };
    this.globalInfoService = new GlobalInfoService();
    this.companyInfoService = new CompanyInfoService();
    this.jobInfoService = new JobInfoService();
  }

  componentDidMount() {
    this.onLoadCompanyInfo();
    this.onLoadJobType();
    this.onLoadJobCategory();
  }

  onLoadCompanyInfo = () => {
    let { jobInfo } = this.state;
    this.companyInfoService.loggedEmployerCompany().then((res) => {
      if (res.status === 302) {
        return res.json().then((body) => {
          let companyInfo = body;
          companyInfo.logoType = "image/png";
          jobInfo.companyInfoDto = companyInfo;
          this.setState({ jobInfo });
        });
      }
    });
  }

  onLoadJobType = () => {
    let { jobTypes } = this.state;
    jobTypes.push({ label: "Select Job Type", value: 0 });
    this.globalInfoService
      .getGlobalInfosByUseForAndIn("Job", "Type")
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            body.forEach((b) => {
              jobTypes.push({ label: b.globalName, value: b.globalId });
            });
            this.setState({ jobTypes });
          });
        } else {
          this.setState({ jobTypes });
        }
      });
  };

  onLoadJobCategory = () => {
    let { jobCategories } = this.state;
    this.globalInfoService
      .getGlobalInfosByUseForAndIn("Job", "Category")
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            body.forEach((b) => {
              jobCategories.push({
                globalName: b.globalName,
                globalId: b.globalId,
              });
            });
            this.setState({ jobCategories });
          });
        }
      });
  };
  onClickBackJobs = () => {
    localStorage.setItem("jobPost", "list");
    // localStorage.setItem("jobPost", 0);
    this.props.handleVisible();
  };
  onClickProduct = (obj) => {
    let { jobProduct } = this.state;
    jobProduct = obj;
    this.setState({ jobProduct });
  };

  onChangeJobInfo = (e) => {
    let { jobInfo, errors } = this.state;
    jobInfo[e.target.name] = e.target.value;
    console.log("Selected Name :", e.target.name);
    console.log("Selected Valu :", e.target.value);
    errors[e.target.name] = "";
    if (e.target.name === "byEmailInput" || e.target.name === "byUrlInput") {
      jobInfo.jobApplyingWayValue = e.target.value;
    }
    this.setState({ jobInfo, errors });
  };

  onTextChangeJobDecrp = (e) => {
    let { jobInfo, errors } = this.state;
    jobInfo.jobDescription = e.htmlValue;
    errors["jobDescription"] = "";
    this.setState({ jobInfo, errors });
  };

  onChangeRadio = (e) => {
    let { jobInfo, errors } = this.state;
    if (e.value === "byEmail") {
      jobInfo.byEmail = true;
      jobInfo["byUrl"] = false;
    } else if (e.value === "byUrl") {
      jobInfo.byEmail = false;
      jobInfo["byUrl"] = true;
    }
    jobInfo.jobApplyingWay = e.value;

    // console.log("Selected Value :", jobInfo.jobApplyingWay);
    errors["applyingWay"] = "";
    this.setState({ jobInfo, errors });
  };

  onChangeJobType = (e) => {
    let { jobType, errors } = this.state;
    jobType = e.target.value;
    errors["jobType"] = "";
    this.setState({ jobType, errors });
  };

  onClickJobPreview = () => {
    // console.log("Preview Button is Clicked ");
    let {
      jobInfo,
      jobType,
      selectedCategories,
      jobProduct,
      previwDialog,
    } = this.state;
    
    if (!this.validateJobInfo()) {
      console.log("Preview Button is Clicked ");
      return;
    }

    jobInfo["jobType"] = { globalId: jobType };
    jobInfo["jobCategories"] = selectedCategories;
    jobInfo["productInfoDto"] = jobProduct;

    previwDialog = true;
    this.setState({ jobInfo, previwDialog });
  };

  confirmToSave = () => {
    let { jobInfo, previwDialog, showProgress, errors } = this.state;

    previwDialog = false;
    this.setState({showProgress:true});
    this.jobInfoService.postNewJob(jobInfo).then((res) => {
      if (res.status === 201) {
        return res.json().then((body) => {
          this.clearSubmitForm();
          this.props.toast(1, 'New Job has successfully saved');
          showProgress = false;
          this.setState({ previwDialog, showProgress, errors });
          this.onLoadCompanyInfo();
        });
      } else {
        return res.json().then((err) => {
          this.props.toast(4, err.message);
          showProgress = false;
          this.setState({ previwDialog, showProgress, errors });
        });
      }
    });
  };

  clearSubmitForm=()=> {
    let {jobInfo, jobType, selectedCategories} = this.state;
    jobInfo = {
      jobTitle: "",
        jobDescription: "",
        jobLocation: "",
        jobApplyingWay: "",
        jobApplyingWayValue: "",
        jobPublishDate: "",
        jobExpireDate: "",
        jobOfferingAmount: "",
        numberOfPosition: "",
        byEmail: true,
        companyInfoDto: null
    }
    jobType = '';
    selectedCategories = [];
    this.setState({jobInfo, jobType, selectedCategories})
  }

  validateJobInfo = () => {
    let { jobInfo, jobType, selectedCategories, errors } = this.state;
    let val = true;
    if (jobInfo.jobTitle === "" || jobInfo.jobTitle === undefined) {
      errors["jobTitle"] = "Enter Job Title";
      val = false;
    }
    if (jobInfo.jobDescription === "" || jobInfo.jobDescription === undefined) {
      errors["jobDescription"] = "Enter Job Description";
      val = false;
    }
    if (jobInfo.jobApplyingWay === "" || jobInfo.jobApplyingWay === undefined) {
      errors["applyingWay"] = "Check any one from those type";
      val = false;
    }
    // if (
    //   jobInfo.jobApplyingWayValue === "" ||
    //   jobInfo.jobApplyingWayValue === undefined
    // ) {
    //   errors[jobInfo.jobApplyingWay] = "Enter a valid address";
    //   val = false;
    // }
    if (jobInfo.jobExpireDate === "" || jobInfo.jobExpireDate === undefined) {
      errors["jobExpireDate"] = "Select a expire date";
      val = false;
    }
    if (jobType === "" || jobType === "undefined") {
      errors["jobType"] = "Select Job Type";
      val = false;
    }
    
    if (selectedCategories.length === 0 || selectedCategories === "undefined") {
      errors["jobCategories"] = "Select Job Categories";
      val = false;
    }
    this.setState({ errors });
    return val;
  };

  renderDialogFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.onHide()}
          className="p-button-text"
        ></Button>
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.onHide()}
          autoFocus
        />
      </div>
    );
  };
  render() {
    const {
      jobInfo,
      jobProduct,
      jobType,
      jobTypes,
      jobCategories,
      previwDialog,
      showProgress,
      errors,
    } = this.state;

    return (
      <div>
        
        {jobProduct === null ? (
          <JobProduct handleProduct={this.onClickProduct} />
        ) : (
          <div className="row">
            <ProgressSpinnerLoader showBar={showProgress} />
            <div className="col-9">
              <div className="tab-header card pl-1 mb--5">
                <span className="tab-header-text">New Job</span>
                <div className="text-right">
                  <button
                    type="submit"
                    className="btn btn-outline-primary"
                    onClick={this.onClickBackJobs}
                    style={{ padding: "revert" }}
                  >
                    Back to all jobs
                  </button>
                </div>
              </div>
              <div>
                <div className="profile-info-container-style1 hover-effect-parent1">
                  <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
                    <div className="content max-width-425">
                      <h4 className="font-weight-bold mb--30 mb-lg-30 mt-1">
                        Post New Job
                      </h4>
                      <div className="row">
                        <div className="col-lg-12 mb--10 mb-lg-10">
                          <div className="form-group">
                            <label>Job Title *</label>
                            <input
                              id="jobTitle"
                              name="jobTitle"
                              type="text"
                              className="form-control"
                              value={jobInfo.jobTitle}
                              onChange={this.onChangeJobInfo}
                            />
                          </div>
                          <span className="error-msg">
                            {errors["jobTitle"]}
                          </span>
                        </div>

                        <div className="col-lg-6 mb--10 mb-lg-10">
                          <div className="form-group">
                            <label>Job Type *</label>
                            <Select
                              className="form-control"
                              name="jobType"
                              value={jobType}
                              onChange={this.onChangeJobType}
                              options={jobTypes}
                            />
                          </div>
                          <span className="error-msg">{errors["jobType"]}</span>
                        </div>

                        <div className="col-lg-6 mb--10 mb-lg-10">
                          <div className="form-group">
                            <label>Categories *</label>
                            <MultiSelect
                              value={this.state.selectedCategories}
                              options={jobCategories}
                              onChange={(e) =>
                                this.setState({ selectedCategories: e.value })
                              }
                              optionLabel="globalName"
                              placeholder="Select Categories"
                              filter
                            />
                          </div>
                          <span className="error-msg">
                            {errors["jobCategories"]}
                          </span>
                        </div>

                        <div className="col-6 mb--10 mb-lg-10">
                          <label>Offering Salary</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaDollarSign />
                              </span>
                            </div>

                            <input
                              id="jobOfferingAmount"
                              type="text"
                              name="jobOfferingAmount"
                              className="form-control"
                              value={jobInfo.jobOfferingAmount}
                              onChange={this.onChangeJobInfo}
                            />
                          </div>
                          <span className="error-msg">
                            {errors["jobOfferingAmount"]}
                          </span>
                        </div>

                        <div className="col-6 mb--10 mb-lg-10">
                          <label>Number of Position</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaUsers />
                              </span>
                            </div>

                            <input
                              id="numberOfPosition"
                              type="text"
                              name="numberOfPosition"
                              className="form-control"
                              value={jobInfo.numberOfPosition}
                              onChange={this.onChangeJobInfo}
                            ></input>
                          </div>
                          <span className="error-msg">
                            {errors["numberOfPosition"]}
                          </span>
                        </div>

                        <div className="col-lg-12 mb--10 mb-lg-10">
                          <div className="form-group">
                            <label>Job Description *</label>
                            <Editor
                              headerTemplate={TextEditorHeader}
                              style={{ height: "200px" }}
                              value={jobInfo.jobDescription}
                              onTextChange={this.onTextChangeJobDecrp}
                            />
                          </div>
                          <span className="error-msg">
                            {errors["jobDescription"]}
                          </span>
                        </div>

                        <div className="col-lg-12 mb--10 mb-lg-10">
                          <label>Location</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FaMapMarkerAlt />
                              </span>
                            </div>
                            <input
                              id="location"
                              type="text"
                              name="jobLocation"
                              className="form-control"
                              value={jobInfo.jobLocation}
                              onChange={this.onChangeJobInfo}
                            />
                          </div>
                          <span className="error-msg">
                            {errors["jobLocation"]}
                          </span>
                        </div>

                        <div className="col-lg-12 mb--10 mb-lg-10">
                          <span className="font-weight-bold mb--10 mb-lg-10 mt--10 mt_lg-10">
                            How to Apply
                          </span>
                        </div>

                        <div className="col-lg-6 mb--10 mb-lg-10">
                          <div className="form-group">
                            <RadioButton
                              name="howToApply"
                              value="byEmail"
                              onChange={this.onChangeRadio}
                              checked={jobInfo.byEmail}
                            />
                            <label style={{ marginLeft: "10px" }}>
                              By Email
                            </label>
                            <input
                              id="byEmailInput"
                              name="byEmailInput"
                              type="text"
                              disabled={jobInfo.byUrl}
                              className="form-control"
                              value={jobInfo.byEmailInput}
                              placeholder={jobInfo.companyInfoDto && jobInfo.companyInfoDto.companyEmail}
                              onChange={this.onChangeJobInfo}
                            />
                          </div>
                          <span className="error-msg">
                            {errors["byEmailInput"]}
                          </span>
                        </div>

                        <div className="col-lg-6 mb--10 mb-lg-10">
                          <div className="form-group">
                            <RadioButton
                              name="howToApply"
                              value="byUrl"
                              onChange={this.onChangeRadio}
                              checked={jobInfo.byUrl}
                            />
                            <label style={{ marginLeft: "10px" }}>By URL</label>

                            <input
                              id="byUrlInput"
                              name="byUrlInput"
                              type="text"
                              disabled={jobInfo.byEmail}
                              className="form-control"
                              value={jobInfo.byUrlInput}
                              placeholder={jobInfo.companyInfoDto && jobInfo.companyInfoDto.websiteUrl}
                              onChange={this.onChangeJobInfo}
                            />
                          </div>
                          <span className="error-msg">
                            {errors["byUrlInput"]}
                          </span>
                        </div>

                        <div className="col-lg-6 mb--10 mb-lg-10">
                          <div className="form-group">
                            <label>Publish Date *</label>
                            <Calendar
                              showIcon
                              name="jobPublishDate"
                              value={jobInfo.jobPublishDate}
                              onChange={this.onChangeJobInfo}
                              monthNavigator
                            />
                          </div>
                          <span className="error-msg">
                            {errors["jobPublishDate"]}
                          </span>
                        </div>

                        <div className="col-lg-6 mb--10 mb-lg-10">
                          <div className="form-group">
                            <label>Expiration Date *</label>
                            <Calendar
                              showIcon
                              name="jobExpireDate"
                              value={jobInfo.jobExpireDate}
                              onChange={this.onChangeJobInfo}
                              monthNavigator
                            />
                          </div>
                          <span className="error-msg">
                            {errors["jobExpireDate"]}
                          </span>
                        </div>

                        <div className="w-100 text-center mb--10 mb-lg-10">
                          <button
                            className="btn btn-primary w-50"
                            type="submit"
                            onClick={this.onClickJobPreview}
                          >
                            Preview
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* product */}
            <div className="col-3">
              <div className="card">
                <div className="card-header">Product Info</div>
                <div className="card-body">
                  <b>{jobProduct.productName}</b>
                  <br />
                  {jobProduct.productDescription}
                </div>
                <div className="card-footer">
                  {jobProduct.productPrice > 0
                    ? `$${jobProduct.productPrice}`
                    : "FREE"}
                </div>
              </div>
            </div>
          </div>
        )}
        <Dialog
          header="Job Preview"
          visible={previwDialog}
          maximizable
          modal
          style={{ width: "50vw", maxHeight:'75vh'}}
          footer={
            <div>
              <Button
                label="Back to Edit"
                icon="pi pi-arrow-left"
                onClick={() => this.setState({ previwDialog: false })}
              />
              <Button
                label="Confirm to Save"
                icon="pi pi-check"
                onClick={this.confirmToSave}
                autoFocus
              />
            </div>
          }
          onHide={() => this.setState({ previwDialog: false })}
        >
          <div className="job-details" style={{boxShadow:'none'}}>
            <div className="col-12 pd0" style={{ display: "flex" }}>
              <span>
                <img
                  src={`data:${
                    jobInfo.companyInfoDto && jobInfo.companyInfoDto.logoType
                  };base64, ${
                    jobInfo.companyInfoDto && jobInfo.companyInfoDto.logoContent
                  }`}
                  alt="Company Logo"
                />
                <br />
              </span>
              <span>
                <Link to="/" title="Company Details" className="company">
                  {jobInfo.companyInfoDto && jobInfo.companyInfoDto.companyName}
                </Link>
                <p style={{ margin: "0px" }}>{jobInfo.jobLocation}</p>
              </span>
            </div>
            <div className="col-12 pd0 job-title">
              <p>{jobInfo.jobTitle}</p>
              <span className="tags">
                <span>
                  <FaTextHeight />{" "}
                  {jobTypes.map((j, i) => {
                    if(jobInfo.jobType && j.value === jobInfo.jobType.globalId) {
                      return (<span>{j.label}</span>);
                    } else {
                      return null;
                    }
                  })}
                </span>
                <span>
                  <FaDollarSign /> {jobInfo.jobOfferingAmount}
                </span>
                <span>
                  <FaUsers /> {jobInfo.numberOfPosition}
                </span>
                
              </span>
              <span className="tags">
                <span>
                  Published: 
                  {DateHandler.getDateFromStringAnyFormat(
                    jobInfo.jobPublishDate,
                    "DD MMM, YYYY"
                  )}
                </span>
                <span>
                  Expiration: 
                  {DateHandler.getDateFromStringAnyFormat(
                    jobInfo.jobExpireDate,
                    "DD MMM, YYYY"
                  )}
                </span>
              </span>
              
            </div>
            <div className="col-12 pd0">
            <p>Location: <br/>
              {jobInfo.jobLocation}
              </p>
              {/* <p>Categories: <br/> */}
              <span>Categories:</span>
              <ul className="ul-li-column">
                {jobInfo.jobCategories && jobInfo.jobCategories.map((c, i) => {
                  return (<li key={i}>{c.globalName}</li>);
                })}
              </ul>
              {/* <ul className="ul-li-column">
                {jobInfo.jobCategories && jobInfo.jobCategories.map((c, i) => {
                  return (<li key={i}>{c.globalName}</li>);
                })}
              </ul> */}
              {/* </p> */}
            </div>
            <div className="col-12 pd0">
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{ __html: jobInfo.jobDescription }}
                ></p>
              </div>
            </div>
            <div className="col-12 pd0">
              <span>
                Apply To: <b>{jobInfo.jobApplyingWayValue}</b>
              </span>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default NewJobPost;

import React, { Component } from "react";
import { Link } from "react-router-dom";

class PageNotFound extends Component {
  render() {
    return (
      <section id="pageNotFound">
        <div className="container pt--100 pt_lg--120 ">
          <div id="pagenotfound" className="row">
            <div className="pagenotfound ">
              <div className="notfound-404"></div>
              <h1>404</h1>
              <h2>OOPS! PAGE NOT BE FOUND</h2>
              <p>
                Sorry but the page you are looking for does not exist, have been
                removed. name changed or is temporarily unavailable
              </p>
              <Link to="/">Back to Home</Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default PageNotFound;

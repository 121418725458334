import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { FaDollarSign, FaSync, FaTextHeight, FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";
import { JobInfoService } from "../../service/JobInfoService";
import { DateHandler } from "../../utils/DateHandler";
import { JOB_STATUS } from "../../utils/StatusConstants";

class PostedJobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDetails : {},
      showProgress : false,
      previwDialog :false
    };
    this.jobInfoService = new JobInfoService();
  }
  onClickPostJob = () => {
    localStorage.setItem("jobPost", "new");
    // localStorage.setItem("jobPost", 1);
    this.props.handleVisible();
  };

  getJobStatus = (statusCode) => {
    let status = "UNKNOWN";
    if (statusCode === JOB_STATUS.ACTIVE) {
      status = "ACTIVE";
    } else if (statusCode === JOB_STATUS.DEACTIVE) {
      status = "DEACTIVE";
    }
    return <span className={status}>{status}</span>;
  };

  viewJobDetails=(jobId, code)=> {
    let {jobDetails, showProgress} = this.state;
    const {company} = this.props;

    let filter = {
      jobId : jobId,
      jobCode : code,
      companyId : company.companyId
    }
    this.setState({showProgress:true, previwDialog:true});
    this.jobInfoService.findJobDetailsForPublic(filter)
    .then(res => {
      if(res.status === 302) {
        return res.json().then(body => {
          jobDetails = body;
          showProgress = false;
          this.setState({jobDetails, showProgress});
        })
      } else {
        return res.json().then(err => {
          jobDetails = {};
          showProgress = false;
          this.setState({jobDetails, showProgress});
        })
      }
    })

  }
  editJobDetails =(jobId, code)=> {
    localStorage.setItem("jobPost", "edit");
    // localStorage.setItem("jobPost", 2);
    this.props.handleVisible();
    this.props.onUpdateJob(jobId, code);
  }
  render() {
    const { jobList } = this.props;
    const { jobDetails, previwDialog, showProgress } = this.state;
    return (
      <div className="row">
        <ProgressSpinnerLoader showBar={showProgress} />
      <div className="col-12">
        <div className="tab-header card pl-1 mb--5">
          <span className="tab-header-text">Job List</span>
          <div className="text-right">
            <button
              type="submit"
              className="btn btn-outline-primary"
              title="Refresh List"
              onClick={() => this.props.reloadJobList()}
              style={{ padding: "revert" }}
            >
              <FaSync/>
            </button>
            <button
              type="submit"
              className="btn btn-outline-primary"
              onClick={this.onClickPostJob}
              style={{ padding: "revert" }}
            >
              Post a Job
            </button>
          </div>
        </div>

        {jobList.length > 0 ? (
          jobList.map((val, i) => (
            <div className="col-md-12 col-lg-12 p-0" key={i}>
              <div
                className="liviam-pricing-item mb--10 mb_lg--0"
                style={{ padding: "5px 10px 5px 10px" }}
              >
                <div className="pricing-head">
                  <div className="category-type">
                    <span>{val.jobTitle}</span>
                  </div>
                  <div
                    className="price row"
                    style={{ fontSize: "20px", fontWeight: "400" }}
                  >
                    <span className="col-sm-6">${val.jobOfferingAmount}</span>
                    <span
                      className="per-time col-sm-6"
                      style={{ marginLeft: "50px", fontSize: "16px" }}
                    >
                      Code: {val.jobCode}
                    </span>
                  </div>
                  <p className="free-trial-content">
                    Applying Way: {val.jobApplyingWayValue}
                  </p>
                </div>
                <div className="pricing-body" style={{ padding: "6px 18px" }}>
                  <p className="hidden-charge row">
                    <span className="col-sm-6">
                      Expire At:{" "}
                      {DateHandler.getDateFromStringAnyFormat(
                        val.jobExpireDate,
                        "DD MMMM, YYYY"
                      )}
                    </span>
                    <span className="col-sm-6 right-grid">
                      {this.getJobStatus(val.jobStatus)}
                      <span className="btn-type" 
                      onClick={() => this.editJobDetails(val.jobId, val.jobCode)}>Edit</span>
                      <span className="btn-type" 
                      onClick={() => this.viewJobDetails(val.jobId, val.jobCode)}>View</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="alert alert-warning">
            <h5 className="alert-heading">Empty!</h5>
            You have no Jobs so far. Post new job
          </div>
        )}
      </div>

      <Dialog
          header="Job Details"
          visible={previwDialog}
          maximizable
          modal
          style={{ width: "50vw", maxHeight:'75vh'}}
          footer={
            <div>
              <Button
                label="Close"
                icon="pi pi-times"
                onClick={() => this.setState({ previwDialog: false })}
              />
             
            </div>
          }
          onHide={() => this.setState({ previwDialog: false })}
        >
          {jobDetails && 
          <div className="job-details" style={{boxShadow:'none'}}>
            <div className="col-12 pd0" style={{ display: "flex" }}>
              <span>
                <img
                  src={`data:${
                    jobDetails.company && jobDetails.company.logoType
                  };base64, ${
                    jobDetails.company && jobDetails.company.logoContent
                  }`}
                  alt="Company Logo"
                />
                <br />
              </span>
              <span>
                <Link to="/" title="Company Details" className="company">
                  {jobDetails.company && jobDetails.company.companyName}
                </Link>
                {/* <p style={{ margin: "0px" }}>{jobDetails.jobLocation}</p> */}
              </span>
            </div>
            <div className="col-12 pd0 job-title">
              <p>{jobDetails.jobTitle}</p>
              <span className="tags">
                <span>
                  <FaTextHeight />{" "}<span>{jobDetails.jobType}</span>
                </span>
                <span>
                  <FaDollarSign /> {jobDetails.jobOfferingAmount}
                </span>
                <span>
                  <FaUsers /> {jobDetails.numberOfPosition}
                </span>
                
              </span>
              <span className="tags">
                <span>
                  Published: 
                  {DateHandler.getDateFromStringAnyFormat(
                    jobDetails.jobPublishDate,
                    "DD MMM, YYYY"
                  )}
                </span>
                <span>
                  Expiration: 
                  {DateHandler.getDateFromStringAnyFormat(
                    jobDetails.jobExpireDate,
                    "DD MMM, YYYY"
                  )}
                </span>
              </span>
              
            </div>
            <div className="col-12 pd0">
            <p>Location: <br/>
              {jobDetails.jobLocation}
              </p>
              <p>Categories: <br/>
              <ul className="ul-li-column">
                {jobDetails.jobCategories && jobDetails.jobCategories.map((c, i) => {
                  return (<li key={i}>{c}</li>);
                })}
              </ul>
              </p>
            </div>
            <div className="col-12 pd0">
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{ __html: jobDetails.jobDescription }}
                ></p>
              </div>
            </div>
            <div className="col-12 pd0">
              <span>
                Apply To: <b>{jobDetails.jobApplyingWayValue}</b>
              </span>
            </div>
          </div>
  }
  {jobDetails === null && <div>No data found</div>}
        </Dialog>
      </div>
    );
  }
}
export default PostedJobList;

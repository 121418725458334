import React, { Component } from "react";
import { FaRegClock, FaAlignJustify } from "react-icons/fa";
import { JobInfoService } from "../../service/JobInfoService";

class CompanyJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: null,
      jobList: [],
    };
    this.jobInfoService = new JobInfoService();
  }

  componentDidMount() {
    this.onLoadJobList();
  }
  onLoadJobList = () => {
    let { jobList, companyId } = this.state;
    const { company } = this.props;
    if (!company) {
      return;
    }
    this.jobInfoService.findJobsByCompanyForPublic(company).then((res) => {
      if (res.status === 200) {
        return res.json().then((body) => {
          jobList = body;
          companyId = company;
          this.setState({ jobList, companyId });
        });
      }
    });
  };
  onClickJob = (job) => {
    const { companyId } = this.state;
    let jobDetailState = {
      selectedJob: {
        jobId: job.jobId,
        jobCode: job.jobCode,
        companyId: companyId,
      },
    };
    this.props.history.push("/job/details", jobDetailState);
  };
  render() {
    const { jobList } = this.state;
    return (
      <div className="col-12 pt--20 pt_lg--20 pd0">
        {jobList.map((job, i) => {
          return (
            <div
              className="col-md-12 mb--30 mb-lg-0 pd0"
              key={i}
              onClick={() => this.onClickJob(job)}
            >
              <div className="job-item text-lg-left">
                <div className="job-content">
                  <h5 className="title" style={{ margin: "0px" }}>
                    {job.jobTitle}
                  </h5>
                  <p>${job.jobOfferingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                  <small
                    dangerouslySetInnerHTML={{ __html: job.jobDescription }}
                  ></small>
                  <div className="meta-job list-unstyled pl-0 d-flex justify-content-between">
                    <div>
                      <span className="icon">
                        <FaRegClock />
                      </span>
                      <span className="meta-content">Live Now</span>
                    </div>
                    <div>
                    <span className="icon">
                        <FaAlignJustify />
                      </span>
                      <span className="meta-content" style={{cursor:'pointer'}}>
                        Details
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default CompanyJobs;

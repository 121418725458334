import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './auth/PrivateRoute';
import { AuthContext } from "./auth/auth";
import TokenHandler from './auth/TokenHandler';
import './App.css';
import Header from './template/Header';
import Footer from './template/footer-section';
import Home from './components/home/Home';
// import PricingSection from './components/price/pricing-section';
import FaqSection from './components/faq/faq-section';
import FindJobs from './components/findJob/FindJobs';
import JobDetails from './components/findJob/JobDetails';
import JobDetailsDesktopUI from './components/findJob/JobDetailsDesktopUI';
import FindCompanies from './components/companies/FindCompanies';
import CompanyDetails from './components/companies/CompanyDetails';
import Signup from './components/sign/up/Signup';
import Signin from './components/sign/Signin';
import ContactUs from './components/contact/ContactUs';
import ProfileLayout from './components/jobSeeker/ProfileLayout';
import PageNotFound from './PageNotFound';
import EmployerLayout from './components/employer/EmployerLayout';
import SeekerDenier from './components/jobSeeker/SeekerDenier';
import EmployerDenier from './components/employer/EmployerDenier';
import JobApply from './components/findJob/JobApply';
import AboutUs from './components/AboutUs';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import ForgotPassword from './components/ForgotPassword';
import NotificationList from './components/messaging/NotificationList';
import MessageDetails from './components/jobSeeker/MessageDetails';
import JobAlert from './components/messaging/JobAlert';
import RedirectedJobView from './components/findJob/RedirectedJobView';

class App extends Component {
  constructor(props){
    super(props);
    this.state={
    }
    this.tokenHandler=new TokenHandler();
  }
 
  
  render(){
    window.addEventListener('scroll', function() {
      var value = window.scrollY;
      if (value > 100) {
          document.querySelector('.header--fixed').classList.add(['sticky'], ['animated'], ['slideInDown'])
      }else{
          document.querySelector('.header--fixed').classList.remove(['sticky'], ['animated'], ['slideInDown'])
      }
    });
    let isAuthenticated = this.tokenHandler.isLoggedIn();
    const roles = this.tokenHandler.getUserRoles();

    const pathname = window.location.pathname;
    let jobseeker = false;
    if((pathname === '/profile' || pathname === '/resume') && isAuthenticated) {
      if(roles.length>0 && roles[0] === 'ROLE_JOBSEEKER'){
        jobseeker = true;
      }
    }

    let employer = false;
    if((pathname === '/employer' || pathname === '/post-job') && isAuthenticated) {
      if(roles.length>0 && roles[0] === 'ROLE_EMPLOYER'){
        employer = true;
      }
    }
  return (
    <AuthContext.Provider value={isAuthenticated}>
    <div>
      <Header headerStyle={'header-style-2'}></Header>
      <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/jobs' component={FindJobs}/>
          <Route path='/job/details' component={JobDetailsDesktopUI}/>
          <Route path='/mobile/job/details' component={JobDetails}/>
          <Route path='/companies' component={FindCompanies}/>
          <Route path='/company/:companyId/:companyName' component={CompanyDetails}/>
          <Route path='/faq' component={FaqSection}/>
          <Route path='/signin' component={Signin}/>
          <Route path='/signup' component={Signup}/>
          <Route path='/contact' component={ContactUs}/>
          <Route path='/aboutus' component={AboutUs}/>
          <Route path='/terms-of-use' component={TermsOfUse}/>
          <Route path='/privacy-policy' component={PrivacyPolicy}/>
          <Route path='/forgot-password' component={ForgotPassword}/>
          <PrivateRoute path='/profile' component={jobseeker ? ProfileLayout : SeekerDenier}/>
          <PrivateRoute path='/resume' component={jobseeker ? ProfileLayout : SeekerDenier}/>
          <PrivateRoute path='/apply-job/:jobId/:jobCode/:companyId' component={JobApply}/>
          <PrivateRoute path='/employer' component={employer ? EmployerLayout : EmployerDenier}/>
          <PrivateRoute path='/post-job' component={employer ? EmployerLayout : EmployerDenier}/>
          <PrivateRoute path='/notifications' component={NotificationList}/>
          <PrivateRoute path='/notification/details/:jobId/:senderId' component={MessageDetails}/>
          <PrivateRoute path='/job-alert' component={JobAlert}/>
          <PrivateRoute path='/job/redirect' component={RedirectedJobView}/>

          <Route component={PageNotFound}/>
        </Switch>
      <Footer></Footer>
    </div>
    </AuthContext.Provider>
  )}
}

export default App;

import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SectionHeader from '../../common/section-header';
import CityWise from './CityWise';
import StateWise from './StateWise';

// header section content
const title = <h3 className='mb--15'>Jobs in your location</h3>;
const subtitle = <h6>In your Country, City and State.</h6>;
const alignment = 'section-header text-center mt--10';

class CityStateWiseJobSection extends Component {
    render() { 
        let tab1 = "Country";
        let tab2 = "City";
        let tab3 = "State";
        return (
            <section id="cityStateSction" className="pb--50">
                <div className="container p-lg-0">
                    <SectionHeader 
                        title={ title } 
                        subtitle={ subtitle }
                        alignment={alignment}
                    />
                </div>

                <div className="container p-lg-0">
                <Tabs >
                    <TabList  className="nav city-state-control justify-content-center">
                        {/* <Tab>{tab1}</Tab> */}
                        <Tab>{tab2}</Tab>
                        <Tab>{tab3}</Tab>
                    </TabList>


                    <TabPanel>
                        <CityWise/>
                    </TabPanel>
                    <TabPanel>
                        <StateWise/>
                    </TabPanel>
                </Tabs>
                </div>
            </section>
        )
    }
}

export default CityStateWiseJobSection;
import React, { Component } from 'react';
import SectionHeader from '../../common/section-header';

// section header 3 content const
const title3 = <h3 className="mb-3">Post your job today</h3>
const subtitle3 = <h6 className="sub-title mb--30">Job seekers will be able to find your first-class job. </h6>
const alignment3 = 'section-header text-lg-left';


class PostJob extends Component {
    render() {
        return (
            <section id="postJob" className="profile-section bg-ash-color pb--60">
                

                <div className="container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50">
                    <div className="row">
                        <div className="col-lg-5 order-lg-last">
                            <div className="profile-section-content style3  text-center text-lg-left">
                                <SectionHeader 
                                    title={ title3 } 
                                    subtitle={ subtitle3 }
                                    alignment={alignment3}
                                />
                                <p className="desc">Manage your recrutment process. Hire best employee </p>
                                <div className="custom-btn-group">
                                    <a href="/" className="custom-btn mb-2 mb-lg-0 mx-2 ml-lg-0">Get This Now</a>
                                    <a href="/" className="custom-btn mx-2">Free Trial</a>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-7 order-lg-first pr_lg--100">
                            <div className="profile-section-image xl-image" 
                            >
                                <img style={{maxWidth: '90%'}}
                                    src="assets/images/home/user-board.png" 
                                    alt="Post Job"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default PostJob;
import React, { Component } from "react";
import SectionHeader from "../../common/section-header";
import { FaAddressCard, FaUsers } from "react-icons/fa";
import { ProductInfoService } from "../../service/ProductInfoService";
import { ProductSubscriptionService } from "../../service/ProductSubscriptionService";
import { ProgressSpinnerLoader } from "../../common/ProgressSpinnerLoader";

const title = <h3 className="mb--15">Plans designed for growth</h3>;
// const title = <h3 className="mb--15">Prouct Subscription Info</h3>;
const subtitle = (    
  <h6 className="sub-title" style={{ lineHeight: "normal" }}>
    We scale with you. Transparent pricing and same-day setup.
  </h6>
);
const alignment = "section-header text-lg-start pb--40";
// const alignment = "section-header text-lg-center pb--40";

class ProductSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products : [],
            companyInfo : this.props.company,
            proSubsInfo : {
                subsPeriod : '',
                companyInfoDto : null,
                productInfoDto : null
            },
            showProgress: false,
            errors: {},
        }
        
        this.productInfoService = new ProductInfoService();
        this.productSubscriptionService = new ProductSubscriptionService();
    }

    componentDidMount(){
        this.onLoadProductList();
    }

    onLoadProductList =()=> {
        let {products} = this.state;
        this.productInfoService.getProductList()
        .then(res=> {
            if(res.status === 200) {
                return res.json().then(body=>{
                    products = body;
                    this.setState({products});
                })
            }
        })
    }

  onClickSubscribe = (product) => {
   let {proSubsInfo, companyInfo, showProgress, errors} = this.state;
   proSubsInfo.subsPeriod=product.productName;
   proSubsInfo.productInfoDto=product;
   proSubsInfo.companyInfoDto=companyInfo;
   console.log("proSubsInfo : ",proSubsInfo);

   this.setState({ showProgress: true });
   this.productSubscriptionService.newSubscription(proSubsInfo).then((res) => {
     if (res.status === 201) {
       return res.json().then((body) => {
         
         this.props.toast(1, "Subscription Successful");
         showProgress = false;
         this.setState({showProgress, errors });
         this.onLoadProductList();
       });
     } else {
       return res.json().then((err) => {
         this.props.toast(4, err.message);
         showProgress = false;
         this.setState({ showProgress, errors });
       });
     }
   });

  };


  render() {
    //   const {products} = this.state;
      const {products,companyInfo} = this.state;
    //   const {companyInfo} =this.props;

      console.log("Company Data :",companyInfo);
      console.log("Product Data :",products);
    return (
      <div className="container">
        <SectionHeader
          title={title}
        //   subtitle={companyInfo.companyName}
          subtitle={subtitle}
          alignment={alignment}
        />
        <div className="row justify-content-center justify-content-lg-start">
         
          {products.map((p, i) => (
              
              <div className="col-lg-8 mb--30 mb-lg-0" key={i}>
             {/* <div className="col-md-6 col-lg-4 mb--30 mb-lg-0" key={i}>  */}
              <div className="feature-item text-left">
                <div className="icon-box">
                  <div className="icon">
                    {/* <FaAddressCard /> */}
                    <FaUsers />
                  </div>
                  <img
                    src="assets/images/feature-dot-shape.png"
                    alt="feature shape"
                  />
                </div>
          <h5>{p.productName}</h5>
          <p>{p.productDescription}</p>
  
          <p>{p.productPrice>0 ? `$${p.productPrice}` : 'FREE'}</p>
                <button
                  type="submit"
                  className="btn btn-outline-primary"
                  onClick={() => this.onClickSubscribe(p)}
                //   disabled={p.productStatus === 0}
                >
                  Subscribe
                </button>
              </div>
            </div>
          ))}
          
        </div>
      </div>
    );
  }
}
export default ProductSubscription;

import React, { Component } from "react";
import { FaSearch, FaMapMarkerAlt } from "react-icons/fa";
import { JobFilterUtils } from "../../utils/JobFilterUtils";
// import './../css/banner.css';
const bannerTitle = "Find Your Desired Job";
const bannerDesc =
  "With your experience level. In your desired industry. At your location";


class JobSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeyword: null,
      selectedAddress: null,
      errors: {},
    };
  }

  onClickFindJobs = () => {
    let { selectedAddress, selectedKeyword, errors } = this.state;

    if (
      selectedKeyword === null ||
      selectedKeyword === "" ||
      selectedKeyword === undefined
    ) {
      selectedKeyword = null;
    }

    if (
      selectedAddress === null ||
      selectedAddress === "" ||
      selectedAddress === undefined
    ) {
      selectedAddress = null;
    }

    let jobTitle = selectedKeyword;
    let address = selectedAddress;
    let searchObj = {
      jobTitle: jobTitle,
      address:address,
      selected:true
    }

    // let jobFilter = localStorage.getItem('jobFilter');
    // if (jobFilter) {
    //   jobFilter = JSON.parse(jobFilter);
    // } else {
    //   jobFilter =[];
    // }
    // let filters = jobFilter.filter(f => f.jobTitle === jobTitle);
    // if(filters.length === 0){
    //   jobFilter.push(searchObj);
    // } 
    // jobFilter.forEach((v) => {
    //   if(v.jobTitle === jobTitle){
    //     v.selected = true;
    //     v.address = address === null ? v.address : address;
    //   }
    //   else {
    //     v.selected = false;
    //   }
    // });

    // localStorage.setItem("jobFilter", JSON.stringify(jobFilter));
    JobFilterUtils.updateLocatStore(searchObj);
    window.location.replace("/jobs");
  };
  render() {
    const { selectedKeyword, selectedAddress } = this.state;
    let totalJob = this.props.totalJob != null ? this.props.totalJob : 0;
    let currentJobs = `Currently Offering ${totalJob} Jobs`;
    return (
      <section id="jobSearch" className="jobSearch-section">
        <div className="jobSearch-content d-flex align-items-center">
          <div className="container container0p">
            <div className="row">
              <div className="col-lg-12">
                <div className="jobSearch-text">
                  <h1>{bannerTitle}</h1>
                  <p>{bannerDesc}</p>
                  <span className="jobOffr">{currentJobs}</span>
                  <div className="jobSearch-find-form">
                    <div className="row">
                      <div className="col-lg-5 mb--30 mb-lg-0">
                        <input
                          type="text"
                          name="jobTitle"
                          id="jobTitle"
                          value={selectedKeyword}
                          onChange={(e) => {
                            this.setState({ selectedKeyword: e.target.value });
                          }}
                          placeholder="job title or keyword"
                          // placeholder="Job title or keyword"
                        />
                        <FaSearch />
                      </div>
                      <div className="col-lg-5 mb--30 mb-lg-0">
                        <input
                          type="text"
                          name="place"
                          id="place"
                          value={selectedAddress}
                          onChange={(e) => {
                            this.setState({ selectedAddress: e.target.value });
                          }}
                          placeholder="city state zip code or remote"
                          // placeholder="City, state, zip code or remote"
                        />
                        <FaMapMarkerAlt />
                      </div>
                      <div className="col-lg-2 mb--30 mb-lg-0">
                        <button
                          className="submit-btn"
                          type="submit"
                          onClick={this.onClickFindJobs}
                        >
                          Find Jobs
                        </button>
                        <FaSearch style={{ color: "white" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default JobSearch;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaEnvelope,
  FaPaperPlane,
  FaCuttlefish,
  FaAngleDoubleLeft,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import SectionHeader from "../../../common/section-header";
import Validation from "../../../utils/validationUtil";
import { RadioButton } from "primereact/radiobutton";
import { SignupService } from "../../../service/SignupService";
import { ProgressSpinnerLoader } from "../../../common/ProgressSpinnerLoader";
import { Helmet } from "react-helmet";

const title = <h3 className="mb--15">Let's create your profile</h3>;
const subtitle = (
  <h6 className="sub-title" style={{ lineHeight: "normal" }}>
    Employers are looking for your profile.
    <br /> Open your account and choose your job.
  </h6>
);
const alignment = "section-header text-lg-center pb--40";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata:null,
      signupTitle: "Continue with email",
      signupEmail: "",
      isClickSignup: false,
      selectedRole: null,
      verifyCode: "",
      signupStep: {
        step: null,
        obj: null,
      },
      showProgress: false,
      isExist:false,
      isUser:false,
      errors: {},
    };
    this.signupService = new SignupService();
  }

  componentDidMount() {
    fetch('metaData.json').then(res=>res.json().then(data=>{this.setState({metadata:data.signup})}))
  }
  onClickSignup = () => {
    let {
      signupTitle,
      signupEmail,
      selectedRole,
      verifyCode,
      signupStep,
      showProgress,
      isExist,
      isUser,
      errors,
    } = this.state;
    if (signupEmail === "") {
      errors["errorEmail"] = "Please, input email address";
      this.setState({ errors });
      return;
    } else if (!Validation.isCurrectEmail(signupEmail)) {
      errors["errorEmail"] =
        "Incurrect email address, input valid email address";
        this.setState({ errors });
      return;
    }
    if (selectedRole == null) {
      errors["userRole"] = "Please, check any type of User Level";
      this.setState({ errors });
      return;
    }

    let info = {
      Email: signupEmail,
      Role: selectedRole,
    };

    this.setState({showProgress:true});
    this.signupService.forwordVerifyCode(info).then((res) => {
      if (res.status === 201) {
        return res.json().then((body) => {
          signupTitle = "Email validation";
          signupStep.step = "codeVerify";
          signupStep.obj = body;
          localStorage.setItem("signupStep", JSON.stringify(signupStep));
          showProgress = false;
          this.setState({ signupTitle, signupEmail, verifyCode, showProgress });
        });
      }
      else if (res.status === 409) {
        return res.json().then((err) => {
          let message = err.message;
          if(message.includes('email has already used')){
            isExist = true;
          }
          else if(message.includes('email is used by a user')){
            isUser = true;
          }
          errors['errorEmail'] = message;
          showProgress = false;
          this.setState({ showProgress, isExist, isUser, errors });
        });
      }
      else {
        return res.json().then((err) => {
          errors['errorEmail'] = err.message;
          showProgress = false;
          this.setState({ showProgress, errors });
        });
      }
    }).catch((err) => {
      showProgress = false;
      this.setState({ showProgress });
    });
  };

  onClickYesSignup =()=>{
    let {
      signupTitle,
      signupEmail,
      verifyCode,
      signupStep,
      showProgress,
      isExist,
      isUser,
      errors,
    } = this.state;
    if (signupEmail === "") {
      errors["errorEmail"] = "Please, input email address";
      this.setState({ errors });
      return;
    } else if (!Validation.isCurrectEmail(signupEmail)) {
      errors["errorEmail"] =
        "Incurrect email address, input valid email address";
        this.setState({ errors });
      return;
    }

    let info = {
      Email: signupEmail,
    };

    this.setState({showProgress:true});
    this.signupService.forwordReVerifyCode(info).then((res) => {
      if (res.status === 201) {
        return res.json().then((body) => {
          signupTitle = "Email validation";
          signupStep.step = "codeVerify";
          signupStep.obj = body;
          localStorage.setItem("signupStep", JSON.stringify(signupStep));
          showProgress = false;
          isExist = false;
          this.setState({ signupTitle, signupEmail, verifyCode, showProgress, isExist });
        });
      }
      else if (res.status === 409) {
        return res.json().then((err) => {
          let message = err.message;
          if(message.includes('email has already used')){
            isExist = true;
          }
          else if(message.includes('email is used by a user')){
            isUser = true;
          }
          errors['errorEmail'] = message;
          showProgress = false;
          this.setState({ showProgress, isExist, isUser, errors });
        });
      }
      else {
        return res.json().then((err) => {
          errors['errorEmail'] = err.message;
          showProgress = false;
          this.setState({ showProgress, errors });
        });
      }
    }).catch((err) => {
      showProgress = false;
      this.setState({ showProgress });
    });
  };

  onClickNoSignup =()=>{
    let {
      signupEmail,
      showProgress,
      isExist,
      errors,
    } = this.state;
    if (signupEmail === "") {
      errors["errorEmail"] = "Please, input email address";
      this.setState({ errors });
      return;
    } 

    let info = {
      Email: signupEmail,
    };

    this.setState({showProgress:true});
    this.signupService.delVerifyInfo(info).then((res) => {
      if (res.status === 200) {
        signupEmail = "";
        showProgress = false;
        isExist=false;
        errors["errorEmail"] = "";
        this.setState({signupEmail, showProgress, isExist, errors});
      }
      else {
        return res.json().then((err) => {
          errors['errorEmail'] = err.message;
          showProgress = false;
          this.setState({ showProgress, errors });
        });
      }
    }).catch((err) => {
      showProgress = false;
      this.setState({ showProgress });
    });
  }

  onChangeEmail = (e) => {
    let { signupEmail, errors } = this.state;
    errors["errorEmail"] = "";
    signupEmail = e.target.value;
    this.setState({ signupEmail, errors });
  };

  backToSignup = () => {
    let signupStep = JSON.parse(localStorage.getItem("signupStep"));
    this.setState({ signupEmail: signupStep.obj.Email });
    localStorage.setItem("signupStep", null);
  };
  verifyInputCode = () => {
    let { verifyCode, showProgress, errors } = this.state;
    let signupStep = JSON.parse(localStorage.getItem("signupStep"));
    if (verifyCode === "" || verifyCode === undefined) {
      errors["verifyCode"] = "Please, enter code";
      this.setState({ errors });
      return;
    }
    let email = signupStep.obj.Email;
    this.setState({showProgress:true});
    this.signupService.verifyCodeWithEmail(email, verifyCode).then((res) => {
      if (res.status === 200) {
        signupStep.step = "setPassword";
        signupStep['Code'] = verifyCode;
        localStorage.setItem("signupStep", JSON.stringify(signupStep));
        errors["verifyCode"] = "Verify success";
        showProgress = false;
        this.setState({showProgress, errors });
      } else {
        return res.json().then((err) => {
        errors["api-msg"] = err.message;
        showProgress = false;
        this.setState({showProgress, errors });
        })
      }
    }).catch((err) => {
      this.setState({showProgress:false});
    });
  };

  setUserPassword = () => {
    let { newPassword, confirmPassword, showProgress, errors } = this.state;
    let signupStep = JSON.parse(localStorage.getItem("signupStep"));
    if (newPassword === "" || newPassword === undefined) {
      errors["newPassword"] = "Please, enter password";
      this.setState({errors});
      return;
    }
    if (confirmPassword === "" || confirmPassword === undefined) {
      errors["confirmPassword"] = "Please, enter confirm password";
      this.setState({errors});
      return;
    }
    if (newPassword !== confirmPassword) {
      errors["confirmPassword"] = "Opps!, mismatch with confirm password";
      this.setState({errors});
      return;
    }
    let obj = signupStep.obj;
    let newUser = {
      Email: obj.Email,
      Password: newPassword,
      Code: obj.Code,
      Role: obj.Role,
    };
    this.setState({showProgress:true});
    this.signupService.createNewUser(newUser).then((res) => {
      if (res.status === 201) {
        localStorage.setItem("signupStep", null);
        this.props.history.push("/signin");
        showProgress = false;
        this.setState({showProgress});
      } else {
        return res.json().then((err) => {
          errors["api-msg"] = err.message;
          showProgress = false;
          this.setState({showProgress, errors });
        })
      }
    });
  };
  render() {
    let signupStep = null;
    if (localStorage.getItem("signupStep") != null) {
      signupStep = JSON.parse(localStorage.getItem("signupStep"));
    }
    const {
      metadata,
      signupTitle,
      signupEmail,
      verifyCode,
      newPassword,
      confirmPassword,
      showProgress,
      isExist,
      isUser,
      errors,
    } = this.state;
    return (
      <section id="signin" className="profile-section bg-ash-color pb--60">
        <Helmet>
          <title>{metadata && metadata.title}</title>
          <meta name="description" content={metadata && metadata.details} />
        </Helmet>
        <ProgressSpinnerLoader showBar={showProgress} />
        <div className="container position-relative pt--100 pb--30 pt_lg--120 pb_lg--50">
          <div className="row">
            <div className="col-lg-7 order-lg-last">
              <div className="profile-section-content text-center text-lg-left">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  alignment={alignment}
                />

                <div className="profile-info-container-style1 hover-effect-parent1 text-left">
                  <div className="profile-section-info-item d-flex flex-wrap no-hover-item">
                    <div className="content max-width-425">
                      <h5 className="font-weight-bold mb-3 mt-0">
                        {signupTitle}
                      </h5>

                      {signupStep == null && (
                        <div className="row">
                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FaEnvelope />
                                </span>
                              </div>
                              <input
                                value={signupEmail}
                                type="email"
                                className="form-control"
                                placeholder="Input valid email"
                                onChange={this.onChangeEmail}
                              />
                            </div>
                            <span className="error-msg">
                              {errors["errorEmail"]}
                            </span>
                          </div>
                          {isExist === false && (
                            <div>
                              <div className="col-lg-12 mb--10 mb-lg-10">
                                <div className="input-group">
                                  <div className="input-group-prepend">I'm </div>
                                  <div className="custom-control custom-control-inline">
                                    <RadioButton
                                      value="ROLE_JOBSEEKER"
                                      name="userRole"
                                      style={{ marginRight: "8px" }}
                                      onChange={(e) =>
                                        this.setState({ selectedRole: e.value })
                                      }
                                      checked={
                                        this.state.selectedRole === "ROLE_JOBSEEKER"
                                      }
                                    />
                                    <label className="">a job seeker</label>
                                  </div>
                                  <div className="custom-control custom-radio custom-control-inline">
                                    <RadioButton
                                      value="ROLE_EMPLOYER"
                                      name="userRole"
                                      style={{ marginRight: "8px" }}
                                      onChange={(e) =>
                                        this.setState({ selectedRole: e.value })
                                      }
                                      checked={
                                        this.state.selectedRole === "ROLE_EMPLOYER"
                                      }
                                    />
                                    <label className="">an employer</label>
                                  </div>
                                </div>
                                <span className="error-msg">
                                  {errors["userRole"]}
                                </span>
                              </div>
                              <div className="col-lg-12">
                                <p>
                                  By continuing, you agree to our{" "}
                                  <Link to="/terms-of-use">Terms of Use </Link>
                                  and <Link to="/privacy-policy">Privacy Policy</Link>
                                </p>
                              </div>
                              <div className="col-lg-12 mb--10 mb-lg-10">
                                <div className="input-group" style={{ display: "inline-grid" }}>
                                  <button className="btn btn-primary" type="submit" onClick={this.onClickSignup}>
                                    <span><FaPaperPlane /></span>{" "}Sign up with Email
                                  </button>
                                  {isUser === false && (
                                    <span>
                                      Already have an account?{" "}
                                      <Link to="/signin">Sign In</Link>
                                    </span>
                                  )}
                                  {isUser && (
                                    <span>
                                    Have you forgotten your password?{" "}
                                      <Link to="/forgot-password">Recover Now</Link>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {isExist && (
                            <div className="col-lg-12 mb--10 mb-lg-10">
                              <div className="input-group" style={{ display: "inline-grid" }}>
                                <span>
                                  Do you want to complete Sign up?{" "}
                                  <button className="btn btn-outline-primary" type="submit" onClick={this.onClickYesSignup}>
                                    <span><FaCheck /></span>{" "}Yes
                                  </button>
                                  <button className="btn btn-outline-danger ml-2" type="submit" onClick={this.onClickNoSignup}>
                                    <span><FaTimes /></span>{" "}No
                                  </button>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {signupStep && signupStep.step === "codeVerify" && (
                        <div className="row">
                          <div className="col-lg-12">
                            <p>
                          A mail has sent to this <b>{signupEmail}</b> email
                          address with a Verification Code. <br />
                          Please, check the mail and bring the code to complete Sign up.
                        </p>
                          </div>
                          <div className="col-lg-12">
                            <strong>Email and Code Verification</strong>
                          </div>
                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FaEnvelope />
                                </span>
                              </div>
                              <input
                                disabled
                                value={signupStep.obj.Email}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FaCuttlefish />
                                </span>
                              </div>
                              <input
                                value={verifyCode}
                                type="text"
                                className="form-control"
                                placeholder="Enter code here"
                                onChange={(e) =>
                                  this.setState({ verifyCode: e.target.value, errors:{} })
                                }
                              />
                            </div>
                            <span className="error-msg">
                              {/* Code: {signupStep.obj.Code}<br/> */}
                              {errors["verifyCode"]}
                              {errors["api-msg"]}
                            </span>
                          </div>
                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <button
                              type="submit"
                              className="btn btn-outline-primary"
                              onClick={this.backToSignup}
                            >
                              <span>
                                <FaAngleDoubleLeft />
                              </span>{" "}
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ float: "right" }}
                              onClick={this.verifyInputCode}
                            >
                              <span>
                                <FaCheck />
                              </span>{" "}
                              Verify
                            </button>
                          </div>
                        </div>
                      )}

                      {signupStep && signupStep.step === "setPassword" && (
                        <div className="row">
                          <div className="col-lg-12">
                            <strong>Set your password</strong>
                          </div>
                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FaEnvelope />
                                </span>
                              </div>
                              <input
                                disabled
                                value={signupStep.obj.Email}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FaCuttlefish />
                                </span>
                              </div>
                              <input
                                value={newPassword}
                                type="password"
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) =>
                                  this.setState({ newPassword: e.target.value })
                                }
                              />
                            </div>
                            <span className="error-msg">
                              {errors["newPassword"]}
                            </span>
                          </div>

                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FaCuttlefish />
                                </span>
                              </div>
                              <input
                                value={confirmPassword}
                                type="password"
                                className="form-control"
                                placeholder="Enter confirm password"
                                onChange={(e) =>
                                  this.setState({
                                    confirmPassword: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <span className="error-msg">
                              {errors["confirmPassword"]}
                              {errors["api-msg"]}
                            </span>
                          </div>
                          <div className="col-lg-12 mb--10 mb-lg-10">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ float: "right" }}
                              onClick={this.setUserPassword}
                            >
                              <span>
                                <FaCheck />
                              </span>{" "}
                              Confirm
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-lg-first pr_lg--100">
              <div className="profile-section-image image1">
                <img
                  src="assets/images/home/signup.png"
                  alt="Profile Setup"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Signup;
